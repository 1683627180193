import React from 'react';
import { CButton } from '@coreui/react';
import { CSpinner } from '@coreui/react'
function CustomButton({isLoading, title, onClick, color}) {
  return (
    <CButton color={color} onClick={onClick}>
    {
      isLoading ? <CSpinner color="light" size='sm'/>: `${title}`
    }
  </CButton>
  );
}

export default CustomButton;