// import axios from 'axios'
// import { getJWTfromCookie } from '../utils/handleCookie.js'
// import { FILTER_URL } from '../constants/api.config'
// import toast from 'react-hot-toast'
// import { toastConfig } from '../constants/other.config'
// import { history } from '../utils/history'
// import { errorHandler } from '../utils/errorHandler'

// export default function getFilters(URL, payload) {
//   const config = {
//     method: 'post',
//     url: `${FILTER_URL}/${URL}`,
//     contentType: 'application/json',
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8',
//      // Authorization: `Bearer ${cookie}`,
//     },
//     data: JSON.stringify(payload),
//   }
//   return new Promise((resolve, reject) => {
//    // if (cookie) {
//       axios(config)
//         .then((result) => resolve(result))
//         .catch((error) => {
//           toast.error(errorHandler(error?.response?.status), toastConfig)
//           reject(null)
//         })
//     // } else {
//     //   history.push('/')
//     // }
//   })
// }

import axios from 'axios'
import { getJWTfromCookie } from '../utils/handleCookie.js'
import { FILTER_URL } from '../constants/api.config'
import toast from 'react-hot-toast'
import { toastConfig } from '../constants/other.config'
import { history } from '../utils/history'
import { errorHandler } from '../utils/errorHandler'

import { CHARTS_URL } from '../constants/api.config'

export default function getFilters(URL, payload) {
  const config = {
    method: 'post',
    url: `${FILTER_URL}/${URL}`,
    contentType: 'application/json',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
     // Authorization: `Bearer ${cookie}`,
    },
    data: JSON.stringify(payload),
  }
  return new Promise((resolve, reject) => {
   // if (cookie) {
      axios(config)
        .then((result) => resolve(result))
        .catch((error) => {
          toast.error(errorHandler(error?.response?.status), toastConfig)
          reject(null)
        })
    // } else {
    //   history.push('/')
    // }
  })
}

