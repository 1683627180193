import getCharts from '../getCharts'
import { MMGSYROADDATA } from '../../constants/api.config'


export default async function MMGSYFetchData(login="1") {
  try {
    var res = ""
  
      const payload = {login }

      res = await getCharts(MMGSYROADDATA, payload)

   
    return res.data
  } catch (error) {
    return error
  }
}
