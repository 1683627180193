import React from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import ChartLabels from  'chart.js-plugin-labels-dv';

import { useState, useEffect, useRef } from 'react'
import Loader from './Loader';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar, Line, Pie,getElementAtEvent,getElementsAtEvent, getDatasetAtEvent } from 'react-chartjs-2'
const colors = [
  '#e55353',
  '#f9b115',
  '#36A2EB',
  '#2eb85c',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
]

function ChartWrapper({ options, chartClickEvent=() => {alert("Showing data on chart click is under maintainance, Please check back later")}, chartID="-1" }) {
  const chartRef = useRef()

  if (options.length == 0) {
    return (
      <p>Loading.. </p>
    )
  }
  const [loading, setloading] = React.useState(false);
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, ArcElement, LineElement, Title, Tooltip, Legend, ChartDataLabels)
  const getFonts = (size = 16, weight = 800) => ({ size, weight, family: 'Inter' })
  const chartlabels = options.labels.map((e) => (e.replace('&amp;','&')))
  const getTitle = (title, fontSize, align = 'start') => ({
    display: true,
    text: title,
    align,
    color: '#000',
    font: getFonts(fontSize),
  })

  
  
  const handleClick = (e) => {
    try {
      setloading(true)
      let dataset = getDatasetAtEvent(chartRef.current, e)
      const datasetIndex = dataset[0].datasetIndex;
      let data = chartRef.current.data
      let label = data.datasets[datasetIndex].label
      let elementAtEvent = getElementAtEvent(chartRef.current, e)
      const { index } = elementAtEvent[0];
      let xAxis = data.labels[index]
      chartClickEvent(xAxis, label, chartID)
      setloading(false)

    } catch (e) {
      setloading(false)

    } 
    
  }
  options.type = options.type.toLowerCase()
  function getAxis(options){
    let scales = {}
    scales["x"] = {
      display: options.type !== 'pie',
      grid: { display: true },
      title: getTitle(options?.xaxis?.title, 14, 'center'),
    }
    scales["A"] = {
      position: 'left',
      display: options.type !== 'pie',
      grid: { display: false },
      title: getTitle(options?.yaxis?.[0]?.title, 14, 'center'),
      id:"A",
      afterDataLimits(scale) {
        if (options.type !== 'pie') {
          let x = Math.floor(Math.log10(scale.max))
          if (x>3) {
            scale.max += Math.pow(10,x) ;
          } else {
            scale.max += Math.pow(5,x);
          }
           
        }
      },
      ticks: { font: getFonts(12)},
    }
    if (options.type !== 'pie' )  {
      if (options.yaxis.length == 2) {
        
        scales["B"] = {
          position: 'right',
          display: options.type !== 'pie',
          grid: { display: false },
          title: getTitle(options?.yaxis?.[1]?.title, 14, 'center'),
          id:"B",
          ticks: { font: getFonts(12)},
        }
    }
  }
    
    
    return scales

  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        font: {
          weight: 'bold',
          size: 12,
        },
        display: options.type == 'pie'  ? 'auto' : true,
        anchor: options.type == 'pie'  ? 'center' : 'end',
        align: options.type == 'pie'  ? 'center' : 'top',
        offset: options.type == 'pie'  ? 0 : -5,
        color:'black',
        formatter: (value, ctx) => {
          if (options.type == 'pie') {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += parseFloat(data);
            });
            // let percentage = (value*100 / sum).toFixed(2)+"%";
            let percentage = value;
            return percentage;
          }
          

      
        },
        padding: 5,
        rotation: options.type == 'bar'  ? -45 : 0,
      },
      legend: {
        display: !!options.showLegend,
        position: options.type == 'pie'? 'bottom':'bottom',
        align: 'center',
        labels: {
          font: getFonts(12),
          boxWidth: 12,
        },
      },
    },
    scales: getAxis(options),
  }

 // const [data, setData] = useState({})

  const data = {
    labels: chartlabels,
    datasets: options.datasets.map((d, i) => ({
      data: d.data,
      borderRadius:10,
      borderWidth:2,
      backgroundColor: options.type !== 'pie' ? colors[i] : colors.slice(0, d.data.length),
      label: d.label,
      yAxisID: options.type !== 'pie' ?  i == 1 ? options.yaxis.length == 2 ? "B" : "A" : "A": "A",
    })),
  }

return (
  loading ? <Loader />:
    <div style={{ height: '100%' }}>
      {options.type === 'bar'  && <Bar plugins={[ChartDataLabels]} ref={chartRef} onClick={handleClick} options={chartOptions} data={data} />}
      {options.type === 'line' && <Line plugins={[ChartDataLabels]} ref={chartRef} onClick={handleClick} options={chartOptions} data={data} />}
      {options.type === 'pie'  && <Pie plugins={[ChartDataLabels]} ref={chartRef} onClick={handleClick} options={chartOptions} data={data} />}
    </div>
  )
}

export default ChartWrapper
