import getCharts from '../getCharts'
import { HOTMIXDATA } from '../../constants/api.config'

export default async function GetHotMixPlantData( Mode) {
  try {
    const payload = {Mode}
    const res = await getCharts(HOTMIXDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}
