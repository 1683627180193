import getFilters from '../getFilters'
import { Filters } from '../../constants/api.config'

export default async function getFilterData(lID, mID, tranID, cID) {
  try {
    const payload = {loginID: lID, moduleID: mID, tranTypeID: tranID, chartID: cID}
    const res = await getFilters(Filters, payload)
    return res.data
  } catch (error) {
    return error
  }
}
