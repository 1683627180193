import { CCol, CRow, CSpinner, CContainer, CButton } from '@coreui/react'
import ChartWrapper from '../../components/shared/ChartWrapper'
import Modal from '../../components/shared/Modal'
import ToggleSwitch from '../../components/shared/ToggleSwitch'
import GridView from '../../components/shared/GridView'
import Card from '../../components/Card'
import { useState, useEffect, useRef } from 'react'
import getChartsWithFilter from '../../services/general/getChartsData.api.js'
import SaveData from '../../services/general/saveData.api.js'
import ViewAllData from '../../services/general/viewAllData.api.js'
import ModalTable from '../../components/shared/ModalTable'

import { Input, Button } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import FilterDrawer from '../../components/shared/FilterDrawer'
import { Chip } from '../../components/shared/components'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { parseJsonFilter } from '../../utils/jsonFilterParser'
import { getCharts, getAllFilters } from '../../services/apicalls.js'
import getFilterData from '../../services/general/getFilterData.api.js'
import Loading from 'react-fullscreen-loading'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import 'react-loading-skeleton/dist/skeleton.css'

window.globalChartID = ''
window.filters = ''
const Capital = () => {
  const loginID = '1'
  const moduleID = '13'
  const tranTypeID = '112'
  const [openModal, setOpenModal] = useState(false)
  const [chartData, setChartData] = useState({})
  const [filterData, setfilterData] = useState([])
  const [isDataRefeteched, setDataRefetched] = useState(false)
  const [isViewDataRefeteched, setViewDataRefetched] = useState(false)
  const [gridData, setGridData] = useState({})
  const [checked, setChecked] = useState(false)
  const [isDrawerOpened, setDrawerOpened] = useState(false)
  const [filterValues, setFilterValues] = useState([])
  const searchCharts = useRef([])

  const [loading, setLoading] = useState(false)
  const [modalChartLoading, setModalChartLoading] = useState(false)
  const [data, setData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [searchString, setSearchString] = useState('')
  const [modalData, setModalData] = useState('')
  const [viewDataVisible, setViewDataVisible] = useState(false)

  const [chartID, setChartID] = useState('')
  const { isLoading, data: chartsData } = getCharts(loginID, moduleID, tranTypeID)
  // const { data: tempFilterData, refetch } = useQuery(['getFilterData', loginID, moduleID, tranTypeID, chartID], () => {getFilterData(loginID, moduleID, tranTypeID, chartID)}, { refetchOnWindowFocus: false, enabled: false  })

  const setChartGridData = (chart) => {
    if (chart.type == 'pie') {
      const cols = [
        { title: 'Name', dataIndex: 'label', key: 'label' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
      ]
      let total = 0
      let rows = chart.labels.map((d, index) => {
        if (chart.datasets[0].data[index] == '' || chart.datasets[0].data[index] == undefined) {
          chart.datasets[0].data[index] = '0'
        }
        total = total + parseInt(chart.datasets[0].data[index])
        return {
          label: d,
          value: chart.datasets[0].data[index],
          key: index,
        }
      })

      let row = {
        label: 'Total',
        value: total,
      }
      rows.push(row)
      setGridData({ rows, cols })
    } else {
      let colsList = []
      let cols = [{ title: 'Label', dataIndex: 'label' }]
      let total = []
      for (let i = 0; i < chart.datasets.length; i++) {
        cols.push({ title: chart.datasets[i].label, dataIndex: chart.datasets[i].label.toLowerCase() })
        colsList.push(chart.datasets[i].label.toLowerCase())
      }
      let data = []
      colsList.map((col, index) => {
        total[index] = 0
      })
      for (let j = 0; j < chart.labels.length; j++) {
        //total[j] = 0
        let el = {}
        colsList.map((col, index) => {
          total[index] = total[index] + parseFloat(chart.datasets[index].data[j])
          el[col] = chart.datasets[index].data[j]
        })

        el['key'] = j
        el['label'] = chart.labels[j]
        data.push(el)
      }
      let rows = data
      let row = {}

      colsList.map((col, index) => {
        row[col] = total[index]
      })
      row['label'] = 'Total'
      row['key'] = chart.labels.length
      rows.push(row)

      setGridData({ rows, cols })
    }
  }
  const handleClickOnCardExpand = (i) => {
    try {
      let chart = data[i]
      setChartData(data[i])
      setChartID(data[i].idnumber)
      window.globalChartID = data[i].idnumber

      let filterData = getFilterData(loginID, moduleID, tranTypeID, data[i].idnumber)
      filterData.then(
        (result) => {
          let temp = JSON.parse(result.d)
          let initialFilterValues = []

          temp.map((fdata, index) => {
            initialFilterValues[index] = fdata.Values[0]
          })
          setFilterValues(initialFilterValues)
          setfilterData(temp)
          setChartGridData(chart)
          setOpenModal(true)
        },
        function (error) {},
      )
    } catch (e) {
      console.log(e)
    }
  }

  const [selectedFilters, setSelectedFilter] = useState([])
  const [selectedModalFilters, setSelectedModalFilter] = useState([])
  const onRemoveFilter = (fil) => setSelectedFilter(selectedFilters.filter((d) => d.name !== fil.name))
  const onRemoveModalFilter = (fil) => setSelectedModalFilter(selectedModalFilters.filter((d) => d.name !== fil.name))
  const filterCharts = (val) => {
    makeData(searchCharts.current.filter((c) => c.title.toLowerCase().includes(val.toLowerCase()) || c?.group?.toLowerCase().includes(val.toLowerCase())))
  }
  const handleOnSearchChange = (e) => {
    setSearchString(e.target.value)
    filterCharts(e.target.value)
  }

  const fetchStateData = async () => {
    setDataRefetched(true)
    var date = new Date()
    debugger

    date.setMonth(date.getMonth() - 1)
    const data = { last_sync_date: date }
    const config = {
      method: 'POST',
      data,
      url: 'https://api.strobes.in/api/workLists',
      headers: {
        token: 'aG4RK0GYRMER7YqcIYl8My0pWP0vIMBB',
        Accept: 'Application/json',
      },
    }
    let response = await axios(config)
    let temp1 = response.data
    console.log('response', response)
    console.log(temp1)
    for (let i = 0; i < temp1.data.length; i++) {
      temp1.data[i]['electrict_line_pole_shifting_data'] = null
      // temp1.data[i]["sub_division_name"] = temp1.data[i]["sub_division_name"].replace("R & B ","")
      // temp1.data[i]["sub_division_name"] = temp1.data[i]["sub_division_name"].replace("R & B  ","")
      // temp1.data[i]["sub_division_name"] = temp1.data[i]["sub_division_name"].replace("(R & B) ","")
      // temp1.data[i]["division_name"] = temp1.data[i]["division_name"].replace("R & B , ","")
      // temp1.data[i]["division_name"] = temp1.data[i]["division_name"].replace("R & B  ","")
      // temp1.data[i]["division_name"] = temp1.data[i]["division_name"].replace("(R & B) ","")
      // temp1.data[i]["circle_name"] = temp1.data[i]["circle_name"].replace("R & B , ","")
      // temp1.data[i]["circle_name"] = temp1.data[i]["circle_name"].replace("R & B  ","")
      // temp1.data[i]["circle_name"] = temp1.data[i]["circle_name"].replace("(R & B) ","")

      temp1.data[i]['water_supply_storm_water_line_data'] = null
    }
    // console.log(temp1.data)
    //console.log(JSON.stringify(temp1.data))
    // let d = JSON.stringify(temp1.data)

    // let a = await SaveData("state", d)
    for (let i = 0; i < temp1.data.length; i++) {
      let d = JSON.stringify(temp1.data.slice(i, i + 1))

      let a = await SaveData('state', d)
    }

    setDataRefetched(false)
    location.reload()
  }

  const onModalClose = () => {
    setOpenModal(false)
    setChecked(false)
    setGridData({})
    setFilterValues([])
    setSelectedModalFilter([])
  }
  function fetchData(filters) {
    setModalChartLoading(true)
    window.filters = filters
    let chartData = getChartsWithFilter(chartID, filters)
    try {
      chartData.then(
        (result) => {
          let temp = JSON.parse(result.d)
          setChartData(temp[0])
          setChartID(temp[0].idnumber)
          setChartGridData(temp[0])
          setModalChartLoading(false)
        },
        function (error) {
          setModalChartLoading(false)
          console.log(error)
        },
      )
    } catch (err) {
      console.log('chart failed')
    }
  }

  useEffect(() => {
    try {
      if (!isLoading) {
        let temp = JSON.parse(chartsData.d)
        searchCharts.current = temp
        setData(temp)
        setOriginalData(temp)
        setLoading(false)
      } else {
        setLoading(true)
      }
    } catch (error) {
      setData([])
      setOriginalData([])
      setLoading(false)
    }
  }, [isLoading])

  const viewDataFn = () => {
    setViewDataRefetched(true)
    let a = ViewAllData('', '13', '112', '')
    a.then((res) => {
      setModalData(res)
      setViewDataVisible(true)
      setViewDataRefetched(false)
    })
  }

  const makeData = (data) => {
    const list = []
    const nonGrouplist = []
    const groups = {}
    data.forEach((d) => {
      if (d.group) {
        const grp = groups[d.group]
        if (grp) grp.charts.push(d)
        else {
          groups[d.group] = { title: d.group, type: 'group', charts: [d] }
        }
      } else nonGrouplist.push(d)
    })
    Object.keys(groups).map((key) => list.push(groups[key]))
    setData([...list, ...nonGrouplist])
  }

  if (loading)
    return (
      <CContainer fluid className="p-0" xxl>
        <div className="p-2 bg-secondary border rounded mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ width: '50%' }} className="rounded-lg overflow-hidden">
              <Input value={searchString} placeholder="Search here ..." />
            </div>
          </div>
        </div>
        <CRow lg={{ gutterY: 3 }}>
          <CCol lg={4}>
            <Card title="Loading..." simple>
              <CRow lg={{ gutterY: 3 }}>
                <Skeleton height={70} count={3}></Skeleton>
              </CRow>
            </Card>
          </CCol>
          <CCol lg={4}>
            <Card title="Loading..." simple>
              <CRow lg={{ gutterY: 3 }}>
                <Skeleton height={70} count={3}></Skeleton>
              </CRow>
            </Card>
          </CCol>
        </CRow>
      </CContainer>
    )

  return (
    <>
      <CContainer fluid className="p-0 align-items-center justify-content-between">
        <div className="p-2 bg-secondary border rounded mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ width: '30%' }} className="rounded-lg overflow-hidden">
              <Input value={searchString} placeholder="Search here ..." onChange={handleOnSearchChange} />
            </div>
            <div style={{ fontSize: '18px' }}>
              <b>Capital</b>
            </div>
            <div className="d-flex ">
              <CButton color="primary" className="m-2" variant="outline" onClick={viewDataFn}>
                {isViewDataRefeteched ? (
                  <>
                    <CSpinner component="span" size="sm" variant="grow" aria-hidden="true" />
                    Loading...
                  </>
                ) : (
                  <>View Data</>
                )}
              </CButton>
              <CButton
                color="danger"
                className="m-2"
                variant="outline"
                onClick={() => {
                  location.href = 'https://strobes.in/login'
                }}
              >
                Modify Data
              </CButton>
              <CButton color="success" className="m-2" variant="outline" onClick={fetchStateData}>
                {isDataRefeteched ? (
                  <>
                    <CSpinner component="span" size="sm" variant="grow" aria-hidden="true" />
                    Loading...
                  </>
                ) : (
                  <>Refetch</>
                )}
              </CButton>
            </div>
          </div>
        </div>
        <CRow lg={{ gutterY: 3 }}>
          {data.map((chart, index) =>
            chart.type === 'group' ? (
              <CCol lg={12} key={index}>
                <Card title={chart.title} simple>
                  <CRow lg={{ gutterY: 3 }}>
                    {chart.charts.map((d, i) => (
                      <CCol lg={12} xl={d.type === 'pie' ? 6 : 6} key={`gc-${i}`}>
                        <Card
                          title={d.title}
                          onClick={() => {
                            handleClickOnCardExpand(i)
                          }}
                        >
                          <ChartWrapper options={d} />
                        </Card>
                      </CCol>
                    ))}
                  </CRow>
                </Card>
              </CCol>
            ) : (
              <CCol lg={12} xl={chart.type === 'pie' ? 4 : 6} key={index}>
                <Card
                  title={chart.title}
                  onClick={() => {
                    handleClickOnCardExpand(index)
                  }}
                >
                  <ChartWrapper options={chart} />
                </Card>
              </CCol>
            ),
          )}
        </CRow>
        {openModal && (
          <Modal open={openModal} type={chartData.type} title={chartData.title} onClose={onModalClose}>
            <div className="container">
              <div className="row">
                <div className={chartData.type === 'pie' ? 'col-8' : 'col-10'}>
                  {!checked ? (
                    modalChartLoading ? (
                      <div style={{ height: '522px' }}>
                        {/* <Skeleton height={'60px'}></Skeleton> */}
                        <Loading loading loaderColor="#3498db" />
                      </div>
                    ) : (
                      <ChartWrapper options={chartData} />
                    )
                  ) : (
                    <GridView cols={gridData.cols} rows={gridData.rows} />
                  )}
                </div>
                <div className={chartData.type === 'pie' ? 'col-4' : 'col-2'}>
                  <div>
                    <h5 className="font-weight-bolder m-0">View Details ♒</h5>

                    {/* <p>Description</p> */}
                    <p></p>
                    {/* <Link to="/details">view table ↗️</Link> */}
                    <p></p>
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="font-weight-bolder m-0">Filters 📂</h5>
                        <Button onClick={() => setDrawerOpened(true)} className="ml-2 d-flex justify-content-center align-items-center" shape="circle" type="primary" icon={<FilterFilled size="small" />} />
                      </div>
                      <div className="d-flex mt-2 align-items-center flex-wrap">
                        {selectedModalFilters.map((m, i) => (
                          <Chip key={i} name={m.name} onClose={() => onRemoveModalFilter(m)} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <ToggleSwitch checked={checked} onChange={setChecked} />
                    <div className="ml-2">Gird View</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {openModal && isDrawerOpened && <FilterDrawer onClose={() => setDrawerOpened(false)} visible={isDrawerOpened} fetchDataFn={fetchData} filterData={filterData} setFiltersFn={setSelectedModalFilter} setFilterValuesFn={setFilterValues} filterValuesState={filterValues} />}
        {viewDataVisible && <ModalTable open={viewDataVisible} setVisibleFn={setViewDataVisible} type="table" title="View All State Data" value="View All State Data" data={modalData}></ModalTable>}
      </CContainer>
    </>
  )
}

export default Capital
