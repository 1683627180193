import getCharts from '../getCharts'
import { COMMONCHARTS } from '../../constants/api.config'

export default async function getAllCommonCharts(loginID, mID, tranID,filters="") {
  try {
    const payload = {loginID, moduleID: mID, tranTypeID:tranID,filters }
    const res = await getCharts(COMMONCHARTS, payload)
    return res.data
  } catch (error) {
    return error
  }
}
