import React, { useEffect, useRef, useState } from 'react'
import CustomAgGrid from './CustomAgGrid'
import getAllHomeWorks from '../../services/general/getAllHomeWorks.api'
import { useQuery } from 'react-query'
import Loader from './Loader'
import { CFormSwitch } from '@coreui/react'
import getAllWorkData from '../../services/general/getAllWorkData.api'
import getAllHomeChartsTemp from '../../services/general/getAllHomeChartsTemp.api'
import Modal from '../../components/shared/ModalTable'



const Watch_list = () => {
  const [toggle,setToggle]= useState(false);
  const [watchListLoader, setWatchListLoader] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalData, setModalData] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [active, setActive] = useState(false)
  const moduleName = useRef('')
  const [watchListData, setwatchListData] = useState('')
  const [on,setOn] = useState(false)

  const loginID = '1'
  
    const fetchWatchListData = async (t) => {
        const temp = await getAllHomeWorks('1', 'get', '')
        return temp.d
      }
    const {isLoading,data: whatList } = useQuery('watchList',fetchWatchListData,{
      refetchOnWindowFocus:false,
      refetchIntervalInBackground:false,
      refetchInterval:false
    })

    const saveWatchList = async (rowsIDs) => {
      if (rowsIDs.length > 0) {
        let t = rowsIDs.join(',')
        const temp = await getAllHomeWorks('1', 'update', t)
        setwatchListData(temp.d)
        alert('Watch List Saved')
        setToggle(false)
        setOn(false)
      }
    }


    
    const {isLoading:loading,data: viewAllList,refetch:callRefetchData } = useQuery('TotalWorks',()=>getAllWorkData("2", "Total"),{
      refetchOnWindowFocus:false,
      refetchIntervalInBackground:false,
      refetchInterval:false
    })
   
  
    
  return (
  
  <>
    <div className="p-2 bg-secondary border rounded mb-3" style={{height:'50px'}}>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ width: '50%',height:'50px'}} className="rounded-lg overflow-hidden">
            <CFormSwitch size='xl' checked={on} onChange={(e)=>{setToggle(!toggle); setOn(e.target.checked)}} style={{ marginLeft:'0',marginRight:'1em',paddingLeft:'0',width:'35px'}} label={toggle? "All Work":"Add Work"} id="formSwitchCheckDefaultNormal"/>
            </div>
          </div>
        </div>
       
        <>
        {
          toggle == true ?  
          <>
          {        
            <>
            {
              loading ? <Loader />:
            <CustomAgGrid options={viewAllList.d} type="table" title={"All Works - Watch List"} saveWatchList={saveWatchList} />
            }
            </>
          }
          </>:
          <>
          {
            isLoading ? <Loader />:
            <CustomAgGrid options={whatList}></CustomAgGrid>
          }
          </>
        }
        </>
     
     </>
  )


}

export default Watch_list;
