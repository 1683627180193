import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
//pdfMake.vfs = pdfFonts && pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : globalThis.pdfMake.vfs;

import getDocDefinition from "./docDefinition";

function printDoc(printParams, gridApi, columnApi, fontSize = 15,title="") {
//   console.log("Exporting to PDF...");
//   const docDefinition = getDocDefinition(printParams, gridApi, columnApi);
//  docDefinition.header = {
//   text:title,
//   alignment:"center",
//   fontSize:15
//  }
//   docDefinition.defaultStyle = {
//     fontSize,
//   }
//   console.log(docDefinition)
//   pdfMake.createPdf(docDefinition).download();
}

export default printDoc;
