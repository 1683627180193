import getCharts from '../getCharts'
import { CHARTS } from '../../constants/api.config'

export default async function getAllCharts(loginID, mID, tranID) {
  try {
    const payload = {loginID: '1', moduleID: mID, tranTypeID:tranID }
    const res = await getCharts(CHARTS, payload)
    return res.data
  } catch (error) {
    return error
  }
}
