import {React, useContext} from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { UserContext } from '../Contexts/UserContext'

const DefaultLayout = () => {
  const { encryptedURL } = useContext(UserContext)

  return (
    <div>
      <AppSidebar url={encryptedURL}  />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader url={encryptedURL}/>
        <div className="body flex-grow-1 p-3">
          <AppContent />
        </div>
        
      </div>
      <AppFooter />
    </div>
  )
}

export default DefaultLayout
