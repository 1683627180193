import React from 'react'
import { CCol, CRow,
 CButton,CModal,CModalBody, CModalHeader, CModalTitle,CModalFooter,CDropdown,CDropdownItem,CDropdownMenu,CDropdownToggle } from '@coreui/react'
import { CSpinner  } from '@coreui/react'
import ChartWrapper from '../../components/shared/HomeChartWrapper' 
import Modal from '../../components/shared/ModalTable'
import Loading from 'react-fullscreen-loading';
import NewModal from '../../components/shared/Modal'
import ToggleSwitch from '../../components/shared/ToggleSwitch'
import getScadaData from "../../services/general/getScadaData.api";
import Map from '../dashboard/FacilityMap';

import {ScadaPlantLocation} from './dummyJson/scadaPlant.jsx'

// import DatePicker from 'antd'
import Loader from '../../components/shared/Loader'

import {
  CChartBar,
  CChartLine,
} from '@coreui/react-chartjs'

import GridView from '../../components/shared/GridView'


import { useEffect } from 'react'
import Card from '../../components/Card'

import {DatePicker} from 'antd'
import HotMixPlant from './HotMixPlant'

import { useState, useRef } from 'react'
import { useCallback } from 'react'

 const  SCADA = () => {
  const loginID = "1"
  const moduleID = "-2"
  const tranTypeID = "-2"

  const scadaTotalData = useRef({})

  const [modalVisible, setModalVisible] = useState(false)
  const scadaData = useRef({"running":{},"working":{}})
  const scadaPattern = {
    "idnumber": "4",
    "title": "Total Running Plants (Scada)",
    "type": "bar",
    "labels": [""],
    "datasets": [
        {
            "label": "CP",
            "data": [
                ""
            ]
        },
        {
            "label": "NH",
            "data": [
                ""
            ]
        },
        {
            "label": "State",
            "data": [
                ""
            ]
        },
        {
            "label": "Panchayat",
            "data": [
                ""
            ]
        },
        {
          "label": "Misc",
          "data": [
              ""
          ]
      }
    ],
    "showLegend": "true",
    "yaxis": [
        {
            "title": ""
        }
    ],
    "xaxis": {
        "title": "Wings"
    }
}

const scadaWorking = {
  "idnumber": "5",
  "title": "Total Working Plants (Scada)",
  "type": "bar",
  "labels": [""],
  "datasets": [
      {
          "label": "CP",
          "data": [
              ""
          ]
      },
      {
          "label": "NH",
          "data": [
              ""
          ]
      },
      {
          "label": "State",
          "data": [
              ""
          ]
      },
      {
          "label": "Panchayat",
          "data": [
              ""
          ]
      }
      
  ],
  "showLegend": "true",
  "yaxis": [
      {
          "title": ""
      }
  ],
  "xaxis": {
      "title": "Wings"
  }
}

const [plants, setPlants] =useState([])
const newPlants = useRef([])

  var scadaTotal = 0
  const [modalData, setModalData] = useState("")
  const [loading, setloading] = useState(false)
  const [checked, setChecked] = useState(false)

  const [modalTitle, setModalTitle] = useState("")

  const [modalChartLoading, setModalChartLoading] = useState(false)
  const [chartID, setChartID] = useState("")
  const [active, setActive] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [chartData, setChartData] = useState({})
  const [gridData, setGridData] = useState({})
  const [visible, setVisible] = useState(false)
  const moduleName = useRef("")
const [viewDataLoader, setViewDataLoader] = useState(false)
  const [chartOptions, setChartOptions] = useState([])
    const [today,setToday] = useState(new Date())

  const setModalVisibleFn = (value) => {
    setModalTitle("")
    setModalVisible(value)
  }

  const fetchScadaData = async () =>{
      
      const day = ("0" + today.getDate()).slice(-2);
      const month = ("0" + (today.getMonth() + 1)).slice(-2);
      const year = today.getFullYear();
      
      const formattedDate = day + "-" + month + "-" + year; 
        
        let c = await getScadaData("1",formattedDate)
        //var xml = new XMLParser().parseFromString(c.d);
        try {
          let tempData = c.d
          console.log("testing")
          console.log(tempData)
          tempData = JSON.parse(tempData)

          tempData.Data.map(obj=>{
            if(obj.work_category == "S") {
                obj.work_category = "State"
            } else if(obj.work_category == "P") {
              obj.work_category = "Panchayat"
              
            } else if(obj.work_category == "") {
              obj.work_category = "Misc"
            }

            obj.working_status = obj.working_status == 'Y'? 'Yes': 'No'
            obj.running_status = obj.running_status == 'Y'? 'Yes': 'No'
            obj.plant_ownership = obj.plant_ownership == 'P'? 'Private': 'Govt'
        })
        scadaTotalData.current = tempData
        runningPlants()
        workingPlants()
        } catch(e) {
          scadaData.current["working"] = scadaWorking

          scadaData.current["running"] = scadaPattern

        }
    }

    const workingPlants = () => {
      try {

        const count = {"NH":0,"State":0,"CP":0,"Panchayat":0,"Misc":0}
        let tempData = scadaTotalData.current
        tempData.Data.map((obj)=>{
            if(obj.work_category == "NH") {
                count["NH"] = count["NH"] + 1
            } else if(obj.work_category == "State") {
                count["State"] = count["State"] + 1
            }else if(obj.work_category == "Panchayat") {
                count["Panchayat"] = count["Panchayat"] + 1
            } else if(obj.work_category == "CP") {
                count["CP"] = count["CP"] + 1
            } else if(obj.work_category == "Misc") {
              count["Misc"] = count["Misc"] + 1
          }

        })
        scadaWorking.datasets.map((obj)=>{
          let label = obj.label
            obj.data = []
            if(count[label] != undefined) {
             // scadaTotal = scadaTotal + parseInt(count[label] )  
              obj.data.push(count[label].toString())
            } 
            
      })
      scadaData.current["working"] = scadaWorking
      } catch(e) {
      }
    }

    const runningPlants = () => {
      try {
        scadaTotal = 0

        const count = {"NH":0,"State":0,"CP":0,"Panchayat":0,"Misc":0}
        let tempData = scadaTotalData.current
        tempData.Data.map((obj)=>{
            if (obj.running_status == "Yes") {
              if(obj.work_category == "NH") {
                count["NH"] = count["NH"] + 1
                } else if(obj.work_category == "State") {
                    count["State"] = count["State"] + 1
                }else if(obj.work_category == "Panchayat") {
                    count["Panchayat"] = count["Panchayat"] + 1
                } else if(obj.work_category == "CP") {
                    count["CP"] = count["CP"] + 1
                } else if(obj.work_category == "Misc") {
                  count["Misc"] = count["Misc"] + 1
              }
            }
           

        })
        scadaPattern.datasets.map((obj)=>{
          let label = obj.label
            obj.data = []
            if(count[label] != undefined) {
              scadaTotal = scadaTotal + parseInt(count[label] )  
              obj.data.push(count[label].toString())
            } 
            
      })

      scadaData.current["running"]=scadaPattern

      } catch(e) {
      }
}

  const widgetClick = useCallback(async ()=> {
    setActive(true)
   // fetchHotMixPlantData()
  //  let modalData = await GetHotMixData()

    //setModalData(modalData)
    setVisible(true)
    setActive(false)
  }, [])


  const onModalClose = () => {
    setOpenModal(false);
  }



  const setChartGridData = (chart) => {
    if (chart.type == "pie") {
      const cols = [
        { title: 'Name', dataIndex: 'label', key: 'label' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
      ]
      let total = 0
      let rows = chart.labels.map((d, index) => {
        if (chart.datasets[0].data[index] == "" || chart.datasets[0].data[index] == undefined) {
          chart.datasets[0].data[index]="0"
        }
        total = total + parseInt(chart.datasets[0].data[index])
        return ({ 
          label: d, value: chart.datasets[0].data[index], key: index 
        })
      })

      let row = {
          label: "Total",
          value:total
      }
      rows.push(row)
      setGridData({ rows, cols })
    } else {
      let colsList = []
      let cols =[{ title: 'Label', dataIndex: 'label' }]
      let total = []
      for (let i = 0;i < chart.datasets.length; i++) {
          cols.push({title: chart.datasets[i].label , dataIndex: chart.datasets[i].label.toLowerCase()})
          colsList.push(chart.datasets[i].label.toLowerCase())
      }
      let data = []
      colsList.map((col, index) => {
        total[index] = 0
      })
      for (let j=0; j<chart.labels.length;j++) {
          //total[j] = 0
          let el = {}
          colsList.map((col, index) => {
            total[index] = total[index] + parseFloat(chart.datasets[index].data[j])
            el[col] = chart.datasets[index].data[j]
          })
          
          el["key"] = j
          el["label"] = chart.labels[j]
          data.push(el)
      }
      let rows = data
      let row = {}
      
     
      colsList.map((col, index) => {
        row[col] = total[index]
      })
      row["label"] = "Total"
      row["key"] = chart.labels.length
      rows.push(row)

      setGridData({ rows, cols })
    }
}


  const handleClickOnCardExpand = (i) => {
    try{
      setloading(true)

      let chart = chartOptions[i]
      setChartData(chartOptions[i])
      setChartID(chartOptions[i].idnumber)
      window.globalChartID = chartOptions[i].idnumber
      setChartGridData(chart)
      setOpenModal(true)
      setloading(false)

      } catch(e) {
          console.log(e)
      }
  }


  const ScadaChartClick =  useCallback(async (databaseName, label)=> {
    setloading(true)
    let temp =  scadaTotalData.current   
    temp.Data = temp.Data.filter(obj=>obj.running_status == "Yes" && obj.work_category == label)
    let scadaData = JSON.stringify(temp)
    let chartData = {d:scadaData}
    setModalTitle("Total Running Plants (SCADA)")
    setModalData(chartData)
    setModalVisible(true)
    setActive(false)
    setloading(false)
  },[])
  const ScadaWorkingChartClick =  useCallback(async (databaseName, label)=> {
    setloading(true)
    let temp =  scadaTotalData.current   
    temp.Data = temp.Data.filter(obj=>obj.work_category == label)
    let scadaData = JSON.stringify(temp)

    let chartData = {d:scadaData}
    //let chartData = await GetHotMixPlantData("ALLDATA")
    setModalTitle("Total Wokring Plants (SCADA)")
    setModalData(chartData)
    setModalVisible(true)
    setActive(false)
    setloading(false)
  },[])


 const onChange = async (e)=>{
    try{
        setToday(e._d)
    } catch(e) {

    }
 }

 const toCamelCase = (str) => {
  return str.replace(/_./g, match => match.charAt(1).toUpperCase()).replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

// Function to modify keys in an object
const modifyKeys = (obj) => {
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = toCamelCase(key.replace(/_/g, ' '));
    newObj[newKey] = value;
  }
  return newObj;
};

 const viewDataFn = () =>{
    setViewDataLoader(true)
    let temp =  scadaTotalData.current   

    let newData = temp?.Data?.map(obj=>  modifyKeys(obj))

    temp.Data = newData
    let scadaData = JSON.stringify(temp)
    let chartData = {d:scadaData}
    setModalTitle("Total Running Plants (SCADA)")
    setModalData(chartData)
    setModalVisible(true)
    setViewDataLoader(false)
 }


 const handleAddFacility = async (obj) => {
  // Geocode the address to get the latitude and longitude coordinates
  const geocoder = new window.google.maps.Geocoder();
  let addr = obj["plant_location"]
  let facility = await geocoder.geocode({ address:addr }, (results, status) => {
    if (status === 'OK') {
      const latLng = results[0].geometry.location;
      let name = obj["name_of_plant"]
      let address = obj["plant_location"]
      let type = obj["running_status"]
      let lat = latLng.lat()
      let lng = latLng.lng()
      try {
        localStorage.setItem(name,JSON.stringify({ name, address, type, lat,lng }))
        newPlants.current = [...newPlants.current,{ name, address, type, lat,lng }]

      } catch (e) {

      }

  
    } else {
      alert(`Geocode was not successful for the following reason: ${status}`);
    }
  });

};


 const fetchMap = async () => {
  let count = 0
  let scadaList = scadaTotalData.current.Data
  newPlants.current = []
  scadaList.filter(obj=>{
  if (obj["work_category"] != "Misc")  {

    newPlants.current.push( {
      name:obj["name_of_plant"],
      address: obj["plant_location"],
      type: obj["running_status"],
      lat:obj["oprlat"],
      lng:obj["oprlong"],
      district:obj["district"],
      netmix:obj["netmix"],
      wing:obj["work_category"]

    })
  }
    
  })
  setPlants(newPlants.current)
 }
 const callFetchScadaData = async () => {
    let c = await fetchScadaData()
    console.log(scadaData)

    let temp = []
    temp.push(scadaData.current["running"])
    temp.push(scadaData.current["working"])
    
    setChartOptions(temp)

    await fetchMap()
}

  useEffect(() => {
      try{
        callFetchScadaData()
      } catch(e) {
        console.log("Error",e)
      }
  },[today])
    return (
        <>
   <div className="p-2 bg-secondary border rounded mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: '30%' }} className="rounded-lg overflow-hidden">
          <DatePicker  size='large' style={{width:"300px"}} onChange={(e)=>{onChange(e)}} />

          </div>
          <div style={{fontSize:"18px"}}>
           <b>Scada</b> 
          </div>
          <div className="d-flex "  >
          <CDropdown color='primary' className='m-2' variant='outline'>
  <CDropdownToggle color="primary">Reports</CDropdownToggle>
  <CDropdownMenu>
    <CDropdownItem target='_blank' href="http://103.27.120.198/RNBDW/login.aspx?encryptedurl=admin;101">Scada Plant Information</CDropdownItem>
    <CDropdownItem target='_blank' href="http://103.27.120.198/RNBDW/login.aspx?encryptedurl=admin;102">Scada Plant Summary</CDropdownItem>
  </CDropdownMenu>
</CDropdown>
          <CButton color='primary' className='m-2' variant='outline' onClick={viewDataFn}  >
          {viewDataLoader ? <><CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                 Loading...</> : <>View Data</>}
            </CButton>
          <CButton color='danger' className='m-2'  variant='outline' onClick={()=>{location.href="https://rnbwms.guj.nic.in/"}} >
            Modify Data
            </CButton>
            <CButton color='success' className='m-2' variant='outline' onClick={()=>{
                location.reload();
            }}>
            {<>Refetch</>}
            </CButton>
          </div>
        </div>
        {/* {selectedFilters.length !== 0 && (
          <div className="d-flex mt-2 align-items-center">
            {selectedFilters.map((m, i) => (
              <Chip key={i} name={m.name} onClose={() => onRemoveFilter(m)} />
            ))}
          </div>
        )} */}
      </div>
<CRow className='mb-5'>

  <CCol xl={6}>
  <Map facilities={plants}></Map>


  </CCol>
  {/* <CCol xl={6}>

   {chartOptions[0] ?
   (
    <Card title={"Total Running Plants (Scada)"}  onClick={() => {handleClickOnCardExpand(0)}} >
      <ChartWrapper options={chartOptions[0]} chartClickEvent={ScadaChartClick} />
    </Card>
   )
    : (<Card title="Total Running Plants (Scada)" >
    <p>Loading</p>
  </Card>)}
  </CCol> */}
    <CCol xl={6}>
    {chartOptions[1] ?
   (
    <Card title={"Total Working Plants (Scada)"}  onClick={() => {handleClickOnCardExpand(1)}} >
      <ChartWrapper options={chartOptions[1]} chartClickEvent={ScadaWorkingChartClick} />
    </Card>
   )
    : (<Card title="Total Working Plants (Scada)" >
    <p>Loading</p>
  </Card>)}
    </CCol>
  </CRow>

  {loading && <Loader />}

  {openModal && <NewModal open={openModal} type={chartData.type} title={chartData.title} onClose={onModalClose}>
        <div className="container" >
          <div className="row">
            <div className={chartData.type === 'pie'?'col-8':'col-10'}>{
            !checked ? ( modalChartLoading ?
             <div style={{height:'522px'}}> 
             {/* <Skeleton height={'60px'}></Skeleton> */}
             <Loading loading loaderColor="#3498db" />
             </div> :
              <ChartWrapper options={chartData}  /> )
            : (<GridView cols={gridData.cols} rows={gridData.rows} />)
            }</div>
            <div className={chartData.type === 'pie'?'col-4':'col-2'}>
              <div>
                <h5 className="font-weight-bolder m-0">View Details ♒</h5>
               
              </div>
              <div className="d-flex align-items-center py-2">
                <ToggleSwitch checked={checked} onChange={setChecked} />
                <div className="ml-2">Gird View</div>
              </div>
            </div>
          </div>
        </div>
      </NewModal>}

{
  modalVisible && <Modal open={modalVisible} isMapRequired={false}  setVisibleFn={setModalVisibleFn} type="table"  title={modalTitle} value="" data={modalData}></Modal>
}
<CModal size="xl" scrollable visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader onClose={() => setVisible(false)}>
        <CModalTitle>Scada Hot Mix Plant Data</CModalTitle>
      </CModalHeader>
      <CModalBody><HotMixPlant></HotMixPlant></CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Close
        </CButton>
      </CModalFooter>
    </CModal>

        
        </>
    )
}


export default SCADA