import moment from "moment";

 export const statusData = [
   {
      lable: 'In Progress',
      value: 'In Progress'
   },
   {
      lable: 'Not Started',
      value: 'Not Started'
   },
   {
      lable: 'Completed',
      value: 'Completed'
   },
 ];

 export const talukaData = [
   {
      lable: 'Amirgadha',
      value: 'Amirgadha'
   }
 ];

 export const circleData = [
   {
      lable: 'Nh circle, vadodara',
      value: 'Nh circle, vadodara'
   }
 ];

 export const yearData = [
   {
      lable: '2016',
      value: '2016'
   },
   {
      lable: '2017',
      value: '2017'
   },
   {
      lable: '2018',
      value: '2018'
   },
   {
      lable: '2019',
      value: '2019'
   },
   {
      lable: '2020',
      value: '2020'
   },
   {
      lable: '2021',
      value: '2021'
   },
   {
      lable: '2022',
      value: '2022'
   },
   {
      lable: '2023',
      value: '2023'
   },
 ];
 
 const helper = {
    statuscheck: function (type) {
       if(type === 'Not Started'){
        return "border-danger text-danger"
       }else if(type === 'In Progress'){
        return "border-warning text-warning"
       }else{
        return "border-success text-success"
       }
    },
    checkNumeric: function (label) {
      if(label.includes('Lakh') || label.includes('Limit' ) || label.includes('Expenditure') || label.includes("Amount")|| label.includes("Length")){
         return true
      }
    },
    checkDropdowField: function (label) {
      if(label.includes('Details of Status') || label.includes('Circle') || label.includes('Taluka') || label.includes('Division') || label.includes("Financial Year")){
         return true
      }
    },
    checkDropDownData: function (label) {
      if(label === 'Details of Status'){
         return statusData;
      }else if(label === 'Circle'){
         let dat = localStorage.getItem('circle')
         let datPars = JSON.parse(dat)
         return datPars;
      }else if(label === 'Taluka'){
         let dat = localStorage.getItem('taluka')
         let datPars = JSON.parse(dat)
         return datPars;
      }else if(label === 'Division'){
         let dat = localStorage.getItem('division')
         let datPars = JSON.parse(dat)
         return datPars;
      }else if(label === 'Financial Year'){
         return yearData;
      }
    },
    checkDateFormat:  function (label, value) {
      let data = moment(value).format("DD/MM/YYYY")
      if(label.includes("Date")){
         return new Date(data)
      }
    },
    checkInputFinanceLable: function(label){
      if(label.includes('Lakh') || label.includes('Limit' ) || label.includes('Expenditure') || label.includes("Amount") || label.includes("BudgetItem") || label.includes("SpillOver")){
         return false
      }
    },
    checkInputFinanceLableOfAddRecord: function(label){
      if(label.field.includes('Lakh') || label.field.includes('Limit' ) || label.field.includes('Expenditure') || label.field.includes("Amount") || label.field.includes("BudgetItem") || label.field.includes("SpillOver")){
         return false
      }
    },
   
}
export default helper