import React, { useEffect, useState } from 'react'
import { tableInfo } from '../../views/dashboard/dummyJson/tabledatajson'
import EditAddRecord from '../../layout/EditAddRecord';


export const AddRecord = () => {  

  const [tableInfo,setTableInfo] = useState({})
  const [wing, setWing] = useState({})

  const getData = async () => {
    let info = await localStorage.getItem('table_info')
    let c = await localStorage.getItem('wing_name')
    setWing(c)
    let dat =  JSON.parse(info)
    dat = JSON.parse(dat)
    setTableInfo(dat.Data[0])


  }
  useEffect(()=>{
    getData()


  },[])
  return (
    <EditAddRecord data={tableInfo} wing={wing}/>
  )
}
