import React, { useState } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker'
import { Button, Tabs } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
const { TabPane } = Tabs

function CustomDateFilter({ DateName="", setVal }) {
  const [filterText, setFilterText] = useState('Select filter')
  const [type, setType] = useState('FIXED')
  const [selectedDay, setSelectedDay] = useState({ from: null, to: null })
  const [selectedFixedRange, setSelectedFixedRange] = useState('')
  const options = ['Last Year', 'Last 6 Months', 'Last Financial Year']
  const [rangeError, setRangeError] = useState(false)
  const [open, setOpen] = useState(false)

  const handleApply = () => {
    if (type === 'FIXED') {
      if (!selectedFixedRange) setRangeError(true)
      else {
        const today = new Date();

        if (selectedFixedRange == "Last 6 Months") {

          setFilterText(`${new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()).toDateString().slice(4)} - ${new Date(today.getFullYear(), today.getMonth(), today.getDate()).toDateString().slice(4)}`)
          setVal(`${new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()).toDateString().slice(4)} - ${new Date(today.getFullYear(), today.getMonth(), today.getDate()).toDateString().slice(4)}`)
       
        } else if (selectedFixedRange == "Last Year") {
          setFilterText(`${new Date(today.getFullYear()-1, today.getMonth() , today.getDate()).toDateString().slice(4)} - ${new Date(today.getFullYear(), today.getMonth(), today.getDate()).toDateString().slice(4)}`)
          setVal(`${new Date(today.getFullYear()-1, today.getMonth(), today.getDate()).toDateString().slice(4)} - ${new Date(today.getFullYear(), today.getMonth(), today.getDate()).toDateString().slice(4)}`)
        } else if (selectedFixedRange == "Last Financial Year") {
          setFilterText(`${new Date(today.getFullYear() - 1, 2, 31).toDateString().slice(4)} - ${new Date(today.getFullYear(), today.getMonth(), today.getDate()).toDateString().slice(4)}`)
          setVal(`${new Date(today.getFullYear() - 1, 2, 31).toDateString().slice(4)} - ${new Date(today.getFullYear(), today.getMonth(), today.getDate()).toDateString().slice(4)}`)
        }
        
        setRangeError(false)
        setOpen(false)
      }
    } else {
      if (!selectedDay.from || !selectedDay.to) setRangeError(true)
      else {
        setRangeError(false)
        setFilterText(`${new Date(selectedDay.from.year, selectedDay.from.month - 1, selectedDay.from.day, 12).toDateString().slice(4)} - ${new Date(selectedDay.to.year, selectedDay.to.month - 1, selectedDay.to.day, 12).toDateString().slice(4)}`)
        setVal(`${new Date(selectedDay.from.year, selectedDay.from.month - 1, selectedDay.from.day, 12).toDateString().slice(4)} - ${new Date(selectedDay.to.year, selectedDay.to.month - 1, selectedDay.to.day, 12).toDateString().slice(4)}`)
        setOpen(false)
      }
    }
  }
  const onCancel = () => {
    setFilterText('Select filter')
    setRangeError(false)
    setOpen(false)
  }

  return (
    <div className="w-full relative">
      <div className="font-bold text-sm text-gray-700">{DateName}</div>
      <Button size="large" onClick={() => setOpen(!open)} className="w-full d-flex justify-content-between align-items-center">
        {filterText}
        <CaretDownOutlined />
      </Button>
      {open && (
        <div id="dropdown" className="mt-1 rounded border border-gray-300 px-4 py-2 bg-white absolute">
          <Tabs defaultActiveKey="1" onChange={(key) => setType(key)}>
            <TabPane tab="Fixed Period" key="FIXED">
              <div className="h-36 scrollable-div overflow-y-scroll">
                {options.map((op) => (
                  <button key={op} onClick={() => setSelectedFixedRange(op)} className={`w-full p-2 border-0 outline-0 ${selectedFixedRange === op ? 'bg-secondary' : ''} `}>
                    {op}
                  </button>
                ))}
              </div>
            </TabPane>
            <TabPane tab="Date Range" key="RANGE">
              <Calendar calendarClassName="no-shadow" colorPrimary="#ff781e" colorPrimaryLight="#ffc198aa" value={selectedDay} onChange={(date) => setSelectedDay(date)} shouldHighlightWeekends />
            </TabPane>
          </Tabs>
          {rangeError && <div className="font-bold text-xs text-red-600">*Please select the period/date range</div>}
          <div className="d-flex justify-content-between mt-2">
            <Button className="w-50" onClick={onCancel}>
              Cancel
            </Button>
            <Button className="w-50" type="primary" onClick={handleApply}>
              Apply
            </Button>
          </div>1
        </div>
      )}
    </div>
  )
}

export default CustomDateFilter
