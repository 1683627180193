import React from 'react'
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CButtonGroup,
  CCardTitle,
  CCardText,
  CCardImage,
  CButton,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CFormSelect,
} from '@coreui/react'
import { CWidgetStatsA, CWidgetStatsB,CWidgetStatsD,CWidgetStatsF, CFormSwitch, CSpinner } from '@coreui/react'
import Modal from '../../components/shared/ModalTable'
import Loading from 'react-fullscreen-loading'
import NewModal from '../../components/shared/Modal'
import ToggleSwitch from '../../components/shared/ToggleSwitch'
import getScadaData from '../../services/general/getScadaData.api'
import XMLParser from 'react-xml-parser'
import getAllWorkData from '../../services/general/getAllWorkData.api.js'
import getAllHomeWorks from '../../services/general/getAllHomeWorks.api.js'
import getAllCommonCharts from '../../services/general/getAllCommonCharts.api.js'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'
import Loader from '../../components/shared/Loader'
import getChartsWithFilter from '../../services/general/getChartsData.api.js'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import FilterDrawer from '../../components/shared/FilterDrawerHome'
import GridView from '../../components/shared/GridView'
import { Suspense, lazy } from 'react'

import { getAllHomeChartsTemp, getAllFilters, getAllWork } from '../../services/apicalls.js'
// import LoadingOverlay from 'react-loading-overlay'
// import BounceLoader from 'react-spinners/BounceLoader'
import GetHomeChartsDataTemp from '../../services/general/getHomeChartsDataTemp.api.js'
import getAllHomeChartsTemp_API from '../../services/general/getAllHomeChartsTemp.api'
import GetHotMixData from '../../services/general/getHotMixPlants.api.js'

import GetHotMixPlantData from '../../services/general/getHotMixPlantData.api.js'

import RPMSIMG from './../../assets/images/rpms.jpg'
import GUJRAMSIMG from './../../assets/images/GujRAMS.png'
import STATEIMG from './../../assets/images/avatars/R&BD.jpg'
import NHIMG from './../../assets/images/avatars/NH.jpg'
const WidgetWrapper = lazy(() => import('./../../components/shared/WidgetWrapper'))
const ChartWrapper = lazy(() => import('../../components/shared/HomeChartWrapper'))
const Card = lazy(() => import('../../components/Card'))
import CustomAgGrid from './../../components/shared/CustomAgGrid'
import { useEffect } from 'react'
import './Home.css'
import HotMixPlant from './HotMixPlant'

import { useState, useRef } from 'react'
import { useCallback } from 'react'
import getAllHomeCharts from '../../services/general/getAllHomeCharts.api'
import { filterData, scadaPattern, scadaWorking } from './dummyJson/homeData'
import helper from './dummyJson/helper'
import { useQuery } from 'react-query'
import { ExportData } from './ExportData'
import { ExportPdf } from './ExportPdf'

const ProgressCard = ({ title, wType, value, color, size=2, map }) => {
  return (
    <CCol sm={size} >
      <Suspense fallback={<div>Loading...</div>}>
        <WidgetWrapper title={title} workType={wType} value={value} color={color} map={map}></WidgetWrapper>
      </Suspense>
    </CCol>
  )
}

const  ShowChartData = ({ xl, chartOptions, title, onClick, chartClickEvent, chartID }) => {

  return (

      <CCol className='mb-3' xl={xl}>
         {chartOptions ?  <Card 
 title={title} onClick={onClick}>
           <ChartWrapper options={chartOptions} chartClickEvent={chartClickEvent} chartID={chartID} />          </Card>
:(<Card title="" >
    <p>Loading...</p>
  </Card>)
            }
      </CCol>
   
  )
}



const ALL = () => {

  const loginID = '1'
  const moduleID = '-2'
  const tranTypeID = '-2'
  const wing = JSON.parse(localStorage.getItem("loggedInUserData")).Wing
  const [allStatus, setAllStatus] = useState(['Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...'])
  const scadaTotalData = useRef({})
  const [isDrawerOpened, setDrawerOpened] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const scadaData = useRef({ running: {}, working: {} })
  const [filterColor, setFilterColor] = useState(false)
  var scadaTotal = 0
  const [modalData, setModalData] = useState('')
  const [watchListData, setwatchListData] = useState('')
  const [loading, setloading] = useState(false)
  const [filterValues, setFilterValues] = useState([])
  const [selectedModalFilters, setSelectedModalFilter] = useState([])

  const { isLoading, data: chartsData } = getAllHomeChartsTemp(loginID, moduleID, tranTypeID, '')
  //const {isLoading, data: chartsData } = getAllHomeChartsTemp(loginID, moduleID, tranTypeID,"")

  const { isLoading: isLoadingWorkData, data: workData } = getAllWork()
  
  const [checked, setChecked] = useState(false)

  const [modalTitle, setModalTitle] = useState('')

  const [lengthChartData, setLengthChartData] = useState([])
  const [modalChartLoading, setModalChartLoading] = useState(false)
  const [chartID, setChartID] = useState('')
  const [active, setActive] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [chartData, setChartData] = useState({})
  const [gridData, setGridData] = useState({})
  const [visible, setVisible] = useState(false)
  const moduleName = useRef('')

  async function fetchData(filters) {
    setModalChartLoading(true)
    window.filters = filters
    let chartData = await getChartsWithFilter(chartID, filters)
    try {
      chartData.then(
        (result) => {
          let temp = JSON.parse(result.d)
          setChartData(temp[0])
          setChartID(temp[0].idnumber)
          setChartGridData(temp[0])
          setModalChartLoading(false)
        },
        function (error) {
          setModalChartLoading(false)
          console.log(error)
        },
      )
    } catch (err) {
      console.log('chart failed')
    }
  }

  const [chartOptions, setChartOptions] = useState([])

  const chartClickEvent = async (databaseName, label, chartID) => {
    let id = -1
    try {
      id = chartOptions[chartID].idnumber
    } catch (e) {}
    setloading(true)
    setActive(true)
    let modalData = await GetHomeChartsDataTemp(databaseName, label, id, JSON.stringify(window.filters))
    setModalData(modalData)
    setModalVisible(true)
    setActive(false)
    setloading(false)
  }

  const setModalVisibleFn = (value) => {
    setModalTitle('')
    setModalVisible(value)
  }

  const setWidgetData = async () => {
    if(workData){
    let totalHotMixPlantData = await GetHotMixPlantData('TOTAL')
    totalHotMixPlantData = await JSON.parse(totalHotMixPlantData.d)
    totalHotMixPlantData = totalHotMixPlantData.Data[0]['Total']
    let tempAllStatus = workData.d.split(',')
    //tempAllStatus[4] = scadaTotal
    setAllStatus(tempAllStatus)
    return tempAllStatus
    }
    //  let allDataHotMixPlantData = await GetHotMixPlantData("ALLDATA")
  }

  const fetchScadaData = async () => {
    const date = new Date()

    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    const formattedDate = day + '-' + month + '-' + year

    let c = await getScadaData('1', formattedDate)
    //var xml = new XMLParser().parseFromString(c.d);
    try {
      let tempData = c.d
      tempData = JSON.parse(tempData)
      tempData.Data.map((obj) => {
        if (obj.work_category == 'S') {
          obj.work_category = 'State'
        } else if (obj.work_category == 'P') {
          obj.work_category = 'Panchayat'
        } else if (obj.work_category == '') {
          obj.work_category = 'Misc'
        }

        obj.working_status = obj.working_status == 'Y' ? 'Yes' : 'No'
        obj.running_status = obj.running_status == 'Y' ? 'Yes' : 'No'
        obj.plant_ownership = obj.plant_ownership == 'P' ? 'Private' : 'Govt'
      })
      //runningPlants()
      scadaTotalData.current = tempData

      workingPlants()
      return scadaTotalData.current
      
    } catch (e) {
      scadaData.current['working'] = scadaWorking

    }
  }


 

    const applyFilterFn = async (filters) => {
      setloading(true)
      window.filters = filters
      let c = await getAllHomeChartsTemp_API(loginID, moduleID, tranTypeID,JSON.stringify(filters) )
      await callFetchScadaData(c.d,JSON.stringify(filters))
      setloading(false)
  }

  const workingPlants = () => {
    try {
      scadaTotal = 0

      const count = { NH: 0, State: 0, CP: 0, Panchayat: 0, Misc: 0 }
      let tempData = scadaTotalData.current
      tempData.Data.map((obj) => {
        if (obj.work_category == 'NH') {
          count['NH'] = count['NH'] + 1
        } else if (obj.work_category == 'State') {
          count['State'] = count['State'] + 1
        } else if (obj.work_category == 'Panchayat') {
          count['Panchayat'] = count['Panchayat'] + 1
        } else if (obj.work_category == 'CP') {
          count['CP'] = count['CP'] + 1
        } else if (obj.work_category == 'Misc') {
          count['Misc'] = count['Misc'] + 1
        }
      })
      scadaWorking.datasets.map((obj) => {
        let label = obj.label
        obj.data = []
        if (count[label] != undefined) {
          scadaTotal = scadaTotal + parseInt(count[label])
          obj.data.push(count[label].toString())
        }
      })
      scadaData.current['working'] = scadaWorking
    } catch (e) {}
  }

  const runningPlants = () => {
    try {
      scadaTotal = 0

      const count = { NH: 0, State: 0, CP: 0, Panchayat: 0, Misc: 0 }
      let tempData = scadaTotalData.current
      tempData.Data.map((obj) => {
        if (obj.running_status == 'Yes') {
          if (obj.work_category == 'NH') {
            count['NH'] = count['NH'] + 1
          } else if (obj.work_category == 'State') {
            count['State'] = count['State'] + 1
          } else if (obj.work_category == 'Panchayat') {
            count['Panchayat'] = count['Panchayat'] + 1
          } else if (obj.work_category == 'CP') {
            count['CP'] = count['CP'] + 1
          } else if (obj.work_category == 'Misc') {
            count['Misc'] = count['Misc'] + 1
          }
        }
      })
      scadaPattern.datasets.map((obj) => {
        let label = obj.label
        obj.data = []
        if (count[label] != undefined) {
          scadaTotal = scadaTotal + parseInt(count[label])
          obj.data.push(count[label].toString())
        }
      })

      scadaData.current['running'] = scadaPattern
    } catch (e) {}
  }

  const widgetClick = useCallback(async () => {
    setActive(true)
    // fetchHotMixPlantData()
    //  let modalData = await GetHotMixData()

    //setModalData(modalData)
    setVisible(true)
    setActive(false)
  }, [])
  const [watchListLoader, setWatchListLoader] = useState(false)

  const onModalClose = () => {
    setOpenModal(false)
  }

  const viewAllClick = async (e) => {
    setWatchListLoader(true)
    moduleName.current = 'WatchList'
    let chartData = await getAllWorkData(loginID, 'Total')
    setModalTitle('All Works - Watch List')
    setModalData(chartData)
    setModalVisible(true)
    setWatchListLoader(false)

    setActive(false)
  }
  const setChartGridData = (chart) => {
    if (chart.type == 'pie') {
      const cols = [
        { title: 'Name', dataIndex: 'label', key: 'label' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
      ]
      let total = 0
      let rows = chart.labels.map((d, index) => {
        if (chart.datasets[0].data[index] == '' || chart.datasets[0].data[index] == undefined) {
          chart.datasets[0].data[index] = '0'
        }
        // if(d.toLowerCase().includes("total")){
         
        // }else{
          total = total + parseInt(chart.datasets[0].data[index])
        // }
       
        return {
          label: d,
          value: chart.datasets[0].data[index],
          key: index,
        }
      })
      // This is unnecessary, Please find a  better way
      const chartLabels = chart.labels.map(s => s.toLowerCase());

      if(!chartLabels.includes('total')){
        let row = {
          label: 'Total',
          value: total,
        }
        rows.push(row)
      }
       
      setGridData({ rows, cols })
    
    } else {
      let colsList = []
      let cols = [{ title: 'Label', dataIndex: 'label' }]
      let total = []
      for (let i = 0; i < chart.datasets.length; i++) {
        cols.push({ title: chart.datasets[i].label, dataIndex: chart.datasets[i].label.toLowerCase() })
        colsList.push(chart.datasets[i].label.toLowerCase())
      }
      let data = []
      colsList.map((col, index) => {
        total[index] = 0
      })
      for (let j = 0; j < chart.labels.length; j++) {
        //total[j] = 0
        let el = {}
        colsList.map((col, index) => {
          if(col !== "Total"){
            total[index] = total[index] + parseFloat(chart.datasets[index].data[j])
          }
        
          el[col] = chart.datasets[index].data[j]
        })

        el['key'] = j
        el['label'] = chart.labels[j]
        data.push(el)
      }
      let rows = data
      let row = {}

      colsList.map((col, index) => {
        row[col] = total[index]
      })

      const chartLabels = chart.labels.map(s => s.toLowerCase());
      if(!chartLabels.includes('total')){
        row['label'] = 'Total'
        row['key'] = chart.labels.length
        rows.push(row)
  
      }
  
      setGridData({ rows, cols })
    }
  }

  // const fetchWatchListData = async (t) => {
  //   const temp = await getAllHomeWorks('1', 'get', '')
  //   setwatchListData(temp.d)
  //   return temp.d
  // }
  const saveWatchList = async (rowsIDs) => {
    if (rowsIDs.length > 0) {
      let t = rowsIDs.join(',')
      const temp = await getAllHomeWorks('1', 'update', t)
      setwatchListData(temp.d)
      alert('Watch List Saved')
    }
  }

  const handleClickOnCardExpand = (i) => {
    try {
      setloading(true)

      let chart = chartOptions[i]
      setChartData(chartOptions[i])
      setChartID(chartOptions[i].idnumber)
      window.globalChartID = chartOptions[i].idnumber
      setChartGridData(chart)
      setOpenModal(true)
      setloading(false)
    } catch (e) {
      console.log(e)
    }
  }

  // const ScadaChartClick = useCallback(
  //   async (databaseName, label) => {
  //     setloading(true)
  //     let temp = scadaTotalData.current
  //     temp.Data = temp.Data.filter((obj) => obj.running_status == 'Yes' && obj.work_category == label)
  //     let scadaData = JSON.stringify(temp)
  //     let chartData = { d: scadaData }
  //     setModalTitle('Total Running Plants (SCADA)')
  //     setModalData(chartData)
  //     setModalVisible(true)
  //     setActive(false)
  //     setloading(false)
  //   },
  //   [chartsData],
  // )
  // const ScadaWorkingChartClick = useCallback(
  //   async (databaseName, label) => {
  //     setloading(true)
  //     let temp = scadaTotalData.current
  //     temp.Data = temp.Data.filter((obj) => obj.work_category == label)
  //     let scadaData = JSON.stringify(temp)

  //     let chartData = { d: scadaData }
  //     //let chartData = await GetHotMixPlantData("ALLDATA")
  //     setModalTitle('Total Working Plants (SCADA)')
  //     setModalData(chartData)
  //     setModalVisible(true)
  //     setActive(false)
  //     setloading(false)
  //   },
  //   [chartsData],
  // )

  // const typeOfRoadChart = useCallback((databaseName, label) => {
  //     setloading(true)
  //     let temp = JSON.parse(chartsData.d)
  //     let options = temp[2]
  //     let c = {}
  //     c.Data = []
  //     options['labels'].forEach((element, i) => {
  //       let ele = {}
  //       ele['AutoID'] = i + 1
  //       ele['Road Type'] = options['labels'][i]

  //       ele['Length(in Kms)'] = options['datasets'][0].data[i]

  //       c.Data.push(ele)
  //     })
  //   },[])



  const ScadaChartClick =  useCallback(async (databaseName, label)=> {
    setloading(true)
    let temp =  scadaTotalData.current   
    temp.Data = temp.Data.filter(obj=>obj.work_category == label)
    let scadaData = JSON.stringify(temp)
    let chartData = {d:scadaData}
    setModalTitle("Total Working Plants (SCADA)")
    setModalData(chartData)
    setModalVisible(true)
    setActive(false)
    setloading(false)
  },[chartsData])
  const ScadaWorkingChartClick =  useCallback(async (databaseName, label)=> {
    setloading(true)
    let temp =  scadaTotalData.current   
    temp.Data = temp.Data.filter(obj=>obj.work_category == label)
    let scadaData = JSON.stringify(temp)

    let chartData = {d:scadaData}
    //let chartData = await GetHotMixPlantData("ALLDATA")
    setModalTitle("Total Working Plants (SCADA)")
    setModalData(chartData)
    setModalVisible(true)
    setActive(false)
    setloading(false)
  },[chartsData])


  // const typeOfRoadChart =  useCallback((databaseName, label)=> {
  //   setloading(true)
  //   let temp = JSON.parse(chartsData.d)
  //   let options = temp[2]
  //   let c = {}
  //   c.Data = []
  //   options["labels"].forEach((element,i) => {
  //     let ele ={}
  //     ele["AutoID"] = i + 1
  //     ele["Road Type"] = options["labels"][i]
      
  //     ele["Length(in Kms)"] = options["datasets"][0].data[i]
      
  //     c.Data.push(ele)

  //   });
    

  //     let jsonData = JSON.stringify(c)

  //     let d = {}
  //     d['d'] = jsonData

  //     setModalData(d)
  //     setModalVisible(true)
  //     setloading(false)
  //   },
  //   [chartsData],
  // )

  const fetchingEverything = () => {
    fetch('')
  }

  const callFetchScadaData = async (data,filters) => {
      let dcharts = await getAllCommonCharts(loginID, moduleID, tranTypeID, filters)

      let temp = JSON.parse(data)
  
      let tempCharts = JSON.parse(dcharts.d)
      temp = temp.concat(tempCharts)
      await fetchScadaData()
      scadaData.current['working']['idnumber'] = (temp.length + 1).toString()
      temp.push(scadaData.current['working'])
      setChartOptions(temp)
      
      return temp;
    
  }

  // const {data:scada } = useQuery('scada',fetchScadaData)
 const {data: widegate, refetch: widegtRefetch } = useQuery('wideget',setWidgetData,{variables: {workData, isLoading, isLoadingWorkData}})
  // const {data: whatList } = useQuery('watchList',fetchWatchListData)
//   const {data: list, refetch: callScadaRefech  } = useQuery(['scadaList',chartsData.d],()=>callFetchScadaData(chartsData.d), { 
// })

  useEffect(() => {
    if (!isLoading && !isLoadingWorkData) {
      callFetchScadaData(chartsData.d)
      widegtRefetch()
      }
  }, [isLoading, isLoadingWorkData])
  
  return (
    <>
      {/* {wing == "0" ?
        <CRow className="mb-3">
          <CCol>
            <CAccordion>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>
                  <CRow>
                    <CCol>
                      <b>Watch List</b>

                      <CButton style={{ marginLeft: '10px' }} onClick={viewAllClick} variant="outline">
                        {watchListLoader ? (
                          <>
                            <CSpinner component="span" size="sm" variant="grow" aria-hidden="true" />
                            Loading...
                          </>
                        ) : (
                          <>Add</>
                        )}
                      </CButton>
                    </CCol>
                  </CRow>
                </CAccordionHeader>
                <CAccordionBody>
                  <CustomAgGrid options={watchListData}></CustomAgGrid>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </CCol>
        </CRow>: 
      null} */}

      <CRow  >
        <div className='row flexcroll' style={{}}>
          
      
        <ProgressCard title={allStatus[0]} workType="Road" value="Not Started (NS)" color="danger" size={3}  map={'Not Started'}/>
        <ProgressCard title={allStatus[1]} workType="Road" value="In Progress (IP)" color="warning" size={3} map={'In Progress'} />
        <ProgressCard title={allStatus[2]} workType="Road" value="Completed (C)" color="success" size={3} map={'Completed'}/>
        <ProgressCard title={allStatus[3]} workType="Road" value="Total (NS + IP + C)" color="info" size={3} map={'Total'}/>

        <ProgressCard title={allStatus[4]} workType="Road" value="Phyically Completed (PC)" color="success" size={3} map={'Phy. Completed'}/>

        <ProgressCard title={allStatus[5]} workType="Road" value="Stopped (S)" color="primary" map={'Stopped'}/>
        <ProgressCard title={allStatus[6]} workType="Road" value="Dropped (D)" color="dark" map={'Dropped'}/>
        <ProgressCard title={allStatus[7]} workType="Road" value="Maintenance (M)" color="secondary" map={'Maintenance'}/>
        <ProgressCard title={allStatus[8]} workType="Road" value="Total(NS + IP + C + PC + S + D + M)"  color="info" size={3} map={'Grand Total'}/> 

        </div>

        
      </CRow>

      <CRow className="mb-5 mt-1">
        {chartOptions?.length>0?(chartOptions?.map((chart, i) => {
          console.log(chart)
          let chartSize = 0
          if (chart?.Size) {
            chartSize = chart.Size > 0?chart.Size: chart.type == "pie"?4:chartOptions[i-1]?.type == "pie" && i%2 != 0?8:6

          } else {
            chartSize = chart.type == "pie"?4:chartOptions[i-1]?.type == "pie" && i%2 != 0?8:6
          }
          if (i == chartOptions?.length-1 ) {
            return 
          }
          return(  
            <ShowChartData key={i}
              xl={chartSize}
              chartOptions={chart}
              title={chart.title}
              onClick={() => {
                handleClickOnCardExpand(i)
              }}
              chartClickEvent={chartClickEvent}
              chartID={i}
            />)
            })):(<><CCol><Card>Loading</Card></CCol><CCol><Card>Loading</Card></CCol></>)}
        {/* <ShowChartData
          xl={6}
          chartOptions={chartOptions[0]}
          title="Status of Works (Number)"
          onClick={() => {
            handleClickOnCardExpand(0)
          }}
          chartClickEvent={chartClickEvent}
          chartID={0}
        />
        <ShowChartData
          xl={6}
          chartOptions={chartOptions[1]}
          title="Status of Works (Length in Kms)"
          onClick={() => {
            handleClickOnCardExpand(1)
          }}
          chartClickEvent={chartClickEvent}
          chartID={1}
        /> */}
      </CRow>

      {/* <CRow className="mb-5">
        <ShowChartData
          xl={4}
          chartOptions={chartOptions[2]}
          title="Total Length of Road"
          onClick={() => {
            handleClickOnCardExpand(2)
          }}
          chartClickEvent={chartClickEvent}
          chartID={2}
        />
        <ShowChartData
          xl={7}
          chartOptions={chartOptions[3]}
          title={'Number of Contractors'}
          onClick={() => {
            handleClickOnCardExpand(3)
          }}
          chartClickEvent={chartClickEvent}
          chartID={3}
        />
      </CRow> */}
      {/* <CRow className="mb-5">
        <ShowChartData
          xl={4}
          chartOptions={chartOptions[4]}
          title={'On Going Delayed Works'}
          onClick={() => {
            handleClickOnCardExpand(4)
          }}
          chartClickEvent={chartClickEvent}
          chartID={4}
        />
        <ShowChartData
          xl={7}
          chartOptions={chartOptions[5]}
          title={'Number of Pending Work(Not Started) at each Stage of Work'}
          onClick={() => {
            handleClickOnCardExpand(5)
          }}
          chartClickEvent={chartClickEvent}
          chartID={5}
        />
      </CRow> */}
      {/* <CRow>
        <ShowChartData
          xl={4}
          chartOptions={chartOptions[6]}
          title={'Total Works'}
          onClick={() => {
            handleClickOnCardExpand(6)
          }}
          chartClickEvent={chartClickEvent}
          chartID={6}
        />
      </CRow> */}

      <div className="p-2 bg-secondary border rounded mb-3 mt-3" style={{ textAlign: 'center' }}>
        <div className="">
          <div style={{ fontSize: '18px' }}>
            <b>SCADA - HOT MIX PLANT</b>
          </div>
        </div>
      </div>
      <CRow>
    <ShowChartData
      xl={7}
      chartOptions={chartOptions[chartOptions.length-1]}
      title={'Total Working Plants (Scada)'}
      onClick={() => {
        handleClickOnCardExpand(chartOptions.length-1)
      }}
      chartClickEvent={ScadaWorkingChartClick}
      chartID={chartOptions.length-1}
    />
      </CRow>

      <button onClick={() => setDrawerOpened(true)} style={{ position: 'fixed', top: '80%', right: '5%', zIndex: 1000, 
      alignItems: 'center', backgroundColor: filterColor?'orange':'#5b4ce2', borderWidth: 0, width: 60, height: 60, borderRadius: 30 }}>
        <CIcon icon={icon.cilFilter} size="xl" style={{ alignSelf: 'center', color: 'white', fontWeight: 'bolder' }} />
      </button>

      {loading && <Loader />}

      {openModal && (
        <NewModal open={openModal} type={chartData.type} title={chartData.title} onClose={onModalClose}>
          <div className="container">
            <div className="row">
              <div className={chartData.type === 'pie' ? 'col-8' : 'col-10'}>
                {!checked ? (
                  modalChartLoading ? (
                    <div style={{ height: '522px' }}>
                      {/* <Skeleton height={'60px'}></Skeleton> */}
                      <Loading loading loaderColor="#3498db" />
                    </div>
                  ) : (
                    <ChartWrapper options={chartData} chartClickEvent={chartClickEvent} />
                  )
                ) : (
                  <GridView cols={gridData.cols} rows={gridData.rows} />
                )}
              </div>
              <div className={chartData.type === 'pie' ? 'col-4' : 'col-2'}>
                <div>
                  <h5 className="font-weight-bolder m-0">View Details ♒</h5>
                </div>
                <div className="d-flex align-items-center py-2">
                  <ToggleSwitch checked={checked} onChange={setChecked} />
                  <div className="ml-2">Gird View</div>
                </div>
                {
                checked?<ExportData apiData={gridData}/> : null
               }
               {/*{
                checked?<ExportPdf pdfData={gridData}/> : null
               } */}
              </div>
            </div>
          </div>
        </NewModal>
      )}
      {isDrawerOpened && <FilterDrawer onClose={() => setDrawerOpened(false)} visible={isDrawerOpened} 
      applyFilterFn={applyFilterFn} fetchDataFn={fetchData} filterData={filterData} 
      setFiltersFn={setSelectedModalFilter} setFilterValuesFn={setFilterValues} filterValuesState={filterValues} isFilterApplied={setFilterColor} />}
      {modalVisible && <Modal open={modalVisible} saveWatchList={saveWatchList} setVisibleFn={setModalVisibleFn} type="table" title={modalTitle} value="" data={modalData}></Modal>}
      <CModal size="xl" scrollable visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle>Scada Hot Mix Plant Data</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <HotMixPlant></HotMixPlant>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ALL
