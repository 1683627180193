import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol, CFormLabel, CFormInput, CFormTextarea, CCard, CCardBody, CButton, CForm } from '@coreui/react'
import helper from '../components/shared/helper'
import DatePicker from '../components/shared/DatePicker';
import Customdropdown from '../components/shared/Customdropdown';
import { useSelector } from 'react-redux';
import moment from 'moment';
const Items = ({ label, value, required, handleChange }) => {
  if (label.includes('Name Of Work') || label.includes('NameOfWork')) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormTextarea required={required ? required:false} value={value} name={label} onChange={handleChange} />
      </CCol>
    )
  }
  if (label.includes('Date')) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <DatePicker required={required ? required:false} value={value} label={label} onChange={handleChange} />
      </CCol>
    )
  }
  if (helper.checkDropdowField(label)) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <Customdropdown required={required ? required:false} options={helper.checkDropDownData(label)} name={label} value={value} onChange={handleChange} />
      </CCol>
    )
  }
  if (helper.checkNumeric(label)) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormInput required={required ? required:false} type="number" value={value} name={label} onChange={handleChange} />
      </CCol>
    )
  } else {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormInput required={required ? required:false} disabled={label == 'WorkID' ? true : false} value={value} onChange={handleChange} name={label} />
      </CCol>
    )
  }
}
  
function EditAddRecord({data, wing}) {
  const { selectDate } = useSelector((state) => state.common)
  const [item, setItem] = useState()
  const [active, setActive] = React.useState('info');

  const [validated, setValidated] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSave()
    }
    setValidated(true)
  }

  const handleChange = (val, label) => {
  
    if (label.includes('Date')) {

      let c = { ...item }
      let selectDate = moment(val).format('DD/MM/YYYY')
 
      c[label] = selectDate
      setItem(c)
    } else {
      let c = { ...item }
      c[label] = val.target.value
 
      setItem(c)
    }
  }

  var colSize = 6
  try {
    colSize = data['Status'] ? 6 : 12
  } catch (e) {
    console.log('Error')
  }

  const onSave = () => {
    let body = {
      module: wing,
      data: item,
    }
    alert(JSON.stringify(body))
  }

  useEffect(()=>{
    Object.keys(data).map(obj=>{
      data[obj] = ""
    })
    setItem(data)
    console.log(data)
    console.log(item)
  },[data])
  return (
    <CContainer className="mt-3 bg-white shadow">
          <CRow>
            <CCol xs={2} className="g-0 position-relative">
              <div className="row-container">
                <button onClick={() => {
                    setActive('info')
                  }} className={'butn btn-blue'}>Row Info</button>
               
               <div className="btns">
               <CButton onClick={handleSubmit} className="my-3 px-5 text-white" color="success">
                        Save
                      </CButton>
                  
               </div>
              </div>
            </CCol>
            <CCol xs={10} className="border" style={{ height: '740px', overflow: 'overlay' }}>
              <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                <CRow className="p-3">
                
                  { item &&  Object.keys(item).map((obj, i) => {
                    let label = obj
                    return (
                      <Items
                        key={i}
                        label={label}
                        value={item[obj]}
                        required={obj.required}
                        selectDate={selectDate}
                        handleChange={(e) => {
                          handleChange(e, label)
                        }}
                      />
                    )
                  })}
                  <div className="d-flex">
                   
                  </div>
                </CRow>
              </CForm>
            </CCol>
          </CRow>
        </CContainer>
  );
}

export default EditAddRecord;
