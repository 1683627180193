import React, { useEffect, useState, useMemo } from 'react'
import { CContainer, CRow, CCol, CFormLabel, CFormInput, CFormTextarea, CCard, CCardBody, CButton, CForm } from '@coreui/react'
import './AgGridStyle.css'
import helper from './helper'
import Customdropdown from './Customdropdown'
import DatePicker from './DatePicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'
import moment from 'moment'

const Items = ({ label, value, selectDate, handleChange }) => {
  if (label.includes('Name Of Work') || label.includes('NameOfWork')) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormTextarea value={value} name={label} onChange={handleChange} />
      </CCol>
    )
  }
  if (label.includes('Date')) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <DatePicker  value={value} label={label} onChange={handleChange} />
      </CCol>
    )
  }
  if (helper.checkDropdowField(label)) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <Customdropdown options={helper.checkDropDownData(label)} name={label} value={value} onChange={handleChange} />
      </CCol>
    )
  }
  if (helper.checkNumeric(label)) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormInput type="number" value={value} name={label} onChange={handleChange} />
      </CCol>
    )
  } else {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormInput  disabled={label == 'WorkID' ? true : false} value={value} onChange={handleChange} name={label} />
      </CCol>
    )
  }
}

const MapView = ({ id }) => {
  return <iframe src={`https://ris.ncog.gov.in/roadstatus/?id=${id}`} width={'100%'} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" height={650}></iframe>
}

const StatusItem = ({ label, value }) => {
  return (
    <CCol xs={6} className="d-flex align-items-center mb-3">
      <CFormLabel className="mx-2">{label}</CFormLabel>
      <CFormInput value={value} readOnly className={helper.statuscheck(value)} />
    </CCol>
  )
}

const EditDynamicForm = ({ data, wing }) => {

  
  const [item, setItem] = useState(data)
  const [active, setActive] = React.useState('info');

  const [validated, setValidated] = useState(false)
  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSave()
    }
    setValidated(true)
  }


  const handleChange = (val, label) => {
    if (label.includes('Date')) {
      let c = { ...item }
      let selectDate = moment(val).format('DD/MM/YYYY')
      c[label] = selectDate
      setItem(c)
    } else {
      let c = { ...item }
      c[label] = val.target.value
      setItem(c)
    }
  }

  var colSize = 6
  try {
    colSize = data['Status'] ? 6 : 12
  } catch (e) {
    console.log('Error')
  }

  const onSave = () => {
    let body = {
      module: wing,
      data: {...data,...item},
    }
    alert(JSON.stringify(body))
  }

  try {
    return (
      <>
        <CContainer className="mt-3 bg-white shadow">
          <CRow>
            {data['Name Of Work'] && (
              <CCol>
                <CCard className="mb-3">
                  <CCardBody>
                    <b>{data['Name Of Work']}</b>
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </CRow>
          <CRow>
            <CCol xs={2} className="g-0 position-relative">
              <div className="row-container">
                <button onClick={() => {
                    setActive('info')
                  }} className={active === 'info'? 'butn btn-blue' : 'butn btn-white'}>Row Info</button>
                <button onClick={() => {
                    setActive('finance')
                  }} className={active === 'finance'? 'butn btn-blue' : 'butn btn-white'}>Finance</button>
              </div>
            </CCol>
            <CCol xs={10} className="border" style={{ height: '600px', overflow: 'overlay' }}>
              <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                <CRow className="p-3">
                  {Object.keys(data).map((obj, i) => {
                    let label = obj
                    return (
                      <Items
                        key={i}
                        label={label}
                        value={item[obj]}
                       
                        selectDate={selectDate}
                        handleChange={(e) => {
                          handleChange(e, label)
                        }}
                      />
                    )
                  })}
                  <div className="d-flex">
                    {
                      active === 'finance' ?<CButton type="submit" className="my-3 px-5 text-white" color="success">
                      Save
                    </CButton>:null
                    }
                 
                  </div>
                </CRow>
              </CForm>
            </CCol>
          </CRow>
        </CContainer>
      </>
    )
  } catch (e) {
    return <></>
  }
}
export default React.memo(EditDynamicForm)
