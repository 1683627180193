
// export const tableInfo = [
//     {
//         field : "WorkID",
//         type : "number",
//     },
//     {
//         field : "WMSCode",
//         type : "number",
//     },
//     {
//         field : "Name Of Package",
//         type : "text",
       
//     },
//     {
//         field : "Name Of Work",
//         type : "text",
       
//     },
//     {
//         field : "Work Category",
//         type : "text",
       
//     },
//     {
//         field : "Wing",
//         type : "text",
       
//     },
//     {
//         field : "Circle",
//         type : "text",
        
//     },
//     {
//         field : "District",
//         type : "text",
       
//     },
//     {
//         field : "Division",
//         type : "text",
       
//     },
//     {
//         field : "Taluka",
//         type : "text",
       
//     },
//     {
//         field : "Sub Division",
//         type : "text",
       
//     },
//     {
//         field : "Road Category",
//         type : "text",
       
//     },
//     {
//         field : "Scheme",
//         type : "text",
       
//     },
//     {
//         field : "Name Of MLA",
//         type : "text",
       
//     },
//     {
//         field : "Type Of Work",
//         type : "text",
       
//     },
//     {
//         field : "Length for Roads(in kms)",
//         type : "number",
       
//     },
//     {
//         field : "Budget Head",
//         type : "text",
       
//     },
//     {
//         field : "Type Of Budget Item",
//         type : "text",
       
//     },
//     {
//         field : "Proposed Work",
//         type : "text",
       
//     },
//     {
//         field : "Financial Year",
//         type : "number",
       
//     },
//     {
//         field : "Details Of Proposed Work",
//         type : "text",
       
//     },
//     {
//         field : "Existing Surface",
//         type : "text",
       
//     },
//     {
//         field : "Year of Approval",
//         type : "number",
       
//     },
//     {
//         field : "Name Of VIllage",
//         type : "number",
       
//     },
//     {
//         field : "Population Of Village",
//         type : "number",
       
//     },
//     {
//         field : "Total Population",
//         type : "number",
       
//     },
//     {
//         field : "Chainage From",
//         type : "text",
       
//     },
//     {
//         field : "Chainage To",
//         type : "text",
       
//     },
//     {
//         field : "AA Approval Letter No",
//         type : "number",
       
//     },
//     {
//         field : "AA Approaval Date",
//         type : "number",
       
//     },
//     {
//         field : "AA Amount In Lakhs",
//         type : "number",
       
//     },
//     {
//         field : "TS Date",
//         type : "number",
       
//     },
//     {
//         field : "TS Amount In Lakhs",
//         type : "number",
       
//     },
//     {
//         field : "DTP Date",
//         type : "number",
       
//     },
//     {
//         field : "DTP Amount In Lakhs",
//         type : "number",
       
//     },
//     {
//         field : "Tender Invitation Date",
//         type : "number",
       
//     },
//     {
//         field : "Tender Openening Date",
//         type : "number",
       
//     },
//     {
//         field : "Tender Approval Date",
//         type : "number",
       
//     },
//     {
//         field : "Tender Reference no#",
//         type : "number",
       
//     },
//     {
//         field : "Agency Contractor",
//         type : "text",
       
//     },
//     {
//         field : "Contract Cost In Lakhs",
//         type : "number",
       
//     },
//     {
//         field : "Above/Below Percentage",
//         type : "number",
       
//     },
//     {
//         field : "Time Limit in Months",
//         type : "number",
       
//     },
//     {
//         field : "Agreement No",
//         type : "number",
       
//     },
//     {
//         field : "Scheduled Date of Start of Contract",
//         type : "number",
       
//     },
//     {
//         field : "Work Order Date",
//         type : "number",
       
//     },
//     {
//         field : "Scheduled Date of Work Completion",
//         type : "number",
       
//     },
//     {
//         field : "Scheduled Date for Completion of Maintenance",
//         type : "number",
       
//     },
//     {
//         field : "Extention of Time Limit(EOT) 1 - Date",
//         type : "number",
       
//     },
//     {
//         field : "Extention of Time Limit(EOT) 1 - Amount",
//         type : "number",
       
//     },
//     {
//         field : "Extention of Time Limit(EOT) 2 - Date",
//         type : "number",
       
//     },
//     {
//         field : "Extention of Time Limit(EOT) 2 - Amount",
//         type : "number",
       
//     },
//     {
//         field : "Extention of Time Limit(EOT) 3 - Date",
//         type : "number",
       
//     },
//     {
//         field : "Extention of Time Limit(EOT) 3 - Amount",
//         type : "number",
       
//     },
//     {
//         field : "Garuntee Period (in Months)",
//         type : "number",
       
//     },
//     {
//         field : "Completion Date of Garuntee Period",
//         type : "number",
       
//     },
//     {
//         field : "Actual Date of Completion",
//         type : "number",
       
//     }, 
//     {
//         field : "Expenditure Incurred in the previous year(Lakhs)",
//         type : "number",
       
//     },
//     {
//         field : "Expenditure incurred till last month(Lakhs)",
//         type : "number",
       
//     },
//     {
//         field : "Expenditure incurred in the current month(Lakhs)",
//         type : "number",
       
//     },
//     {
//         field : "Total Expenditure incurred upto this month(Lakhs)",
//         type : "number",
       
//     },
//     {
//         field : "Total Expenditure up to till Date(Lakhs)",
//         type : "number",
       
//     },
//     {
//         field : "Approved Excess Amount",
//         type : "number",
       
//     },
//     {
//         field : "SpillOver",
//         type : "text",
       
//     },
//     {
//         field : "Physical Progress %",
//         type : "number",
       
//     },
//     {
//         field : "FInancial Progress %",
//         type : "number",
       
//     },
//     {
//         field : "Physical Progress Update Date",
//         type : "number",
       
//     },
//     {
//         field : "Status",
//         type : "text",
       
//     },
//     {
//         field : "Details of Status",
//         type : "text",
       
//     },
//     {
//         field : "Completed length",
//         type : "number",
       
//     },
//     {
//         field : "Remarks",
//         type : "text",
       
//     },
//     {
//         field : "Createddate",
//         type : "number",
       
//     },
//     {
//         field : "Editeddate",
//         type : "number",
       
//     },
// ]
export const tableInfo = [];

export const BuildingInfo = [
    {
        field : "WorkID",
        type : "number",
       
    },
    {
        field : "WMSCode",
        type : "number",
       
    },
    {
        field : "Name Of Package",
        type : "text",
       
    },
    {
        field : "Name Of Work",
        type : "text",
       
    },
    {
        field : "Work Category",
        type : "text",
       
    },
    {
        field : "Wing",
        type : "text",
       
    },
    {
        field : "Circle",
        type : "text",
        
    },
    {
        field : "District",
        type : "text",
       
    },
    {
        field : "Division",
        type : "text",
       
    },
    {
        field : "Taluka",
        type : "text",
       
    },
    {
        field : "Sub Division",
        type : "text",
       
    },
    {
        field : "Road Category",
        type : "text",
       
    },
    {
        field : "Scheme",
        type : "text",
       
    },
    {
        field : "Name Of MLA",
        type : "text",
       
    },
    {
        field : "Type Of Work",
        type : "text",
       
    },
    {
        field : "Length for Roads(in kms)",
        type : "number",
       
    },
    {
        field : "Budget Head",
        type : "text",
       
    },
    {
        field : "Type Of Budget Item",
        type : "text",
       
    },
    {
        field : "Proposed Work",
        type : "text",
       
    },
    {
        field : "Financial Year",
        type : "number",
       
    },
    {
        field : "Details Of Proposed Work",
        type : "text",
       
    },
    {
        field : "Existing Surface",
        type : "text",
       
    },
    {
        field : "Year of Approval",
        type : "number",
       
    },
    {
        field : "Name Of VIllage",
        type : "number",
       
    },
    {
        field : "Population Of Village",
        type : "number",
       
    },
    {
        field : "Total Population",
        type : "number",
       
    },
    {
        field : "Chainage From",
        type : "text",
       
    },
    {
        field : "Chainage To",
        type : "text",
       
    },
    {
        field : "AA Approval Letter No",
        type : "number",
       
    },
    {
        field : "AA Approaval Date",
        type : "number",
       
    },
    {
        field : "AA Amount In Lakhs",
        type : "number",
       
    },
    {
        field : "TS Date",
        type : "number",
       
    },
    {
        field : "TS Amount In Lakhs",
        type : "number",
       
    },
    {
        field : "DTP Date",
        type : "number",
       
    },
    {
        field : "DTP Amount In Lakhs",
        type : "number",
       
    },
    {
        field : "Tender Invitation Date",
        type : "number",
       
    },
    {
        field : "Tender Openening Date",
        type : "number",
       
    },
    {
        field : "Tender Approval Date",
        type : "number",
       
    },
    {
        field : "Tender Reference no#",
        type : "number",
       
    },
    {
        field : "Agency Contractor",
        type : "text",
       
    },
    {
        field : "Contract Cost In Lakhs",
        type : "number",
       
    },
    {
        field : "Above/Below Percentage",
        type : "number",
       
    },
    {
        field : "Time Limit in Months",
        type : "number",
       
    },
    {
        field : "Agreement No",
        type : "number",
       
    },
    {
        field : "Scheduled Date of Start of Contract",
        type : "number",
       
    },
    {
        field : "Work Order Date",
        type : "number",
       
    },
    {
        field : "Scheduled Date of Work Completion",
        type : "number",
       
    },
    {
        field : "Scheduled Date for Completion of Maintenance",
        type : "number",
       
    },
    {
        field : "Extention of Time Limit(EOT) 1 - Date",
        type : "number",
       
    },
    {
        field : "Extention of Time Limit(EOT) 1 - Amount",
        type : "number",
       
    },
    {
        field : "Extention of Time Limit(EOT) 2 - Date",
        type : "number",
       
    },
    {
        field : "Extention of Time Limit(EOT) 2 - Amount",
        type : "number",
       
    },
    {
        field : "Extention of Time Limit(EOT) 3 - Date",
        type : "number",
       
    },
    {
        field : "Extention of Time Limit(EOT) 3 - Amount",
        type : "number",
       
    },
    {
        field : "Garuntee Period (in Months)",
        type : "number",
       
    },
    {
        field : "Completion Date of Garuntee Period",
        type : "number",
       
    },
    {
        field : "Actual Date of Completion",
        type : "number",
       
    }, 
    {
        field : "Expenditure Incurred in the previous year(Lakhs)",
        type : "number",
       
    },
    {
        field : "Expenditure incurred till last month(Lakhs)",
        type : "number",
       
    },
    {
        field : "Expenditure incurred in the current month(Lakhs)",
        type : "number",
       
    },
    {
        field : "Total Expenditure incurred upto this month(Lakhs)",
        type : "number",
       
    },
    {
        field : "Total Expenditure up to till Date(Lakhs)",
        type : "number",
       
    },
    {
        field : "Approved Excess Amount",
        type : "number",
       
    },
    {
        field : "SpillOver",
        type : "text",
       
    },
    {
        field : "Physical Progress %",
        type : "number",
       
    },
    {
        field : "FInancial Progress %",
        type : "number",
       
    },
    {
        field : "Physical Progress Update Date",
        type : "number",
       
    },
    {
        field : "Status",
        type : "text",
       
    },
    {
        field : "Details of Status",
        type : "text",
       
    },
    {
        field : "Completed length",
        type : "number",
       
    },
    {
        field : "Remarks",
        type : "text",
       
    },
    {
        field : "Createddate",
        type : "number",
       
    },
    {
        field : "Editeddate",
        type : "number",
       
    },
]



