import getCharts from '../getCharts'
import { HOMECHARTS_Temp } from '../../constants/api.config'

export default async function getAllHomeChartsTemp(loginID, mID, tranID,filters="") {
  try {
    const payload = {loginID, moduleID: mID, tranTypeID:tranID,filters }
    const res = await getCharts(HOMECHARTS_Temp, payload)
    return res.data
  } catch (error) {
    return error
  }
}
