import getCharts from '../getCharts'
import { SCADA } from '../../constants/api.config'

export default async function getScadaData(login, date) {
  try {
    const payload = {login: '1', dateToBeFetched: date }
    console.log("payload",payload)
    const res = await getCharts(SCADA, payload)
  
    return res.data
  } catch (error) {
    return error
  }
}
