import React, { useEffect } from 'react'

import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from 'xlsx';

export const ExportData = ({apiData}) => {
let datas = []
const calcData = () => {

  try {
    let finalTable = []
    let cols = apiData["cols"]
    let rows = apiData["rows"]

    let colsColumn = cols.map(obj=>obj.title)
    finalTable.push(colsColumn)

    let c = rows.map((obj,i) => {
      let temp = []
      temp = cols.map((o)=>{
        return i=obj[o.dataIndex]
      })
      return temp
    })

  
  datas= finalTable.concat(c)
  } catch (e) {

  }

  
} 
const downloadExcel = () => {
  calcData()
  const worksheet = XLSX.utils.json_to_sheet(datas);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "DataSheet.xlsx");
};
  return (
    <button onClick={downloadExcel} className='btn btn-success text-white'>
    Excel
</button>
  )
}
