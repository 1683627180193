import React from 'react';
import { CContainer, CRow, CCol, CFormLabel, CFormInput, CFormTextarea, CCard, CCardBody } from '@coreui/react'
import helper from '../components/shared/helper'

const Items = ({ label, value }) => {
    return label === 'Name Of Work' ? (
      <CCol xs={6} className="mt-2 px-3">
        <CFormLabel style={{color: 'black', fontWeight: 600}}>{label}</CFormLabel>
        <CFormTextarea value={value} disabled={true} style={{color: 'black', backgroundColor:'whitesmoke'}}  />
      </CCol>
    ) : (
      <CCol xs={6} className="mt-2 px-3">
        <CFormLabel style={{color: 'black', fontWeight: 600}}>{label}</CFormLabel>
        <CFormInput value={value} disabled={true} style={{color: 'black', backgroundColor:'whitesmoke'}}/>
      </CCol>
    )
  }
  
  const MapView = ({ id }) => {
    return <iframe src={`https://ris.ncog.gov.in/roadstatus/?id=${id}`} width={'100%'} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" height={650}></iframe>
  }
  
  const StatusItem = ({ label, value }) => {
    return <CCol xs={3} className="align-items-center mb-3">
    <CFormLabel style={{color: 'black', fontWeight: 600}}>{label}</CFormLabel>
    <CFormInput value={value} readOnly className={helper.statuscheck(value)}/>
  </CCol>
  }
function InfoDetails({data, active, setActive}) {
  return (
    <CContainer className="bg-white shadow " >
    <CRow>
      <CCol md={2} className='g-0' >
      <div className='inner-side-bar'>
          <button
            type="button"
            className={active === 'info' ? 'butn btn-blue' : 'butn btn-white'}
            onClick={() => {
              setActive('info')
            }}
          >
           {data['Name Of Work'] ? "Basic Info": "Row Info"}
          </button>
          {/* <button
            type="button"
            className={active === 'finance' ? 'butn btn-blue' : 'butn btn-white'}
            onClick={() => {
              setActive('finance')
            }}
          >
           Finance
          </button> */}
          {data['Status'] &&<button
            type="button"
            className={active === 'map' ? 'butn btn-blue' : 'butn btn-white'}
            onClick={() => {
              setActive('map')
            }}
          >
            Map
          </button>}
        </div>
      </CCol>
      <CCol md={10} className='g-0 border' style={{height:'680px',overflow:'overlay'}}>
      <div className="flex-grow-1" >
    {data['Name Of Work']  && <div className="card p-2" >
         <b className="mb-2" style={{fontSize: '16px', textTransform: 'uppercase', textAlign:'center'}}>{data['Name Of Work']}</b>
      </div>}
      
      
      <div className="p-3 flex-grow-1 m-3">
        {active !== 'map' && (
          <CRow>
            {data['Status'] &&<StatusItem label="Status" value={data['Status']} />}
            <CRow>
            {Object.keys(data).filter((item)=>{
               if(active === 'finance'){
                if(helper.checkInputFinanceLable(item)){
                  return item === null ? "": item
                }
              }else{
                if(helper.checkInputFinanceLable(item) !== true){
                  return item === null ? "": item
                }                   
              }

            }).map((obj) => {
              return <Items label={obj} value={data[obj]} />
            })}
            </CRow>
          </CRow>
        )}
        {active === 'map' && data['Status'] && (
          <CCol>
            {data['Status'] &&<StatusItem label="Status" value={data['Status']} />}
            <MapView id={data.WorkID} />
          </CCol>
        )}
      </div>
    </div>
      </CCol>
   
    </CRow>
    
  </CContainer>
  );
}

export default InfoDetails;
