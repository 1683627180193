import getCharts from '../getCharts'
import { TRANSFERDATA } from '../../constants/api.config'


export default async function TransferData(wing="state", jsonData, login="1") {
  try {
    var res = ""
  
      const payload = {wing, jsonData }

      res = await getCharts(TRANSFERDATA, payload)

   
    return res.data
  } catch (error) {
    return error
  }
}
