import getCharts from '../getCharts'
import { RefetchDataExternal } from '../../constants/api.config'

export default async function RefetchDataExternalFn(mode,loginID="1") {
  try {
    const payload = {mode,loginID }
    const res = await getCharts(RefetchDataExternal, payload)
    return res.data
  } catch (error) {
    return error
  }
}
