import React from 'react';

const MapComponent = () => {
  const mapUrl = "https://gatishakti.ncog.gov.in/FindRoad/redirectapi1?access_token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJua24iLCJpYXQiOjE3MjEzNzYzNjcsImV4cCI6MTcyMTM3ODE2N30.AGEDs9Qp1F52kPDy2zUt5ElD20_9f6BnPFr8M9hubVM&workid=";

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src={mapUrl}
        style={{ border: 0, width: '100%', height: '100%' }}
        allowFullScreen
        title="Map"
      />
    </div>
  );
};

export default MapComponent;
