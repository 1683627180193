import { DataGrid,
   GridToolbar } from '@mui/x-data-grid';

   import {AgGridReact} from 'ag-grid-react';
   import 'ag-grid-enterprise';

   import { LicenseManager } from  'ag-grid-enterprise'

LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-21_December_2022_[v2]_MTY3MTU4MDgwMDAwMA==72993978b037cf7071dd20d6c116bd5d")
import GetHotMixPlantData  from '../../services/general/getHotMixPlantData.api.js'

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import { useLocation } from 'react-router-dom'

import getChartTableDetails from '../../services/general/getChartTableDetails.api.js'
import { useQuery } from 'react-query'
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';

import { Input, Button } from 'antd'
import { useHistory } from 'react-router-dom'


window.childTableDetails = {}
export default function HotMixPlant() {
  const gridRef = useRef();
  const gridRefAllData = useRef();
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      hiddenByDefault: false,
    };
  }, []);
  const [loginID, setLoginID] = useState("")
  //gridRef.current.columnApi.setPivotMode(true);
  const history = useHistory()
  const { isLoading, data: chartDetails } = useQuery('GetHotMixPlantDataTotal', () => GetHotMixPlantData("TOTAL"),
   { refetchOnWindowFocus: false, cacheTime:0 , refetchOnmount: true})

   const { isLoading: isLoadingAllData, data: allHotMixPlantData } = useQuery('GetHotMixPlantDataAllData', () => GetHotMixPlantData("ALLDATA"),
   { refetchOnWindowFocus: false, cacheTime:0 , refetchOnmount: true})
  const [data, setData] = useState([])

  const [allData, setAllData] = useState([])
  // const location = useLocation().pathname.split('/')
  const [searchString, setSearchString] = useState('')
  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 300,
    };
  }, []);

  const [selectedFilters, setSelectedFilter] = useState([])
  const [selectedModalFilters, setSelectedModalFilter] = useState([])
  const onRemoveFilter = (fil) => setSelectedFilter(selectedFilters.filter((d) => d.name !== fil.name))
  const onRemoveModalFilter = (fil) => setSelectedModalFilter(selectedModalFilters.filter((d) => d.name !== fil.name))
  const [columns, setColumns] = useState([])
  const [allColumns, setAllColumns] = useState([])
  const [tableID, setTableID] = useState(0)
  var tableIDNew = 0
  const [loading, setLoading] = useState(false)

  // function currentlySelected(params, record) {
  //   try {
  //       let cols = Object.keys(record.data)
  //       let rowData = Object.values(record.data)
        
  //       let moduleID = 0
        
  //       window.childTableDetails = {loginID:"1", table : tableIDNew,
  //                                   module: moduleID, columns:JSON.stringify(cols), rows: JSON.stringify(rowData) }
        
  //      // history.push('/childTable')

  //      // let temp = getChildTable("1", tableIDNew, moduleID, JSON.stringify(cols), JSON.stringify(rowData))

  //   } catch (e) {
  //       console.log(e)
  //   }

  // }

  useEffect(() => {
    try {
      let c =[]
      let allColumns = []
      if (!isLoading && !isLoadingAllData) {
        let tempData = JSON.parse(chartDetails.d)

        let allDataTemp = JSON.parse(allHotMixPlantData.d)

        let allData = allDataTemp["Data"]
        let temp = tempData["Data"]
       // tableIDNew = tempData["IDNumber"]
        //setTableID(tempData["IDNumber"])

        // console.log("allData",allData)
        // console.log("temp",temp)
        for (let j = 0; j <Object.keys(temp[0]).length ; j++) {
          
            c.push({
              field: Object.keys(temp[0])[j],
              headerName:Object.keys(temp[0])[j],
            })
          
          
        }

        for (let j = 0; j <Object.keys(allData[0]).length ; j++) {
          
            allColumns.push({
              field: Object.keys(allData[0])[j],
              headerName:Object.keys(allData[0])[j],
            })
        
          
        }
        // c.push({
        //   field: "drilldown",
        //   headerName: "Detail",
        //   cellRenderer: (record) => {
        //     return (
        //       <Button variant="contained" color="primary" onClick={(e) => {currentlySelected(e, record)}} >
        //         Detail
        //       </Button>
        //     );
        //  }
        // })
        setAllColumns(allColumns)
        setAllData(allData)
         setColumns(c)
        setData(temp)
        setLoading(false)
      } else  {
      setLoading(true)
    }
    } catch (error) {
      //setData([])
      setLoading(false)
    }
  }, [isLoading, isLoadingAllData])
  
  if (loading) return 'Loading...'


  return (
    <>
    <div className="ag-theme-material"
    style={{
      height: '20rem'
    }}>
    
      <AgGridReact rowData={data}
        columnDefs={columns} 
        defaultColDef={defaultColDef}
          pagination={true}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={'multiple'}
          rowGroupPanelShow={'always'}
          suppressAutoSize={false}
          pivotPanelShow={'always'}
          enablePivot={true}
          sideBar={sideBar}
          ref={gridRef}
          animateRows={true} 
          enableCharts={true}
          enableRangeSelection={true}
          maintainColumnOrder={true}
        />
    </div>
    <div className="ag-theme-material"
    style={{
      height: '20rem'
    }}>
    
      <AgGridReact rowData={allData}
        columnDefs={allColumns} 
        defaultColDef={defaultColDef}
          pagination={true}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={'multiple'}
          rowGroupPanelShow={'always'}
          suppressAutoSize={false}
          pivotPanelShow={'always'}
          enablePivot={true}
          sideBar={sideBar}
          ref={gridRefAllData}
          animateRows={true} 
          enableCharts={true}
          enableRangeSelection={true}
          maintainColumnOrder={true}
        />
    </div>
    
    </>
    
    
    
  );
}