import getCharts from '../getCharts'
import { CHARTSWITHFILTER } from '../../constants/api.config'

export default async function getChartsWithFilter(cID, filters ) {
  try {
    const payload = {chartID: cID, loginID: '1', filterData:JSON.stringify(filters) }
    console.log(payload)
    const res = await getCharts(CHARTSWITHFILTER, payload)
    return res.data
  } catch (error) {
    return error
  }
}
