

import { commonAction } from "./commonSlice"

export const onZoomChange = (status) =>{
    return (dispatch) =>{
        dispatch(commonAction.onZoom({data: status}))
    }
}


export const onSetTableChange = (status) =>{
    return (dispatch) =>{
        dispatch(commonAction.onSetTable({data: status}))
    }
}
