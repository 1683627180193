import React from 'react'
import { CFooter } from '@coreui/react'
import { cilScreenSmartphone, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const AppFooter = () => {
  return (
    <CFooter position='sticky'>
     <div>
    
    
  </div>
  <div>
  <CIcon icon={cilScreenSmartphone} size="lg" /> <span>For Queries Call  +91 9714105866 </span> OR  <span> Submit <a href='https://forms.gle/9nDJ2LAqQPUHQWKH6' target="_blank">Form </a>  </span>
  </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
