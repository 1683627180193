import React from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels'
//import ChartLabels from  'chart.js-plugin-labels-dv';

import { useState, useEffect, useRef } from 'react'

import Loader from './Loader'

import { Chart as ChartJS, CategoryScale, LinearScale, LogarithmicScale, BarElement, ArcElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar, Line, Pie, getElementAtEvent, getElementsAtEvent, getDatasetAtEvent } from 'react-chartjs-2'
const StatusColors = [
  '#e55353',
  '#f9b115',
  '#2eb85c',
  '#20c997',
  '#fd7e14',
  '#4f5d73',
  '#9da5b1'

]

const AllColors = [
  '#e55353',
  '#f9b115',
  '#2eb85c',
  '#36A2EB',
  '#66994D',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
]

const barChartColor = [
  '#4BC0C0',
  '#FF9F40', // lighter magenta
  '#C9B4FF', // lighter purple

  '#D66600',

  '#FF1A66',



  '#228B22',
  '#FFB6A9', // lighter salmon
  '#6BD3B1',

  '#B768E0', // lighter violet
  '#FF6EFF', // lighter magenta
   // lighter green
  '#C1B2FF',
  '#A2D8FF',
  '#F2C2E6',
  '#86D18B',
 
  '#93C9A7',
  '#B6C9B6',
  '#F2FFB6',
  '#93C900',
  '#DAB6DA',
  '#A7A793']

var colors = []

function HomeChartWrapper({ options, chartClickEvent, height = '350px', chartColors = 'standard', chartID = '-1' }) {
  colors = AllColors

  // Pass this as a param 
  if (options.type=='bar' && options?.datasets?.length > 4 ) {
    chartColors = "status"
  }


  if (chartColors == "status") {
    colors = StatusColors
  } else {
    if ( options.type=='bar' && options?.datasets?.length < 2 ) {
      colors = ['#DAB6DA']
      if (options?.title.toLowerCase().includes("progress")) {
        colors=['#f9b115']
      }else if (options?.title.toLowerCase().includes("not started")) {
        colors=['#e55353']
      }else if (options?.title.toLowerCase().includes("total works")) {
        colors=['#39f']
      }
    }  
    else {
      
    if (options.type=='pie') {
      colors = barChartColor
    } else {
      colors = AllColors
    }
   
    }

  }


  
  
  // else {

  // }
  // if (chartColors == 'standard' ) {
  //   colors = AllColors
  // } else {
  //   colors = AllColors.slice(5, 15)
  // }

  const chartRef = useRef()

  const [isloading , setIsLoading] = useState(false)
  

  ChartJS.register(CategoryScale, LinearScale, BarElement,LogarithmicScale, PointElement, ArcElement, LineElement, Title, Tooltip, Legend, ChartDataLabels)
  var chartlabels = {}
  try{
     chartlabels = options?.labels?.map((e) => e.replace('&amp;', '&'))
  } catch(e) {

  }
  const getFonts = (size = 16, weight = 800) => ({ size, weight, family: 'Inter' })
  
  const getTitle = (title, fontSize, align = 'start') => ({
    display: true,
    text: title,
    align,
    color: '#000',
    font: getFonts(fontSize),
  })

  const handleClick = (e) => {
    try{
      let dataset = getDatasetAtEvent(chartRef.current, e)
      const datasetIndex = dataset[0].datasetIndex
      let data = chartRef.current.data
      let label = data?.datasets[datasetIndex].label
      let elementAtEvent = getElementAtEvent(chartRef.current, e)
      const { index } = elementAtEvent[0]
      let xAxis = data.labels[index]
      chartClickEvent(xAxis, label, chartID)
    } catch (e) {

    }
   
  }

  options.type = options.type ? options?.type?.toLowerCase():"bar"
  function getAxis(options) {
    let scales = {}
    scales['x'] = {
      
      display: options.type !== 'pie',
      grid: { display: false },
      
      title: getTitle(options?.xaxis?.title, 14, 'center'),
    }
    scales['A'] = {
      position: 'left',
      type: 'logarithmic',

      display: false,
      grid: { display: false },
      title: getTitle(options?.yaxis?.[0]?.title, 14, 'center'),
      id: 'A',
      afterDataLimits(scale) {
        if (options.type !== 'pie') {
          let x = Math.floor(Math.log10(scale.max))
          if (x > 3) {
            scale.max += Math.pow(10, x)
          } else {
            scale.max += Math.pow(5, x)
          }
        }
      },
      min:0,
      max:500000,
      ticks: {
        font: getFonts(12),
      },
    }
    if (options.type !== 'pie') {

      if (options?.yaxis?.length == 2) {
        scales['B'] = {
          position: 'right',

          display: options.type !== 'pie',
          grid: { display: true },
          title: getTitle(options?.yaxis?.[1]?.title, 14, 'center'),
          id: 'B',
          ticks: { font: getFonts(12) },
        }
      }
    }

    return scales
  }
  const anchorSet = (context,type) => {
    let bool = type == 'pie' ? context.dataIndex % 2 ? 'center':'end' : 'end'

    return bool

  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: options.type !== 'pie' ? false : true,
    plugins: {
      datalabels: {
        font: {
          weight: 'bold',
          size: 14,
        },
        anchor: function(context) {
         return anchorSet(context,options.type) 
        },
        align: options.type == 'pie' ? 'start' : 'top',
        color: 'black',
        display: options.type == 'pie' ? true : true,
        formatter: (value, ctx) => {
          if (options.type == 'pie') {
            let sum = 0
            let dataArr = ctx.chart.data.datasets[0].data
            dataArr?.map((data) => {
              sum += parseFloat(data)
        })
            //let percentage = (value*100 / sum).toFixed(2)+"%";
            let percentage = value
            return percentage
          }
        },
        padding: 5,
        rotation: (options.type == 'bar') & (options.datasets?.length > 2) ? -45 : 0,
      },
      legend: {
        display: !!options.showLegend,
        position: options.type == 'pie' ? 'bottom' : 'bottom',
        align: 'center',
        labels: {
          font: getFonts(12),
          boxWidth: 12,
        },
      },
    },
    scales: getAxis(options),
  }


  const getColor = () => {
    let number = options?.idnumber != "" ? parseInt(options?.idnumber):0
    if ( number >=  colors.length  ) {
      number = Math.floor(Math.random() * colors.length);
    }
    return   colors[number]
  }
  // const [data, setData] = useState({})

  const data = {
    labels: chartlabels,
    datasets: options?.datasets?.map((d, i) => ({
      barThickness:options.datasets.length > 1? options.datasets.length > 4?30:50 : 100,
      maxBarThickness:options.datasets.length > 1? options.datasets.length > 4?30:50 : 100,
      data: d.data,
      borderRadius: 10,
      borderWidth: 2,
      backgroundColor: options.type !== 'pie' ? options?.datasets?.length < 2? getColor():colors[i] : colors.slice(0, d.data.length),
      label: d.label,
      yAxisID: options.type !== 'pie' ? (i == 1 ? (options.yaxis.length == 2 ? 'B' : 'A') : 'A') : 'A',
    })),
  }

  data.datasets.forEach((dataset) => {
    dataset.data = dataset?.data?.map((value) => {
      if (value == 0) {
        return ""
      }
      return value;
    });
  });
  return (
    isloading ? <Loader/>
    :
    <div style={{ height: '100%' }}>
      {options.type === 'bar' && <Bar height={height} ref={chartRef} onClick={handleClick} plugins={[ChartDataLabels]} options={chartOptions} data={data} />}
      {options.type === 'line' && <Line height={height} ref={chartRef} plugins={[ChartDataLabels]} onClick={handleClick} options={chartOptions} data={data} />}
      {options.type === 'pie' && <Pie ref={chartRef} plugins={[ChartDataLabels]} onClick={handleClick} options={chartOptions} data={data} />}
    </div>
  )
}

export default HomeChartWrapper
