import React,{useState,useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import PinRed from './pin-red.svg';
import PinGreen from './pin-green.svg';
import PinBlue from './pin-blue.svg';
import PinYellow from './pin-yellow.svg';
import { CCard, CCardBody,CCol,CRow } from '@coreui/react';
import './FacilityMap.css';


const colorMap = {
  "green":PinGreen,
  "red":PinRed,
  "yellow":PinYellow,
  "blue":PinBlue
}

const wingMap = {
  "CP":"red",
  "NH":"yellow",
  "State":"green",
  "Panchayat":"blue"
}

const wings = ["CP","NH","State","Panchayat"]


const PinSVG = ({color}) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path fill="#00ff00" d="M16 0C9.385 0 4 5.385 4 12c0 7.935 11.132 19.768 11.345 20.009a1.102 1.102 0 0 0 1.31 0C16.868 31.768 28 19.935 28 12c0-6.615-5.385-12-12-12zm0 17.455c-2.662 0-4.824-2.163-4.824-4.824 0-2.662 2.162-4.824 4.824-4.824s4.824 2.162 4.824 4.824c0 2.661-2.162 4.824-4.824 4.824z"/>
</svg>)

}
// const PinSVG = (c) => {
//   let temp = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'> <path fill='"+c+"' d='M16 0C9.385 0 4 5.385 4 12c0 7.935 11.132 19.768 11.345 20.009a1.102 1.102 0 0 0 1.31 0C16.868 31.768 28 19.935 28 12c0-6.615-5.385-12-12-12zm0 17.455c-2.662 0-4.824-2.163-4.824-4.824 0-2.662 2.162-4.824 4.824-4.824s4.824 2.162 4.824 4.824c0 2.661-2.162 4.824-4.824 4.824z'/></svg>"


//   return temp

// }
const FacilityMap = ({ facilities }) => { 
  const MapLegend = ({facilities}) => {
    return (
      <div className="map-legend">
        <h5>Map Legend</h5>
        <ul>
        {wings.map((wing,i)=>{
          let col = colorMap[wingMap[wing]]
          let temp = PinSVG(col) 
          return (
            <li> <img width={32} height={32} src={colorMap[wingMap[wing]]} ></img>   
            {wing}</li>
          )
        })}
          
        </ul>
      </div>
    );
  }

  useEffect(()=> {
  },[])
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  }


  const defaultProps = {
    center: { lat: 22.309425, lng:72.136230 },
    zoom: 7,
  };

  function Marker(props) {

    const icon = colorMap[wingMap[props.wing]]
    const [info, setInfo] = useState(false);

    const toggleInfo = () => {
      setInfo(!info);
    };

    return (
      <>
         
       <div style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }} onClick={toggleInfo}
        onMouseEnter={() => setInfo(true)}
        onMouseLeave={() => setInfo(false)}>
        <img src={icon} alt="Marker" width={32} height={32} />
      </div>
      {info && <div style={{backgroundColor:'white',width:'20rem',fontSize:'15px', color:'black'}}>
        <CCard style={{borderRadius:'10px',zIndex:1000}}>
          <CCardBody >
          Name: <b>{props.text}</b>
          <br />
          Wing: <b>{props.wing}</b>
          <br />
          District: <b>{props.district}</b>
          <br />
          Netmix: <b>{props.netmix}</b>
          <br />
          Location: <b>{props.location}</b>
          <br />
          
          </CCardBody>
        </CCard>
        </div>}
      </>
     
    );
  }

  return (
    <>
    
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBQWNt0CI8rCrIdFt6_AUgExvRONdbm_ZI' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
     
        {facilities.map((facility, index) => (
          <Marker
          key={index}
          lat={facility.lat}
          lng={facility.lng}
          text={facility.name}
          type={facility.type}
          wing={facility.wing}
          netmix={facility.netmix}
          district={facility.district}
          location={facility.address}
        />
          
        ))}
      </GoogleMapReact>
      
    </div>
      <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <MapLegend facilities={facilities}></MapLegend>
                </CCardBody>
              </CCard>
            </CCol>
      </CRow>
    </>
  );
        }

export default FacilityMap



