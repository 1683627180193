import React, { useEffect, useState } from 'react'
import { CContainer,CRow, CCol, CFormLabel, CFormInput,CFormTextarea, CCard, CCardBody } from '@coreui/react'
import './AgGridStyle.css'
import helper from './helper'

const Items = ({ label, value }) => {
  if(label.includes('Name Of Work')||label.includes('NameOfWork')){
    return (
      <CCol xs={6} className='mt-2'>
      <CFormLabel>{label}</CFormLabel>
      <CFormTextarea value={value}  />
    </CCol>
    )
  }
  return (
    <CCol xs={6} className='mt-2'>
      <CFormLabel>{label}</CFormLabel>
      <CFormInput style={{width:'30%'}} value={value}  />
    </CCol>
  )
}

const MapView = ({ id }) => {
  return <iframe 
    src={`https://ris.ncog.gov.in/roadstatus/?id=${id}`} 
    width={'100%'} 
    
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
    height={650}></iframe>
}

const StatusItem = ({ label, value }) => {
    return <CCol xs={6} className="d-flex align-items-center mb-3">
    <CFormLabel className='mx-2'>{label}</CFormLabel>
    <CFormInput value={value} readOnly className={helper.statuscheck(value)}/>
  </CCol>
}

export const DynamicForm = ({ data }) => {
  var colSize = 6
try {
   colSize = data['Status'] ? 6:12
} catch (e) {
  console.log("Error")
}

    try {
      
      return (
        <>
          <CContainer>
            <CRow>
                { data['Name Of Work'] &&  
                <CCol>
                
                    <CCard className='mb-3'>
                        <CCardBody>
                       <b>{data['Name Of Work']}
                        </b> 
                        </CCardBody>
                    </CCard>
                </CCol>
                }
            </CRow>
            <CRow>
              <CCol xs={colSize}>
                <CRow>
                  {Object.keys(data).map((obj) => {
                    return <Items label={obj} value={data[obj]} />
                  })}
                </CRow>
              </CCol>
              
              {
                data['Status'] && <CCol xs={6}> <StatusItem label="Status" value={data['Status']} />
                <MapView id={data.WorkID} /></CCol>
              }
                
              
            </CRow>
          </CContainer>
        </>)
    } catch (e) {
      return (
        <></>
      )
    }
  

}
