import getDatawarehouseData from '../getDatawarehouseData'
import { GETDATA } from '../../constants/api.config'

export default async function ViewAllData(loginID="1",mID,tID,tData) {
  try {
    
    const payload = { loginID: '1', moduleID: mID, tranTypeID:tID, typeOfData:tData }
    const res = await getDatawarehouseData(GETDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}