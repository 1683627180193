import getCharts from '../getCharts'
import { AgGridFilterChart } from '../../constants/api.config'

export default async function AgGridChartFn(mode,name,value,loginID="1") {
  try {
    const payload = {mode,name,value,loginID }
    const res = await getCharts(AgGridFilterChart, payload)
    return res.data
  } catch (error) {
    return error
  }
}
