import React from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import EditDetails from './EditDetails'
import InfoDetails from './InfoDetails'


const DetailsLayout = ({ data,type }) => {
  const [active, setActive] = React.useState('info')
  const wing = localStorage.getItem('wing_name')
  var colSize = 6

  try {
    colSize = data['Status'] ? 6 : 12
  } catch (e) {}
  return (
    <div>
      <div className="wrapper min-vh-100 bg-light">
        <AppHeader isDetails={true} title={data['Name Of Work'] ? data['Name Of Work'] : ''} />
        <div className='d-flex align-items-center justify-content-center  min-vh-90' >
          {
            type === 'false' ? <InfoDetails data={data} active={active} setActive={setActive} />:
            <EditDetails data={data} wing={wing}/>
          }
        </div>
      </div>
      <AppFooter />
    </div>
  )
}

export default DetailsLayout