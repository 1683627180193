import getCharts from '../getCharts'
import { HOMECHARTS } from '../../constants/api.config'

export default async function getAllHomeCharts(loginID, mID, tranID, departmentName = "ALL",wingName = "ALL") {
  try {
    const payload = {loginID, moduleID: mID, tranTypeID:tranID, departmentName,wingName }
    const res = await getCharts(HOMECHARTS, payload)

    return res.data
  } catch (error) {
    return error
  }
}
