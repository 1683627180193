import React from 'react'
import { CCol, CRow,
 CButton,CModal,CModalBody, CModalHeader, CModalTitle,CModalFooter,CDropdown,CDropdownItem,CDropdownMenu,CDropdownToggle } from '@coreui/react'
import { CSpinner  } from '@coreui/react'
import Modal from '../../components/shared/ModalTable'
import Loading from 'react-fullscreen-loading';
import NewModal from '../../components/shared/Modal'
import ToggleSwitch from '../../components/shared/ToggleSwitch'

// import DatePicker from 'antd'
import Loader from '../../components/shared/Loader'

import {
  CChartBar,
  CChartLine,
} from '@coreui/react-chartjs'

import GridView from '../../components/shared/GridView'


import { useEffect } from 'react'
import Card from '../../components/Card'


import { useState, useRef } from 'react'
import { useCallback } from 'react'
import GetApiLogs from '../../services/general/getAPILog.api'
import CustomAgGrid from '../../../src/components/shared/CustomAgGrid';

 const  APILOG = () => {
  const loginID = "1"
  const moduleID = "-2"
  const tranTypeID = "-2"


  const [modalVisible, setModalVisible] = useState(false)
 

const [plants, setPlants] =useState([])
const newPlants = useRef([])

  const [modalData, setModalData] = useState("")
  const [loading, setloading] = useState(false);

  const [modalTitle, setModalTitle] = useState("")

    const [today,setToday] = useState(new Date())


  const setModalVisibleFn = (value) => {
    setModalTitle("")
    setModalVisible(value)
  }


 const fetchData = async () => {

    let c = await GetApiLogs("","")
    console.log(c)
    let data = c.d
    setModalData(data)   
    setModalVisible(true)
  
}

  useEffect( () => {
      try{
        fetchData()
      } catch(e) {
        console.log("Error",e)
      }
  },[today])
    return (
        <>
   {/* <Modal open={modalVisible} isMapRequired={false}  setVisibleFn={setModalVisibleFn} type="table"  title={modalTitle} value="" data={modalData}></Modal> */}

        <CustomAgGrid options={modalData} isMapRequired={false}  title={modalTitle}></CustomAgGrid>
        </>
    )
}


export default APILOG