import { useQuery } from "react-query";

import getWings from './general/getWings.api.js'
import getDivision from './general/getDivision.api.js'
import getOffice from './general/getOffice.api.js'
import getAllCharts from './general/getAllCharts.api.js'
import getModules from './general/getModules.api.js'
import getFilterData from './general/getFilterData.api.js'
import getAllWorks from './general/getAllWorks.api.js'
import getAllWorkBuilding from './general/getAllWorksBuilding.api.js'
import getAllHomeChart from './general/getAllHomeCharts.api'
import getAllHomeChartTemp from './general/getAllHomeChartsTemp.api'

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export const getWingFilter = () => useQuery('getWings', getWings, { refetchOnWindowFocus: false })

export const getCharts = (loginID, moduleID, tranTypeID) => useQuery('getAllCharts', () => getAllCharts(loginID, moduleID, tranTypeID), { refetchOnWindowFocus: false,cacheTime:0 ,refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: twentyFourHoursInMs})
export const getAllHomeChartsTemp = (loginID, moduleID, tranTypeID,filters) => useQuery('getAllHomeChartTemp', () => getAllHomeChartTemp(loginID, moduleID, tranTypeID,filters), { 
    refetchOnWindowFocus: false,refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: twentyFourHoursInMs})
export const getAllHomeCharts = (loginID, moduleID, tranTypeID) => useQuery('getAllHomeChart', () => getAllHomeChart(loginID, moduleID, tranTypeID), { 
        refetchOnWindowFocus: false,cacheTime:0 ,refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        retry: false,
        staleTime: twentyFourHoursInMs})
export const getAllHomeBuildingsCharts = (loginID, moduleID, tranTypeID,departmentName = "ALL",wingName="ALL") => useQuery(['getAllHomeChart',wingName], () => getAllHomeChart(loginID, moduleID, tranTypeID,departmentName,wingName), { 
    refetchOnWindowFocus: false,cacheTime:0 ,refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    retry: false,
    staleTime: twentyFourHoursInMs})
export const getDivsionFilter = () => useQuery(['getDivision','0'], () => getDivision("0"), { refetchOnWindowFocus: false })
export const getAllWork = (loginID = 1, workType) => useQuery(['getAllWorks','0'], () => getAllWorks(loginID, workType), { refetchOnWindowFocus: false })
export const getAllWorksBuilding = (loginID=2) => useQuery(['getAllWorkBuilding','0'], () => getAllWorkBuilding(loginID), { refetchOnWindowFocus: false })

export const getOfficeFilter = () => useQuery(['getOffice','0'], () => getOffice("0"), { refetchOnWindowFocus: false })
export const getModuleDetails = () => useQuery('getModules', () => getModules("1"), { refetchOnWindowFocus: false,cacheTime:0 })
export const getAllFilters = (loginID, moduleID, tranTypeID, chartID) => useQuery('getFilterData', () => getFilterData(loginID, moduleID, tranTypeID, chartID), { refetchOnWindowFocus: false, enabled: false,cacheTime:0,refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,retry: false,
    staleTime: twentyFourHoursInMs,  })
