import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    zoom: 1,
    setTable: "",
};

const commomSlice = createSlice({
    name: 'common',
    initialState: {...initialState},
    reducers: {
        onZoom(state, action){
            state.zoom = action.payload.data;
        },
        onSetTable(state,action){
            state.setTable = action.payload.data;
        }
    },
});

export const commonAction = commomSlice.actions;

export default commomSlice;