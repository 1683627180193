import React from 'react'
import PropTypes from 'prop-types'
import { CCard, CCardHeader, CCardBody } from '@coreui/react'

function Card({ ...props }) {
  return (
    <CCard>
      <CCardHeader onClick={props.onClick}>
       <b style={{fontSize:'18px'}}> {props.title}</b> 
        {!props.simple && (
          <button style={{ float: 'right',height:'35px !important' }}  className="border-0 outline-0 bg-transparent">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z" />
            </svg>
          </button>
        )}
      </CCardHeader>
      <CCardBody>{props.children}</CCardBody>
    </CCard>
  )
}

Card.propTypes = {
  title: PropTypes.string,
}

export default Card
