import React from 'react'
import { HomeBuildings } from './views/dashboard/HomeBuildings'
import {Buildings} from './views/dashboard/Buildings'

import  Capital  from './views/dashboard/Capital'
import  PMGSY  from './views/dashboard/PMGSY'
import  OTHERROADS  from './views/dashboard/OtherRoads'
import MainDashboard from './views/dashboard/MainDashboard1'
import NationalHighway from './views/dashboard/NationalHighway'
import Contractor from './views/dashboard/Contractor'
import HR from './views/dashboard/HR'
import APILOG from './views/dashboard/APILOG'

import RPMS from './views/dashboard/RPMS'
import ALL from './views/dashboard/ALL'
import GujMarg  from './views/dashboard/GujMarg'
import HotMixPlant  from './views/dashboard/HotMixPlant'
import SCADA from './views/dashboard/Scada'
//const MainDashboard = React.lazy(() => import('./views/dashboard/MainDashboard1'))
//const NationalHighway = React.lazy(() => import('./views/dashboard/NationalHighway'))
const HRMS = React.lazy(() => import('./views/dashboard/HRMS'))
//const RPMS = React.lazy(() => import('./views/dashboard/RPMS'))
import GujRams from './views/dashboard/GujRams'

import MapComponent from './views/dashboard/MapComponent'
import Watch_list from './components/shared/Watch_list'

import PrintView from './views/dashboard/PrintView'
import { components } from 'react-select'
//const GujMarg = React.lazy(() => import('./views/dashboard/GujMarg'))
const Details = React.lazy(() => import('./views/dashboard/Details'))
const ChildTable = React.lazy(() => import('./views/dashboard/ChildTable'))
const Register = React.lazy(() => import('./views/pages/Register/Register'))
const RPMSRoads = React.lazy(() => import('./views/datawarehouse/RPMSRoads'))

const Home = React.lazy(() => import('./views/dashboard/Home'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/home', exact: true, component: Home, name: 'HomeNew' },
  { path: '/home/ALL', exact: true, component: ALL, name: 'HomeALL' },
  { path: '/home/Map', exact: true, component: MapComponent, name: 'MapComponent' },

  { path: '/homeBuilding', exact: true, component: HomeBuildings, name: 'HomeBuildings' },
  { path: '/watchlist', exact: true, component: Watch_list, name: 'HomeBuildings' },
  { path: '/buildings/:wing', exact: true, component: Buildings, name: 'Buildings' },
  { path: '/print', name: 'Print', component: PrintView},
  { path: '/dashboard/mmgsy', name: 'RPMS', component: RPMS, exact: true },
  { path: '/dashboard/hrms', name: 'HRMSDashboard', component: HRMS, exact: true },
  { path: '/dashboard/capital', name: 'Capital', component: Capital, exact: true },
  { path: '/dashboard/pmgsy', name: 'PMGSY', component: PMGSY, exact: true },
  { path: '/dashboard/otherroads', name: 'OTHERROADS', component: OTHERROADS, exact: true },
  { path: '/dashboard/contractor', name: 'CONTRACTOR', component: Contractor, exact: true },
  { path: '/dashboard/scada', name: 'SCADA', component: SCADA, exact: true },
  { path: '/dashboard/apilogs', name: 'APILOGs', component: APILOG, exact: true },

  { path: '/dashboard/hr', name: 'HR', component: HR, exact: true },

  { path: '/dashboard/hotmixplant', name: 'HotMixPlant', component: HotMixPlant, exact: true },
  { path: '/dashboard/wms', name: 'WMS', component: MainDashboard, exact: true },
  { path: '/dashboard/gujrams', name: 'GujRams', component: GujRams, exact: true },
  { path: '/dashboard/gujmarg', name: 'GujMarg', component: GujMarg, exact: true },
  { path: '/dashboard/nh', name: 'NationalHighway', component: NationalHighway, exact: true },
  { path: '/details', name: 'Details', component: Details},
  { path: '/register', name: 'Register', component: Register, exact: true},
  { path: '/childTable', name: 'ChildTable', component: ChildTable},
  { path: '/datawarehouse', name: 'RPMS Works', component: RPMSRoads, exact: true},
  { path: '/datawarehouse/RPMS/:data', name: 'RPMS Works', component: RPMSRoads, exact: true},

]

export default routes
