import getCharts from '../getCharts'
import { WatchList } from '../../constants/api.config'

export default async function getAllHomeWorks(login="1", mode, workids) {
  try {
    const payload = {login: '1', mode, workids }
    const res = await getCharts(WatchList, payload)
    return res.data
  } catch (error) {
    return error
  }
}
