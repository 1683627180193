import './component.css'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Select from 'react-select'

export const MinimalInput = ({ error, value, onChange, label, w, labelStyles, InputStyles, ...props }) => (
  <div className="minimal-input" style={w ? { width: `${w}%` } : { width: 'auto' }}>
    <div className={`minimal-input-label ${error ? 'error-label' : ''} ${props.disabled ? 'disabled-label' : ''}`} style={labelStyles}>
      {label}
    </div>
    <input className={`minimal-input-base ${error ? 'error-input' : ''}`} style={InputStyles} {...props} value={value} onChange={(e) => onChange(e.target.value)} />
    {error && error.error && <span className="error-msg">{error.msg}</span>}
  </div>
)

export const Chip = ({ name, onClose }) => (
  <div className="d-flex m-1 px-2 align-items-center chip " style={{background:'#fe812eb5'}}>
    <div className="py-1 px-2">{name}</div>
    <Button type="text" size="small" onClick={onClose} shape="circle" style={{ color: '#fff' }} icon={<CloseOutlined />} className="mr-2" />
  </div>
)

export const MinimalSelect = ({ error, label, w, value, options, onChange, placeholder, scrollToBottom, labelStyles, inputStyles, errorStyles, ...props }) => {
  const basic = { border: 0, display: 'flex', padding: 0, borderRadius: '4px', background: '#eee', fontSize: '14px' }
  const getErrorStyle = () => {
    if (!error) return
    else return (errorStyles = { background: '#ff000021', border: '1px solid red', color: 'red' })
  }
  const styles = {
    menu: (provided, state) => ({ ...provided, padding: '0 0 0 4px ', borderRadius: '4px', border: 0, outline: 0, overflowY: 'hidden' }),
    option: (provided, state) => ({ ...provided, borderRadius: '4px', background: state.isSelected ? '#ff9747c9' : state.isFocused ? '#f7f7f7' : 'none', color: '#000' }),
    control: () => ({ ...basic, ..._inputStyles, ...getErrorStyle(), '&:hover': { cursor: props.isDisabled ? 'not-allowed' : 'pointer' } }),
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
    multiValueRemove: (base, state) => {
      return state.data.isFixed || props.isDisabled ? { ...base, display: 'none' } : base
    },
    multiValue: (base, state) => ({ ...base, padding: '1px 4px' }),
  }
  const _inputStyles = inputStyles || { background: 'none', padding: '2px 6px', border: '1px solid #d8d8dc', borderRadius: '8px' }
  return (
    <div className="minimal-input" style={w ? { width: `${w}%` } : { width: 'auto' }}>
      <div className={`minimal-input-label ${error ? 'error-label' : ''}`}>{label}</div>
      <Select {...props} options={options} styles={styles} onMenuScrollToBottom={scrollToBottom} placeholder={placeholder} value={value} onChange={(v) => onChange(v)} className={props.isDisabled ? 'react-select-disabled' : ''} />
      {error && error.error && <span className="error-msg">{error.msg}</span>}
    </div>
  )
}
