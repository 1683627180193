import {React, useContext} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import navigation from '../_nav'
import { useState, useEffect } from 'react'
import {getModuleDetails}  from '../services/apicalls.js'
import { UserContext } from '../Contexts/UserContext'

const AppSidebar = (url) => {
  const dispatch = useDispatch()
  const { encryptedURL } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [nav, setNav] =useState([])
  // const wing = JSON.parse(localStorage.getItem("loggedInUserData")).Wing
  const [newNavigation, setNewNavigation] = useState(navigation)

  useEffect(()=> {

    try {
      let tempNavigation =navigation
      tempNavigation[1].items[0].href = "http://103.27.120.198/RNBCR/Login.aspx?encryptedURl=" +  url?.url
      tempNavigation[1].items[1].href = "http://103.27.120.198/RNB_PM/login.aspx?encryptedURl=" +  url?.url
      tempNavigation[1].items[2].href = "https://103.27.120.198/RNB_pwm/login.aspx?encryptedURl=" +  url?.url
      tempNavigation[1].items[3].href = "http://103.27.120.198/RNBHR_USER/Login.aspx?encryptedURl=" +  url?.url
      setNewNavigation(tempNavigation)
  } catch(e) {
  
  }

  },[url])





  // const items = newNavigation[0].items.filter((obj, i) => {
  //   const newItems = []
  //   if (wing == "0") {
  //     return true
  //   } else if(obj.name.includes(wing)) {
  //          return true
  //                 } else if (obj.name.includes("Buildings")) {
  //         return true
  //       }
  // })

  // const items2 = newNavigation.filter((obj, i) => {
  //   if (wing == "0") {
  //     return true
  //   }else if(obj.name.includes("API Integration Data")){
  //     return false
  //   }else{
  //     return true
  //   }
  // })

  // const items3 = newNavigation[1].items.filter((obj, i) => {
  //   if (wing == "0") {
  //     return true
  //   } else if(obj.name.includes("Contractor Registration") || obj.name.includes("HR- Employee Data")) {
  //     return false
  //   }else{
  //     return true
  //   }
  // })
  
  // newNavigation[0].items = items
  // newNavigation[1].items = items3
  // newNavigation = items2

  // useEffect(()=> {
      
  // },[])


  return (
    <CSidebar position="fixed" unfoldable={true} visible={sidebarShow} onVisibleChange={(visible) => dispatch({ type: 'set', sidebarShow: visible })}>
      <CSidebarBrand className="d-none d-md-flex " to="/"></CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={newNavigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler className="d-none d-lg-flex" onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })} />
    </CSidebar>
  )
}

export default AppSidebar
