
var wing = ""
try {
  if (JSON.parse(localStorage.getItem("loggedInUserData"))?.Wing) {
     wing = JSON.parse(localStorage.getItem("loggedInUserData"))?.Wing
  
  } else {
    localStorage.setItem(JSON.stringify({"Wing":"0"}),"loggedInUserData")
     wing = JSON.parse(localStorage.getItem("loggedInUserData"))?.Wing
  
  }

} catch (e) {
  localStorage.setItem(JSON.stringify({"Wing":"0"}),"loggedInUserData")
   wing = JSON.parse(localStorage.getItem("loggedInUserData"))?.Wing
}



const getWingsData = () => {
  if(wing == "0"){
    return [
      { label: 'ALL', value: '0' },
      { label: 'Panchayat', value: 'Panchayat' },
      { label: 'State Highway', value: 'State Highway' },
      { label: 'National Highway', value: 'National Highway' },
      { label: 'Capital Project', value: 'Capital Project' },
    ]
  }else if(wing == "Panchayat"){
    return [
      { label: 'Panchayat', value: 'Panchayat' },
    ]
  }else if(wing == "State"){
    return [
      { label: 'State Highway', value: 'State Highway' },
    ]
  }else if(wing == "National Highway"){
    return [
      { label: 'National Highway', value: 'National Highway' },
    ]
  }
}

export const filterData = [
  {
    displayName: 'Region',
    idnumber: '3059',
    Values: [{label: 'ALL', value: '0'},{label: 'North Gujarat', value: '1'},{label: 'South Gujarat', value: '2'},{label: 'East Gujarat', value: '3'},{label: 'West Gujarat', value: '4'}],
  },
    {
      displayName: 'Wing',
      idnumber: '3060',
      Values: getWingsData(),
    },
    {
      displayName: 'Status',
      idnumber: '3059',
      Values: [
        { label: 'ALL', value: '0' },
        { label: 'Not Started', value: 'Not Started' },
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Phy. Completed', value: 'Phy. Completed' },
        { label: 'Stopped', value: 'Stopped' },
        { label: 'Dropped', value: 'Dropped' },
        { label: 'Maintenance ', value: 'Maintenance' },
      ],
    },
    {
      displayName: 'Year of Approval',
      idnumber: '30643',
      Values: [
        { label: 'ALL', value: '0' },
        { label: '2016 - 17', value: '2016 - 17' },
        { label: '2017 - 18', value: '2017 - 18' },
        { label: '2018 - 19', value: '2018 - 19' },
        { label: '2019 - 20', value: '2019 - 20' },
        { label: '2020 - 21', value: '2020 - 21' },
        { label: '2021 - 22', value: '2021 - 22' },
        { label: '2022 - 23', value: '2022 - 23' },
      ],
    },
    {
      displayName: 'Work Category',
      idnumber: '30645',
      Values: [
        { label: 'ALL', value: 'ALL' },
        { label: 'BRIDGE', value: 'BRIDGE' },
        { label: 'ROAD', value: 'ROAD' },
      ],
    },
    {
      displayName: 'Scheme',
      idnumber: '30646',
      Values: [
        { label: 'ALL', value: 'ALL' },
        { label: 'MMGSY', value: 'MMGSY' },
        { label: 'PMGSY', value: 'PMGSY' },
        { label: 'Other Panchayat Roads', value: 'Other Panchayat Roads' },
      ],
    },
  ];

export const scadaPattern = {
    idnumber: '5',
    title: 'Total Running Plants (Scada)',
    type: 'bar',
    labels: [''],
    datasets: [
      {
        label: 'CP',
        data: [''],
      },
      {
        label: 'NH',
        data: [''],
      },
      {
        label: 'State',
        data: [''],
      },
      {
        label: 'Panchayat',
        data: [''],
      },
      {
        label: 'Misc',
        data: [''],
      },
    ],
    showLegend: 'true',
    yaxis: [
      {
        title: '',
      },
    ],
    xaxis: {
      title: 'Wings',
    },
  }

export const scadaWorking = {
    idnumber: '5',
    title: 'Total Working Plants (Scada)',
    type: 'bar',
    labels: [''],
    datasets: [
      {
        label: 'CP',
        data: [''],
      },
      {
        label: 'NH',
        data: [''],
      },
      {
        label: 'State',
        data: [''],
      },
      {
        label: 'Panchayat',
        data: [''],
      },
    ],
    showLegend: 'true',
    yaxis: [
      {
        title: '',
      },
    ],
    xaxis: {
      title: 'Wings',
    },
  }