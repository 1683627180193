import { useState, useEffect } from 'react'
import { Button, Drawer } from 'antd'
import CustomDateFilter from './CustomDateFilter'
import { MinimalSelect } from './components'
// import XMLParser from 'react-xml-parser'
// import { useQuery } from 'react-query'
// import getWings from '../../services/general/getWings.api.js'

function FilterDrawer({ onClose, visible, fetchDataFn,  filterData, setFiltersFn, setFilterValuesFn, filterValuesState}) {
  //const [dateFilterVal, setDateFilterVal] = useState('')

  let filter = []
  // selected value of each filter
  let initialFilterValues = []
  let customfilters = []
  let currentFilterValues = []


  useEffect(()=>{
    if (filterValuesState?.length == 0) {

      filterData.map((fdata, index) => {
        initialFilterValues[index] = fdata.Values[0]
        }
      )
      //setfilterValues(initialFilterValues)

      currentFilterValues = JSON.parse(JSON.stringify(initialFilterValues))  
  
    } else  {

      currentFilterValues = JSON.parse(JSON.stringify(filterValuesState))  
    }
    

  },[filterData])

 

  const applyFilters = () => {
    let newFilters = []
    const filters = [] 
    if (currentFilterValues.length == 0) {
      currentFilterValues = filterValuesState
    }
    for(let a=0; a<filterData.length; a++) {
      newFilters.push({ idnumber: filterData[a].idnumber, value: currentFilterValues[a] })
      filters.push({ name: `${filterData[a].displayName} : "${currentFilterValues[a].label}"` })
    }
    setFiltersFn(filters)
    fetchDataFn(newFilters)

    setFilterValuesFn(currentFilterValues)
    handleClose()
}


const clearFilters = () => {
  filterData.map((fdata, index) => {
    initialFilterValues[index] = fdata.Values[0]
    }
  )
  currentFilterValues = JSON.parse(JSON.stringify(initialFilterValues))  
  setFilterValuesFn(initialFilterValues)
}

const handleClose = () => {
  //  setfilterValues('')
 // setfilterValues(currentFilterValues)
  onClose()
}

const onFilterSet = (selectedItem, itemIndex) => {
  currentFilterValues[itemIndex.index] = selectedItem
    // customfilters = selectedFilterValues
    // setffliter(selectedFilterValues)
};
  
return (
    <Drawer width={450} title="Filters" placement="right" onClose={handleClose}  visible={visible} style={{ zIndex: 10000 }}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          {/* <CustomDateFilter setVal={setDateFilterVal} /> */}
          <div className="mt-2">
            {
              filterData.map((fdata, index) => {
                
                return <MinimalSelect key={index} placeholder={filterValuesState[index]?.label} value={filterValuesState[{index}]}  
                       onChange={(selectedItem) => onFilterSet(selectedItem, {index})} options={fdata.Values} label={fdata.displayName} w={100} />
              })
            }
          </div>
        </div>
        <div className="d-flex justify-content-between	">
          <Button className="w-50" onClick={clearFilters} style={{border:'1px solid', marginRight:'2px'}}>Clear All</Button>
          <Button className="w-50" type="primary" onClick={applyFilters}>
            Apply Filters
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default FilterDrawer