import getCharts from '../getCharts'
import { HOMECHARTSDATA } from '../../constants/api.config'

export default async function GetHomeChartsData(databaseName, label, chartID = -1, departmentName = "ALL") {
  try {
    const payload = {databaseName, loginID: '1', label, chartID, departmentName,typeOfWork:"roads" }
    const res = await getCharts(HOMECHARTSDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}


