const dbName = "myDatabase";
const dbVersion = 1;

const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

export function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = idb.open(dbName, dbVersion);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("myObjectStore")) {
        db.createObjectStore("myObjectStore", { keyPath: "id", autoIncrement: true });
      }
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject("Error opening IndexedDB database");
    };
  });
}


export function getDataByKey(key) {
    return new Promise((resolve, reject) => {
      openDatabase()
        .then((db) => {
          const transaction = db.transaction(["myObjectStore"], "readonly");
          const objectStore = transaction.objectStore("myObjectStore");
          const request = objectStore.get(key);
  
          request.onsuccess = function (event) {
            const result = event.target.result;
            if (result) {
              resolve(result);
            } else {
              reject("Data not found");
            }
          };
  
          request.onerror = function (event) {
            reject("Error getting data from IndexedDB");
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


export function addData(data) {
    return new Promise((resolve, reject) => {
      openDatabase()
        .then((db) => {
          const transaction = db.transaction(["myObjectStore"], "readwrite");
          const objectStore = transaction.objectStore("myObjectStore");
          const request = objectStore.add(data);
  
          request.onsuccess = function (event) {
            resolve(event.target.result);
          };
  
          request.onerror = function (event) {
            reject("Error adding data to IndexedDB");
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  export function getAllData() {
    return new Promise((resolve, reject) => {
      openDatabase()
        .then((db) => {
          const transaction = db.transaction(["myObjectStore"], "readonly");
          const objectStore = transaction.objectStore("myObjectStore");
          const request = objectStore.getAll();
  
          request.onsuccess = function (event) {
            resolve(event.target.result);
          };
  
          request.onerror = function (event) {
            reject("Error getting data from IndexedDB");
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  // Implement updateData() and deleteData() functions in a similar way
  