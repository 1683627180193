import React from 'react'
import { CSpinner } from '@coreui/react'

const Loader = () => {
  return (
    <div className="loder">
      <div className="loader-box">
        <CSpinner component="span" size="sm" variant="grow" aria-hidden="true" />
        Loading...
      </div>
    </div>
  )
}

export default Loader
