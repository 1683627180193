import { useState, useEffect } from 'react'
import { Button, Drawer } from 'antd'
import CustomDateFilter from './CustomDateFilter'
import { MinimalSelect } from './components'
import { toastConfig } from '../../constants/other.config'
import toast from 'react-hot-toast'
// import XMLParser from 'react-xml-parser'
// import { useQuery } from 'react-query'
// import getWings from '../../services/general/getWings.api.js'

function FilterDrawer({ onClose, visible, fetchDataFn,  filterData, setFiltersFn, setFilterValuesFn, filterValuesState,applyFilterFn,isFilterApplied}) {
  const [dateFilterVal, setDateFilterVal] = useState('')
  const [wodateFilter, setwodateFilter] = useState('')
  const [comdateFilter, setComdateFilter] = useState('')

  let filter = []
  // selected value of each filter
  let initialFilterValues = []
  let customfilters = []
  let currentFilterValues = []


  useEffect(()=>{
    if (filterValuesState?.length == 0) {

      filterData.map((fdata, index) => {
        initialFilterValues[index] = fdata.Values
        }
      )
      setFilterValuesFn(initialFilterValues)
      
      currentFilterValues = JSON.parse(JSON.stringify(initialFilterValues))  
  
    } else  {

      currentFilterValues = JSON.parse(JSON.stringify(filterValuesState))  
    }
    

  },[filterData])


  const formatDate = (str) =>{
    const today = new Date(str);
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

const formattedToday = dd + '/' + mm + '/' + yyyy;
return formattedToday
  }
 

  const applyFilters = () => {
    let newFilters = []
    const filters = [] 
    if (currentFilterValues.length == 0) {
      currentFilterValues = filterValuesState
    }

    for(let a=0; a<filterData.length; a++) {
      try {
        newFilters.push({ idnumber: filterData[a].idnumber, value: currentFilterValues[a] })
        filters.push({ name: `${filterData[a].displayName}`,value : `${currentFilterValues[a].label}` })
      } catch (e) {
        
      }
    }
//    currentFilterValues.unshift({label:dateFilterVal,value:dateFilterVal});

    if (dateFilterVal!="") {
      try {
        let frmDate = formatDate(dateFilterVal.split('-')[0].trim())
        let toDate = formatDate(dateFilterVal.split('-')[1].trim())
        filters.push({name:"AA Date", value:frmDate + '-' + toDate})
      } catch (e) {
      }
    }

    if (wodateFilter!="") {
      try {
        let frmDate = formatDate(wodateFilter.split('-')[0].trim())
        let toDate = formatDate(wodateFilter.split('-')[1].trim())
        filters.push({name:"Work Order Date", value:frmDate + '-' + toDate})
      } catch (e) {

      }
      
    }

    if (comdateFilter!="") {
      try {
        let frmDate = formatDate(comdateFilter.split('-')[0].trim())
        let toDate = formatDate(comdateFilter.split('-')[1].trim())
        filters.push({name:"Work Completion Date", value:frmDate + '-' + toDate})
      } catch (e) {

      }
      
    }


    setFiltersFn(filters)

    //fetchDataFn(newFilters)
    applyFilterFn(filters)

    setFilterValuesFn(currentFilterValues)
    handleClose()

    if(filters.length===0){
      isFilterApplied(false)
    }
    else{
      isFilterApplied(true)
    }
    
    filters.map((foptions, index)=>{
      if(foptions.value!=="ALL" ||foptions.name==="Work Order Date" || foptions.name==="AA Date"){
        toast.success(`${foptions.name} : ${foptions.value}`, toastConfig)
        }
    })
}


const clearFilters = () => {
  isFilterApplied(false)

  filterData.map((fdata, index) => {
    initialFilterValues[index] = fdata.Values[0]
    }
  )
  currentFilterValues = JSON.parse(JSON.stringify(initialFilterValues))  
  setFilterValuesFn(initialFilterValues)
}

const handleClose = () => {
  //  setfilterValues('')
 // setfilterValues(currentFilterValues)
  onClose()
}

const onFilterSet = (selectedItem, itemIndex) => {
  currentFilterValues[itemIndex.index] = selectedItem
    // customfilters = selectedFilterValues
    // setffliter(selectedFilterValues)
};
return (
    <Drawer width={450} title="Filters" placement="right" onClose={handleClose}  visible={visible} style={{ zIndex: 10000 }}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          
          <div className='mb-2'>
          <CustomDateFilter DateName={"AA Approval Date"} setVal={setDateFilterVal} />
          </div>
          <div className='mb-2'>
          <CustomDateFilter DateName={"Work Order Date"} setVal={setwodateFilter} />
          </div>

          <div className='mb-2'>
          <CustomDateFilter DateName={"Work Completion Date"} setVal={setComdateFilter} />
          </div>

          <div className="mt-2">
            {
              filterData.map((fdata, index) => {
                return <MinimalSelect key={index} placeholder={filterValuesState[index]?.label} value={filterValuesState[{index}]}  
                       onChange={(selectedItem) => onFilterSet(selectedItem, {index})} options={fdata.Values} label={fdata.displayName} w={100} />
              })
            }
          </div>
        </div>
        <div className="d-flex justify-content-between	">
          <Button className="w-50" onClick={clearFilters} style={{border:'1px solid', marginRight:'2px'}}>Clear All</Button>
          <Button className="w-50" type="primary" onClick={applyFilters}>
            Apply Filters
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default FilterDrawer
