import React, { useReducer } from 'react'
import { CCol, CRow, CCard, CCardBody, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CButtonGroup, CCardTitle, CCardText, CCardImage, CButton, CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CFormSelect, CCardHeader } from '@coreui/react'
import { CWidgetStatsA, CWidgetStatsD, CFormSwitch } from '@coreui/react'
import ChartWrapper from '../../components/shared/HomeChartWrapper'
import Modal from '../../components/shared/ModalTable'
import Loading from 'react-fullscreen-loading'
import NewModal from '../../components/shared/Modal'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import GridView from '../../components/shared/GridView'
import { getAllHomeBuildingsCharts, getAllFilters, getAllWorksBuilding } from '../../services/apicalls.js'
import getAllHomeCharts from '../../services/general/getAllHomeCharts.api.js'
import ToggleSwitch from '../../components/shared/ToggleSwitch'

import GetHomeChartsData from '../../services/general/getBuildingsChartsData.api.js'
import Loader from '../../components/shared/Loader'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'
import FilterDrawer from '../../components/shared/FilterDrawer'

import WidgetWrapper from '../../components/shared/WidgetWrapper'
import { useEffect, useRef } from 'react'
import Card from '../../components/Card'

import { useState } from 'react'
import { useCallback } from 'react'

const Departments = ['ALL', 'Education', 'Labour and employment', 'Sports Y. & Culture', 'Legal', 'Tribal Development', 'Agriculture', 'Industry & Mining', 'Finance', 'Animal husbandry', 'R & B', 'Ports & Transport', 'Revenue']

const Wings = [{
  Values:[
    {
        "label": "ALL",
        "value": "ALL"
    },
    {
        "label": "State Highway",
        "value": "State Highway"
    },
    {
        "label": "Policy and Planning",
        "value": "Policy and Planning"
    },
    {
        "label": "STC",
        "value": "STC"
    }
],
  displayName:"Wings",
  idnumber:"3060"
}]


//const Wings = ['ALL','State Highway', 'Policy and Planning','STC']

const WingMap = {
  "wms":"State Highway",
  "pp":"Policy and Planning",
  "stc":"STC"
}
export const HomeBuildings = () => {
  const loginID = '2'
  const moduleID = '-3'
  const tranTypeID = '-3'
  const [checked, setChecked] = useState(false)
  const [modalChartLoading, setModalChartLoading] = useState(false)
  const [gridData, setGridData] = useState({})
  const [loading, setloading] = useState(false)
  const [allStatus, setAllStatus] = useState(['Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...', 'Loading...'])

  const [modalVisible, setModalVisible] = useState(false)
  const [isDrawerOpened, setDrawerOpened] = useState(false)
  const [selectedModalFilters, setSelectedModalFilter] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [filterColor, setFilterColor] = useState(false)
  
  const [modalData, setModalData] = useState('')

  const selectedDepartment = useRef('ALL')

  const selectedWing = useRef('ALL')
  const [openModal, setOpenModal] = useState(false)
  const [chartData, setChartData] = useState({})
  const { isLoading, data: chartsData } = getAllHomeBuildingsCharts(loginID, moduleID, tranTypeID)

  const { isLoading: isLoadingWorkData, data: workData } = getAllWorksBuilding(loginID)

  const [lengthChartData, setLengthChartData] = useState([])
  const [chartID, setChartID] = useState('')
  const [chartOptions, setChartOptions] = useState([])

  const chartClickEvent = useCallback(
    async (databaseName, label, chartID) => {
      setloading(true)
      let modalData = await GetHomeChartsData(databaseName, label, chartID, selectedDepartment.current, selectedWing.current)
      console.log(modalData)
      setloading(false)
      if(modalData.d.includes('Error:') ){
        alert(modalData.d)
      }else{
        setModalData(modalData)
        setModalVisible(true)
      }
  
    },
    [chartsData],
  )

  const onModalClose = () => {
    setOpenModal(false)
  }

  const setChartGridData = (chart) => {
    if (chart.type == 'pie') {
      const cols = [
        { title: 'Name', dataIndex: 'label', key: 'label' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
      ]
      let total = 0
      let rows = chart.labels.map((d, index) => {
        //console.log(chart.datasets[0].data[index])
        if (chart.datasets[0].data[index] == '' || chart.datasets[0].data[index] == undefined) {
          chart.datasets[0].data[index] = '0'
        }
        total = total + parseInt(chart.datasets[0].data[index])
        return {
          label: d,
          value: chart.datasets[0].data[index],
          key: index,
        }
      })

      let row = {
        label: 'Total',
        value: total,
      }
      rows.push(row)
      setGridData({ rows, cols })
    } else {
      let colsList = []
      let cols = [{ title: 'Label', dataIndex: 'label' }]
      let total = []
      for (let i = 0; i < chart.datasets.length; i++) {
        cols.push({ title: chart.datasets[i].label, dataIndex: chart.datasets[i].label.toLowerCase() })
        colsList.push(chart.datasets[i].label.toLowerCase())
      }
      let data = []
      colsList.map((col, index) => {
        total[index] = 0
      })
      for (let j = 0; j < chart.labels.length; j++) {
        //total[j] = 0
        let el = {}
        colsList.map((col, index) => {
          total[index] = total[index] + parseFloat(chart.datasets[index].data[j])
          el[col] = chart.datasets[index].data[j]
        })

        el['key'] = j
        el['label'] = chart.labels[j]
        data.push(el)
      }
      let rows = data
      let row = {}

      colsList.map((col, index) => {
        row[col] = total[index]
      })
      row['label'] = 'Total'
      row['key'] = chart.labels.length
      rows.push(row)

      setGridData({ rows, cols })
    }
  }
  const handleClickOnCardExpand = (i) => {
    try {
      let chart = chartOptions[i]
      setChartData(chartOptions[i])
      setChartID(chartOptions[i].idnumber)
      window.globalChartID = chartOptions[i].idnumber

      setChartGridData(chart)
      setOpenModal(true)
    } catch (e) {
      console.log(e)
    }
  }

  // const typeOfRoadChart =  useCallback((databaseName, label, chartID)=> {
  //   let temp = JSON.parse(chartsData.d)
  //   let options = temp[2]
  //   let c = {}
  //   c.Data = []
  //   options["labels"].forEach((element,i) => {
  //     let ele ={

  //     }
  //     ele["AutoID"] = i + 1
  //     ele["Road Type"] = options["labels"][i]

  //     ele["Length(in Kms)"] = options["datasets"][0].data[i]

  //     c.Data.push(ele)

  //   });

  //   let jsonData = JSON.stringify(c)

  //   let d = {}
  //   d["d"] = jsonData

  //   setModalData(d)
  //   setModalVisible(true)
  // },[chartsData])

  const handleOnDepartmentChange = async (e) => {
    setloading(true)
    selectedDepartment.current = Departments[e.target.value]
    let chartsData = await getAllHomeCharts(loginID, moduleID, tranTypeID, Departments[e.target.value], selectedWing.current)
    parseChartData(chartsData)
    setloading(false)
  }

  const handleOnWingChange = async (filter) => {
    setloading(true)
    window.File =filter
    //selectedWing.current = Wings[e.target.value]
    let chartsData = await getAllHomeCharts(loginID, moduleID, tranTypeID ,"ALL", filter[0].value.value)
    parseChartData(chartsData)
    setloading(false)
  }

  const parseChartData = (chartData) => {
    try {
      setAllStatus(workData.d.split(','))
    } catch {}

    let temp = JSON.parse(chartData.d)

    setChartOptions(temp)
  }

  useEffect(() => {
    if (!isLoading && !isLoadingWorkData) {
      parseChartData(chartsData)
    }
  }, [isLoading, isLoadingWorkData])
  return (
    <>
      <CRow>
        <CCol sm={2}>
          <WidgetWrapper title={allStatus[0]} accessCode="2" workType="Building" value="Not Started" color="danger"></WidgetWrapper>
        </CCol>
        <CCol sm={2}>
          <WidgetWrapper title={allStatus[1]} accessCode="2" workType="Building" value="In Progress" color="warning"></WidgetWrapper>
        </CCol>
        <CCol sm={2}>
          <WidgetWrapper title={allStatus[2]} accessCode="2" workType="Building" value="Completed" color="success"></WidgetWrapper>
        </CCol>
        <CCol sm={2}>
          <WidgetWrapper title={allStatus[3]} accessCode="2" workType="Building" value="Stopped" color="primary"></WidgetWrapper>
        </CCol>
        <CCol sm={2}>
          <WidgetWrapper title={allStatus[4]} accessCode="2" workType="Building" value="Dropped" color="danger"></WidgetWrapper>
        </CCol>
        <CCol sm={2}>
          <WidgetWrapper title={allStatus[5]} accessCode="2" workType="Building" value="Total" color="info"></WidgetWrapper>
        </CCol>
      </CRow>

     

      <CCard>
        <CCardHeader>
          
          <CRow>
            <CCol xs={10}>
              <CRow>
                <CCol xs={4}>
                  </CCol>  
                  <CCol xs={4} style={{marginLeft:'20%'}}>
                  <CCardTitle> Buildings Data </CCardTitle>
                  </CCol>           
                     </CRow>
             
             </CCol>
            {/* <CCol xs={2}>
              <CRow>
                <CCol xs={6}>Department:</CCol>
                <CCol xs={6}>
                  <CFormSelect
                    onChange={handleOnDepartmentChange}
                    aria-label="Default select example"
                    options={Departments.map((e, i) => {
                      return { label: e, value: i }
                    })}
                  />
                </CCol>
              </CRow>
            </CCol> */}
            {/* <CCol xs={2}>
              <CRow>
                <CCol xs={4}>Wing:</CCol>
                <CCol xs={8}>
                   <CFormSelect
                    onChange={handleOnWingChange}
                    options={Wings.map((e, i) => {
                      return { label: e, value: i }
                    })}
                  /> 
                </CCol>
              </CRow>
            </CCol> */}
          </CRow>
        </CCardHeader>
        
        <CCardBody style={{ backgroundColor: '#ebedef' }}>
        <CRow className="mb-2">
        <button onClick={() => setDrawerOpened(true)} style={{ position: 'fixed', top: '80%', right: '5%', zIndex: 1000, 
      alignItems: 'center', backgroundColor: filterColor?'orange':'#5b4ce2', borderWidth: 0, width: 60, height: 60, borderRadius: 30 }}>
        <CIcon icon={icon.cilFilter} size="xl" style={{ alignSelf: 'center', color: 'white', fontWeight: 'bolder' }} />
      </button>
        <FilterDrawer onClose={() => setDrawerOpened(false)} visible={isDrawerOpened} fetchDataFn={handleOnWingChange} filterData={Wings} setFiltersFn={setSelectedModalFilter} setFilterValuesFn={setFilterValues} filterValuesState={filterValues} />
        {/* <CCol xs={8}>
          {chartOptions[0] ? (
            <Card
              title="Number of Works Department Wise"
              onClick={() => {
                handleClickOnCardExpand(0)
              }}
            >
              <ChartWrapper height="350px" chartID="1" options={chartOptions[0]} chartClickEvent={chartClickEvent} />
            </Card>
          ) : (
            <Card title="Number of Works Department Wise">
              <p>Loading</p>
            </Card>
          )}
        </CCol> */}
        <CCol xs={4}>
          {chartOptions[5] ? (
            <Card
              title="Number of Works Wing Wise"
              onClick={() => {
                handleClickOnCardExpand(5)
              }}
            >
              <ChartWrapper height="350px" chartID="6" options={chartOptions[5]} chartClickEvent={chartClickEvent} />
            </Card>
          ) : (
            <Card title="Number of Works Wing Wise">
              <p>Loading</p>
            </Card>
          )}
        </CCol>
        <CCol xl={8}>
              {chartOptions[2] ? (
                <Card
                  title="Land Availability wise Number of Works"
                  onClick={() => {
                    handleClickOnCardExpand(2)
                  }}
                >
                  <ChartWrapper chartID="3" options={chartOptions[2]} chartClickEvent={chartClickEvent} chartColors="new" />
                </Card>
              ) : (
                <></>
              )}
            </CCol>
      </CRow>
          <CRow className="mb-2">
            <CCol xl={4}>
              {chartOptions[1] ? (
                <Card
                  title="Status of Works at Each Stage"
                  onClick={() => {
                    handleClickOnCardExpand(1)
                  }}
                >
                  <ChartWrapper chartID="2" options={chartOptions[1]} chartClickEvent={chartClickEvent} />
                </Card>
              ) : (
                <></>
              )}
            </CCol>
            <CCol xl={8}>
              {chartOptions[3] ? (
                <Card
                  title="Number of Not Started Works At Each Stage"
                  onClick={() => {
                    handleClickOnCardExpand(3)
                  }}
                >
                  <ChartWrapper options={chartOptions[3]} chartClickEvent={chartClickEvent} chartID="4" chartColors="new" />
                </Card>
              ) : (
                <></>
              )}
            </CCol>
            
          </CRow>

          <CRow className="mb-2">
            
            <CCol xl={4}>
              {chartOptions[4] ? (
                <Card
                  title="Status of Works In Progress"
                  onClick={() => {
                    handleClickOnCardExpand(4)
                  }}
                >
                  <ChartWrapper options={chartOptions[4]} chartClickEvent={chartClickEvent} chartID="5" chartColors="new" />
                </Card>
              ) : (
                <></>
              )}
            </CCol>

            {/* <CCol xl={6}>

   {chartOptions[3] ?
   (
    <Card title="" >
      <ChartWrapper options={chartOptions[2]} chartClickEvent={typeOfRoadChart} />
    </Card>
   )
    : (<></>)}
  </CCol> */}
          </CRow>
        </CCardBody>
      </CCard>
      {loading && <Loader />}
      {openModal && (
        <NewModal open={openModal} type={chartData.type} title={chartData.title} onClose={onModalClose}>
          <div className="container">
            <div className="row">
              <div className={chartData.type === 'pie' ? 'col-8' : 'col-10'}>
                {!checked ? (
                  modalChartLoading ? (
                    <div style={{ height: '522px' }}>
                      {/* <Skeleton height={'60px'}></Skeleton> */}
                      <Loading loading loaderColor="#3498db" />
                    </div>
                  ) : (
                    <ChartWrapper options={chartData} chartClickEvent={chartClickEvent} />
                  )
                ) : (
                  <GridView cols={gridData.cols} rows={gridData.rows} />
                )}
              </div>
              <div className={chartData.type === 'pie' ? 'col-4' : 'col-2'}>
                <div>
                  <h5 className="font-weight-bolder m-0">View Details ♒</h5>
                </div>
                <div className="d-flex align-items-center py-2">
                  <ToggleSwitch checked={checked} onChange={setChecked} />
                  <div className="ml-2">Gird View</div>
                </div>
              </div>
            </div>
          </div>
        </NewModal>
      )}
      
      {modalVisible && <Modal open={modalVisible} setVisibleFn={setModalVisible} type="table" title="Buildings Data" value="Buildings Data" data={modalData}></Modal>}
    </>
  )
}
