import React, { useState, useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderBrand, CHeaderDivider, CCard, CCardBody, CHeaderNav, CHeaderToggler, CButtonGroup, CButton, CCol, CRow, CFormCheck, CNavLink, CNavItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'
import { AppBreadcrumb } from './index'
import { useHistory } from 'react-router-dom'

import { AppHeaderDropdown } from './header/index'
import { useEffect } from 'react'
import { UserContext } from '../Contexts/UserContext'

const AppHeader = ({ isDetails, title,url }) => {
  console.log("URl",url)
  const dispatch = useDispatch()
  const history = useHistory()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const { activeSelection, setActiveSelection } = useContext(UserContext)

  const pathMap = ['wms', 'pp', 'stc']

  React.useEffect(()=>{
    getActiveNav()
  },[])
  const getActiveNav = () => {
    var path = window.location.pathname.split('/').pop()
      setActiveSelection(path)
  }



  const handleOnClick = (e) => {
    try {
      // var path = window.location.pathname.split('/home').pop()

      // if (e.target.id == 'b1') {
      //   if (path != 'home') {
      //     setActiveSelection('home')
      //     history.push('/home')
      //   }
      // } else if(e.target.id == 'b2') {
      //   setActiveSelection("buildings")
      //   if (path != 'homeBuilding') {
      //     if (pathMap.includes(path)) {
      //       const pathName = '/buildings/' + path
      //       history.push(pathName)
      //     } else if (path == 'home') {
      //       history.push('/homeBuilding')
      //       setActiveSelection('home')
      //     } else if (path == 'watch_list') {
      //       history.push('/watchlist')
      //       setActiveSelection('watch_list')
      //     } else {
      //       alert('No Buildings Data')
      //     }
      //   }
      // }else{
      //     setActiveSelection('watch_list')
      //     history.push('/watchlist')
      // }


      if (e.target.id == 'b1') {
        setActiveSelection('home')
        history.push('/home')
      } else if (e.target.id == 'b2') {
        setActiveSelection('homeBuilding')
        history.push('/homeBuilding')
      } else if (e.target.id == 'b3') {
        setActiveSelection('ALL')
        history.push('/home/ALL')
      } else if (e.target.id == 'b4'){
        setActiveSelection('watchlist')
        history.push('/watchlist')
      }
      else if (e.target.id == 'b5'){
        setActiveSelection('Map')
        console.log("MAP")
        const mapUrl = "https://gatishakti.ncog.gov.in/FindRoad/redirectapi1?access_token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJua24iLCJpYXQiOjE3MjEzNzYzNjcsImV4cCI6MTcyMTM3ODE2N30.AGEDs9Qp1F52kPDy2zUt5ElD20_9f6BnPFr8M9hubVM&workid=";
        window.open(mapUrl, '_blank', 'noreferrer')

        history.push('/home/Map')
      }
    } catch (e) {}
  }

  if (isDetails) {
    return (
      <CHeader position="sticky" style={{ marginBottom: '0px !important' }}>
        <CContainer fluid>
          <CHeaderToggler className="ps-1" onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
          {/* <CCol sx={8} style={{maxWidth: '80%'}}>
                  {title}
            </CCol> */}
          <CHeaderNav></CHeaderNav>

          <CHeaderNav className="ms-3">
            <AppHeaderDropdown />
          </CHeaderNav>
        </CContainer>
      </CHeader>
    )
  }
  return (
    <CHeader position="sticky" style={{ marginBottom: '0px !important' }}>
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        <CRow>
          <CCol>
            <CCard className=" shadow-lg bg-body " style={{ borderRadius: '10px', width: '100%', marginLeft: '10%' }}>
              <CCardBody>
                <CButtonGroup onClick={handleOnClick}>
                <CButton id="b4" color="primary" variant="outline" active={activeSelection === 'watchlist'}>
                    Watch List
                  </CButton>
                  <CButton id="b1" color="primary" variant="outline" active={activeSelection === 'home'}>
                    Road and Bridge
                  </CButton>
                  <CButton id="b2" color="primary" variant="outline" active={activeSelection === 'homeBuilding'}>
                    Buildings
                  </CButton> 
                  <CButton id="b3" color="primary" variant="outline" active={activeSelection === 'ALL'}>
                    All Data
                  </CButton>
                  <CButton id="b5" color="primary" variant="outline" active={activeSelection === 'Map'}>
                  Gujarat Road Network Map
                  </CButton>                
                </CButtonGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CHeaderNav>
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>

        <CHeaderNav className="ms-3">
          <AppHeaderDropdown  url={url}/>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
