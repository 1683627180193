import React from 'react'
import { useEffect, useState, useMemo, useRef, useCallback } from 'react'

import { CWidgetStatsA, CWidgetStatsB,CWidgetStatsD,CWidgetStatsF, CFormSwitch, CSpinner } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'

import CIcon from '@coreui/icons-react'
import { cilChartPie } from '@coreui/icons'
import Modal from '../../components/shared/Modal'
import getAllWorkData from '../../services/general/getAllWorkData.api.js'
import GetHotMixPlantData from '../../services/general/getHotMixPlantData.api.js'
import CustomAgGrid from './CustomAgGrid'
import Loader from './Loader'

const WidgetWrapper = (options) => {
  const gridRef = useRef()
  
  const allColors = {
  blue:" #0d6efd",
  indigo:" #6610f2",
  purple:" #6f42c1",
  pink:" #d63384",
  red:" #dc3545",
  orange:" #fd7e14",
  yellow:" #ffc107",
  green:" #198754",
  teal:" #20c997",
  cyan:" #0dcaf0",
  black:" #000015",
  white:" #fff",
  gray:" #8a93a2",
  primary:" #ff781e",
  secondary:" #9da5b1",
  success:" #20c997",
  info:" #39f",
  warning:" #f9b115",
  danger:" #e55353",
  light:" #ebedef",
  dark:" #4f5d73",
  }
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      hiddenByDefault: false,
    }
  }, [])
  const [title, setTitle] = useState('')
  const [loading, setloading] = useState(false)
  const [value, setValue] = useState('')

  const [color, setColor] = useState('')

  const onClose = () => {
    setVisible(false)
  }

  const [visible, setVisible] = useState(false)

  const [data, setData] = useState([])
  const handleClick = async (e) => {
    setloading(true)
    let c = []
    let workType = options.workType ? options.workType : 'Road'

    let chartData
    let loginID = options.accessCode ? options.accessCode : '2'

    if (options?.map ) {
      chartData = await getAllWorkData(loginID, options?.map, workType)
    } else {
      if (value.includes('Not')) {
      chartData = await getAllWorkData(loginID, 'Not Started', workType)
    } else if (value.includes('Progress')) {
      chartData = await getAllWorkData(loginID, 'In Progress', workType)
    } else if (value.trim() == 'Completed') {
      chartData = await getAllWorkData(loginID, 'Completed', workType)
    }else if (value.trim() == 'Phy. Completed') {
      chartData = await getAllWorkData(loginID, 'Phy. Completed', workType)
    }else if (value.trim() == 'Maintenance') {
      chartData = await getAllWorkData(loginID, 'Maintenance', workType)
    } else if (value.includes('Stopped')) {
      chartData = await getAllWorkData(loginID, 'Stopped', workType)
    } else if (value.includes('Dropped')) {
      chartData = await getAllWorkData(loginID, 'Dropped', workType)
    } else if (value.includes('Total')) {
      chartData = await getAllWorkData(loginID, 'Total', workType)
    } else if (value.includes('Plants')) {
      chartData = await GetHotMixPlantData('ALLDATA')
    }
    }
    
 
    console.log("Issusueueueuee",chartData)
    setData(chartData.d)   
    setloading(false)
    setVisible(!visible)

  }
  const fnRowClicked = (e) => {
    if (value.includes('Plants')) {
      window.open('https://drive.google.com/file/d/1xrAXhnjXB8vrQs4eMbJDs_muKK01UcmA/view?usp=share_link', '_blank', 'noreferrer')
    }
  }
 

  useEffect(() => {
    setTitle(options.title)

    setValue(options.value)

    setColor(options.color)

  }, [options])

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel()
  }, [])
  const excelStyles = useMemo(() => {
    return [
      {
        id: 'multiline',
        alignment: {
          wrapText: true,
        },
      },
    ]
  }, [])

  return (
    <>
      {options.value.includes('Plants') ? (
        <a>
          <CWidgetStatsC className="mb-3" icon={<CIcon icon={cilChartPie} height={36} />} progress={{ color: 'success', value: 75 }} text="Widget helper text" title={options.value} onClick={handleClick} value={options.title} />
        </a>
      ) : (
        <a>
          <CWidgetStatsF
            style={{ borderRadius: '20px', border:'1px solid',color:allColors[color]?allColors[color]:color + ' !important', borderColor:allColors[color]?allColors[color]:color }}
            className="mb-4 shadow"
            onClick={handleClick}
            color={color}
            value={ <>
              <span   dangerouslySetInnerHTML={{ __html: value }}></span>
            </>}
            title={
              <>
                <span  style={{ fontWeight: 'bold', fontSize: '20px' }}  >{title}</span>
              </>
            }
            
          />
        </a>
      )}
      {loading&& <Loader />}
      {visible && (
        <Modal open={visible} type="table" title={value} onClose={onClose}>
          <CustomAgGrid options={data}></CustomAgGrid>
        </Modal>
      )}
    </>
  )
}

export default React.memo(WidgetWrapper)
