import React from "react";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";

// import { CCol, CRow, CCard, CCardBody, CDropdown,CDropdownToggle ,CDropdownMenu,CDropdownItem ,
//     CCardTitle, CCardText, CCardImage, CButton,CAccordion,CAccordionBody,CAccordionHeader,CAccordionItem , CFormSelect } from '@coreui/react'
//   import { CWidgetStatsA, CWidgetStatsD } from '@coreui/react'
//   import {
//     CChartBar,
//     CChartLine,
//   } from '@coreui/react-chartjs'
  import Modal from './Modal'
  // import getAllWorkData from '../../services/general/getAllWorkData.api.js'
//   import {AgGridReact} from 'ag-grid-react';
//   import 'ag-grid-enterprise';

//   import { LicenseManager } from  'ag-grid-enterprise'
  
//   LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-2_February_2023_[v2]_MTY3NTI5NjAwMDAwMA==5019cf4ce03850168f15b7cdbd2c2378")

// //LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-21_December_2022_[v2]_MTY3MTU4MDgwMDAwMA==72993978b037cf7071dd20d6c116bd5d")


// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-material.css';
import CustomAgGrid from "./CustomAgGrid";


const ModalTable = (options) => {

  const [data,setData] = useState({})
  const onClose = () => {
    try {
      options.setVisibleFn(false)
    }
    catch (e) {

    }
    setVisible(false)
  }
  

    const [value, setValue] = useState("")
    const [visible, setVisible] = useState(false)


    const fnRowClicked = (e) => {
 
      if (options.title.includes("SCADA")) {
        window.open('https://drive.google.com/file/d/1xrAXhnjXB8vrQs4eMbJDs_muKK01UcmA/view?usp=share_link', '_blank', 'noreferrer');
     } 
    }

  useEffect(() => {
    setVisible(options.open)
    setValue(options.value)
    let chartData = options.data
    setData(chartData.d)
},[options])

    return (
      <>
      {visible && <Modal open={visible} type="table" title={value} onClose={onClose} >
        <CustomAgGrid options={data} isMapRequired={options.isMapRequired} title={options.title} saveWatchList={options.saveWatchList} >

        </CustomAgGrid>
  
    </Modal>}
      </>
    )
}

export  default ModalTable