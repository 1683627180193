import getCharts from '../getCharts'
import { HOMECHARTSDATA_Temp } from '../../constants/api.config'

export default async function GetHomeChartsDataTemp(xaxis, label, chartID = -1, filters="") {
  try {
    const payload = {loginID: '1',xaxis, label, chartID, filters }
    const res = await getCharts(HOMECHARTSDATA_Temp, payload)
    return res.data
  } catch (error) {
    return error
  }
}


