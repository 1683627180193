import { DataGrid,
   GridToolbar } from '@mui/x-data-grid';

   import {AgGridReact} from 'ag-grid-react';
   import 'ag-grid-enterprise';

   import { LicenseManager } from  'ag-grid-enterprise'

LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-21_December_2022_[v2]_MTY3MTU4MDgwMDAwMA==72993978b037cf7071dd20d6c116bd5d")
import MyInnerRenderer from '../../components/shared/MyInnerRenderer.jsx';
import { openDatabase,getDataByKey } from '../../components/shared/indexedDB.js';
import PDFExportPanel from "../../components/shared/pdfExport/PDFExportPanel.jsx";

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import { useLocation } from 'react-router-dom'

import getChartTableDetails from '../../services/general/getChartTableDetails.api.js'
import { useQuery } from 'react-query'
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';

import { Input, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { CButton, CCol, CModal, CModalBody, CModalHeader, CModalTitle, CRow,CFormLabel,CFormInput } from '@coreui/react';

window.childTableDetails = {}
export default function PrintView() {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null)
  const [columnApi, setColumnApi] = useState(null);
  const [showExtra,setShowExtra] = useState(true)
  const state = useRef({})
  const [title, setTitle] = useState("")
  const [todayDate, setTodayDate] = useState("")

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
    },
    getRowStyle: (params) => {
      if (params.node.footer) {
        return { fontWeight: 'bold' };
      }
    },
    aggFuncs:{
      "sum":customSum,
      "count":customCountAggFunc
    }
  }
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
    };
  }, []);
  const [loginID, setLoginID] = useState("")
  //gridRef.current.columnApi.setPivotMode(true);
  const history = useHistory()
 
  const [data, setData] = useState([])
 // const location = useLocation().pathname.split('/')
  const [searchString, setSearchString] = useState('')
  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enableValue: true,
      editable:true,
      wrapHeaderText: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      wrapText: true,
    autoHeight: true,
    minWidth:200
    };
  }, []);

  const [selectedFilters, setSelectedFilter] = useState([])
  const [selectedModalFilters, setSelectedModalFilter] = useState([])
  const onRemoveFilter = (fil) => setSelectedFilter(selectedFilters.filter((d) => d.name !== fil.name))
  const onRemoveModalFilter = (fil) => setSelectedModalFilter(selectedModalFilters.filter((d) => d.name !== fil.name))
  const [columns, setColumns] = useState([])
  const [tableID, setTableID] = useState(0)
  var tableIDNew = 0
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)

  const onBtPrint = useCallback(() => {
    setShowExtra(false)
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      print();
      setNormal(api);
      setShowExtra(true)
    }, 2000);
  }, [print]);
  const onBtPdf = (e) => {

    setColumnApi(gridRef.current.columnApi)
    setGridApi(gridRef.current.api)
    setVisible(true)

  }
  function currentlySelected(params, record) {
    try {
        let cols = Object.keys(record.data)
        let rowData = Object.values(record.data)
        
        let moduleID = 0
        
        window.childTableDetails = {loginID:"1", table : tableIDNew,
                                    module: moduleID, columns:JSON.stringify(cols), rows: JSON.stringify(rowData) }
        
        history.push('/childTable')

       // let temp = getChildTable("1", tableIDNew, moduleID, JSON.stringify(cols), JSON.stringify(rowData))

    } catch (e) {
        console.log(e)
    }

  }
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 300,
      cellRendererParams: {
        innerRenderer: MyInnerRenderer,
      },
    };
  }, []);
  function customCountAggFunc(params) {
    let count = params.values.length;
    let childCount = 0
    if(params?.rowNode != undefined) {
      if (params?.rowNode?.allChildrenCount != 0) {
        childCount= params?.rowNode?.allChildrenCount - count; // subtract parent count
      }
    }
    
    let newCount = count + childCount;
    
    return newCount
  }


  function customSum({values}) {
    let sum = 0;
    values.forEach(value => {
      if (value !== null && value !== undefined) {
        // Convert the value to an integer before summing
        const intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
          sum += intValue;
        }
      }
    });
    return sum;
  }


  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
  };
  const onFirstDataRendered = useCallback((params) => {
    console.log("Rendered")
    gridRef.current.api.expandAll();
    gridRef?.current?.columnApi?.autoSizeAllColumns()
    gridRef?.current?.api.setFilterModel(state?.current?.filterModel)
    gridRef?.current?.columnApi.applyColumnState({
      state: state?.current?.colState,
      applyOrder: true,
    })
  }, []);

  const formattedDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    return formattedToday
  }
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = '50rem';
    api.setDomLayout();
  };
  useEffect(() => {
    try {
      let c =[]
      console.log("in UseEffect")
      let totalData = localStorage.getItem("allPrintData")
      console.log("Total Data", totalData)
      getDataByKey(parseInt(totalData)).then((data) => {
        // Handle the retrieved data here
        console.log("Retrieved data:", data);
        let rowData = JSON.parse(data?.rowData)
        let colData = JSON.parse(data?.colData)
        let tempTitle = data?.title? data?.title : ""
        state.current.colState = data?.colState
        state.current.filterModal = data?.filterModal
        setTitle(tempTitle)
        setTodayDate(formattedDate())
        setColumns(colData)
        setData(rowData)
        setLoading(false)

      })
      .catch((error) => console.error(error));
      // let a = JSON.parse(totalData)
      // localStorage.removeItem("allPrintData");
      // // gridRef.current.api.setFilterModel(a.filterModel)
      // // gridRef.current.columnApi.applyColumnState({
      // //   state: a.colState,
      // //   applyOrder: true,
      // // })
      // console.log(a.rowData)
      // let tempTitle = a?.title? a?.title : ""
      // state.current.colState = a?.colState
      // state.current.filterModal = a?.filterModal
      // setTitle(tempTitle)
      // setTodayDate(formattedDate())
      //  setColumns(a.colData)
      //   setData(a.rowData)
      //   setLoading(false)
      //   console.log("Completed")
       
    } catch (error) {
      setData([])
      setLoading(false)
      console.log("Error",error)
    }
  }, [])
  
  if (loading) return 'Loading...'

  

  return (
    <>
    <CRow id="btnRow"  >

      <CCol>
      <CButton
             onClick={onBtPrint}
            variant="outline"
            color="primary"
            style={{ margin: '5px 5px', fontWeight: 'bold',width:'5rem',display:showExtra?'inline':'none' }}
          >
            Print
          </CButton>
          <CButton
             onClick={onBtPdf}
            variant="outline"
            color="secondary"
            style={{ margin: '5px 5px', fontWeight: 'bold',width:'5rem',display:showExtra?'inline':'none' }}
          >
            PDF
          </CButton>
          
      </CCol>
      <CCol style={{textAlign:'center'}}>
             <CFormInput value={title} onChange={(e)=> {setTitle(e.target.value)}} style={{marginTop:'5px',fontSize:'20px',border:'0px',textAlign:'center',fontWeight:'bold'}} ></CFormInput>
          </CCol>
          <CCol style={{textAlign:'center'}}>
             <CFormLabel style={{marginTop:'5px',fontSize:'20px'}} ><b>Date: {todayDate}</b></CFormLabel>
          </CCol>

    </CRow>
  
    <div id="myGrid"  className="ag-theme-material"
    style={{
      height: '50rem',
      zoom: '0.8',

    }}>
    
      <AgGridReact rowData={data}
        columnDefs={columns} 
        defaultColDef={defaultColDef}
          groupSelectsChildren={true}
          rowSelection={'multiple'}
          ref={gridRef}
          enableCharts={true}
          groupIncludeFooter={true}
          groupIncludeTotalFooter={true}
          maintainColumnOrder={true}
          gridOptions={gridOptions}
          sideBar={showExtra}
          groupMultiAutoColumn={true}
          pagination={true}
          groupSuppressBlankHeader={true}
          autoGroupColumnDef={autoGroupColumnDef}
          paginationAutoPageSize={true}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady={(params) => {
            setGridApi(params.api);
            setColumnApi(params.columnApi);
          }}
        />
    </div>
    <CModal visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader onClose={() => setVisible(false)}>
        <CModalTitle>PDF Export</CModalTitle>
      </CModalHeader>
      <CModalBody>
         <PDFExportPanel gridApi={gridApi} columnApi={columnApi}  Title={title + " - Date: " + todayDate.toString()}/>

      </CModalBody>
    </CModal>
    
    </>
    
    
    
  );
}