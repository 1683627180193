import getCharts from '../getCharts'
import { ChartTableDetails } from '../../constants/api.config'

export default async function getChartTableDetails(loginID, cID, filters, chartClickedData ) {
  try {

    if (filters=="") {
      filters = ""
    } else {
      filters = JSON.stringify(filters)
    }
    const payload = { loginID: '1', chartID: cID, filterData:filters,chartClickedData }
    const res = await getCharts(ChartTableDetails, payload)
    return res.data
  } catch (error) {
    return error
  }
}
