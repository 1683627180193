import { CCol, CRow,CSpinner,CContainer, CButton } from '@coreui/react'
import ChartWrapper from '../../components/shared/ChartWrapper'
import Modal from '../../components/shared/Modal'
import ToggleSwitch from '../../components/shared/ToggleSwitch'
import GridView from '../../components/shared/GridView'
import Card from '../../components/Card'
import { useState, useEffect,useRef } from 'react'
import getChartsWithFilter from '../../services/general/getChartsData.api.js'
import SaveData from '../../services/general/saveData.api.js'
import TransferData from '../../services/general/transferData.api.js'
import RemoveData from '../../services/general/removeData.api.js'


import ViewAllData from '../../services/general/viewAllData.api.js'
import ModalTable from '../../components/shared/ModalTable'

import { Input, Button } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import FilterDrawer from '../../components/shared/FilterDrawer'
import { Chip } from '../../components/shared/components'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { parseJsonFilter } from '../../utils/jsonFilterParser'
import { getCharts, getAllFilters } from '../../services/apicalls.js'
import getFilterData from '../../services/general/getFilterData.api.js'
import Loading from 'react-fullscreen-loading';
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import 'react-loading-skeleton/dist/skeleton.css'


window.globalChartID = ""
window.filters = ""
const RPMS = () => {
  const loginID = "1"
  const moduleID = "2"
  const tranTypeID = "106"
  const [openModal, setOpenModal] = useState(false)
  const [chartData, setChartData] = useState({})
  const [filterData, setfilterData] = useState([])
  const [isDataRefeteched, setDataRefetched] = useState(false)
  const [isViewDataRefeteched, setViewDataRefetched] = useState(false)
  const [gridData, setGridData] = useState({})
  const [checked, setChecked] = useState(false)
  const [isDrawerOpened, setDrawerOpened] = useState(false)
  const [filterValues, setFilterValues] = useState([])
  const searchCharts = useRef([])

  const [loading, setLoading] = useState(false)
  const [modalChartLoading, setModalChartLoading] = useState(false)
  const [data, setData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [searchString, setSearchString] = useState('')
  const [modalData, setModalData] = useState("")
  const [viewDataVisible, setViewDataVisible] = useState(false)


  const [chartID, setChartID] = useState("")
  const { isLoading, data: chartsData } = getCharts(loginID, moduleID, tranTypeID );
 // const { data: tempFilterData, refetch } = useQuery(['getFilterData', loginID, moduleID, tranTypeID, chartID], () => {getFilterData(loginID, moduleID, tranTypeID, chartID)}, { refetchOnWindowFocus: false, enabled: false  })


  const setChartGridData = (chart) => {
    if (chart.type == "pie") {
      const cols = [
        { title: 'Name', dataIndex: 'label', key: 'label' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
      ]
      let total = 0
      let rows = chart.labels.map((d, index) => {
        if (chart.datasets[0].data[index] == "" || chart.datasets[0].data[index] == undefined) {
          chart.datasets[0].data[index]="0"
        }
        total = total + parseInt(chart.datasets[0].data[index])
        return ({ 
          label: d, value: chart.datasets[0].data[index], key: index 
        })
      })

      let row = {
          label: "Total",
          value:total
      }
      rows.push(row)
      setGridData({ rows, cols })
    } else {
      let colsList = []
      let cols =[{ title: 'Label', dataIndex: 'label' }]
      let total = []
      for (let i = 0;i < chart.datasets.length; i++) {
          cols.push({title: chart.datasets[i].label , dataIndex: chart.datasets[i].label.toLowerCase()})
          colsList.push(chart.datasets[i].label.toLowerCase())
      }
      let data = []
      colsList.map((col, index) => {
        total[index] = 0
      })
      for (let j=0; j<chart.labels.length;j++) {
          //total[j] = 0
          let el = {}
          colsList.map((col, index) => {
            total[index] = total[index] + parseFloat(chart.datasets[index].data[j])
            el[col] = chart.datasets[index].data[j]
          })
          
          el["key"] = j
          el["label"] = chart.labels[j]
          data.push(el)
      }
      let rows = data
      let row = {}
      
     
      colsList.map((col, index) => {
        row[col] = total[index]
      })
      row["label"] = "Total"
      row["key"] = chart.labels.length
      rows.push(row)

      setGridData({ rows, cols })
    }

  }
  const handleClickOnCardExpand = (i) => {
    try{
      let chart = data[i]
      setChartData(data[i])
      setChartID(data[i].idnumber)
      window.globalChartID = data[i].idnumber

      let filterData = getFilterData(loginID, moduleID, tranTypeID, data[i].idnumber)
      filterData.then( result => {
          let temp = JSON.parse(result.d)
          let initialFilterValues = []
         
            temp.map((fdata, index) => {
              initialFilterValues[index] = fdata.Values[0]
              }
            )
          setFilterValues(initialFilterValues)
          setfilterData(temp)
          setChartGridData(chart)
          setOpenModal(true)


        }, function(error) {
        });
     
      } catch(e) {
          console.log(e)
      }
  }

  const [selectedFilters, setSelectedFilter] = useState([])
  const [selectedModalFilters, setSelectedModalFilter] = useState([])
  const onRemoveFilter = (fil) => setSelectedFilter(selectedFilters.filter((d) => d.name !== fil.name))
  const onRemoveModalFilter = (fil) => setSelectedModalFilter(selectedModalFilters.filter((d) => d.name !== fil.name))
  const filterCharts = (val) => {
    makeData(searchCharts.current.filter((c) => c.title.toLowerCase().includes(val.toLowerCase()) || c?.group?.toLowerCase().includes(val.toLowerCase())))
  }
  const handleOnSearchChange = (e) => {
    setSearchString(e.target.value)
    filterCharts(e.target.value)
  }

  const workStatusMap = {"1":"Not Started","2":"In Progress","3":"Stopped","4":"Phy. Completed","5":"Dropped"}
    

  const fetchLogin = async (wing) => {
    var data = {}
    if (wing == "NH") {
      data = {username:"cenh",password:"cenh@123"}
    } else {
       data = {username:"cestate",password:"cestate@123"}
    }
    
    const config = {
      method:'POST',
      data,
      url:'https://strobes.in/api/login',
      headers: {
        'Accept': 'Application/json'
      }
    }
    let response = await axios(config)
    let token
    try{
       token = response?.data?.data["access_token"]
    } catch (e) {

    }
    
    return token
  }

  const allStrobesData = useRef([])

  const fetchStateData = async () => {
    setDataRefetched(true)
    let wings = ["NH","State"]
    for(let j =0;j<wings.length;j++) {

      let token = await fetchLogin(wings[j])
    if (!token) {
      alert("Cannot access State WMS API, Please try again later")
      return
    }
    var date = new Date();
    date.setMonth(date.getMonth() - 1)

    const config = {
      method:'GET',
      url:'https://strobes.in/api/expanditure-data',
      headers: {
        'Authorization': `Bearer ${token}`,
        'token': 'aG4RK0GYRMER7YqcIYl8My0pWP0vIMBB',
        'Accept': 'Application/json'
      }
    }

    let response = await axios(config)
    // console.log(JSON.stringify(response))
    // console.log(response)
    // const config1 = {
    //   method:'POST',
    //   url:'https://api.strobes.in/api/workLists',
    //   headers: {
    //     'token': 'aG4RK0GYRMER7YqcIYl8My0pWP0vIMBB',
    //     'Accept': 'Application/json'
    //   }
    // }

    //  let response1 = await axios(config1)

    //  let temp1 = response1?.data?.data

    let temp2 = []
    let temp1 = response?.data?.data?.allData
    let package_id = -1
    let packages = []
    let package_name = {}
    
    for(let i=0;i<temp1.length;i++) {
        //let c = temp2[i]
        // .find(obj=>{
        //   if (obj["unique_code"] == temp1[i]["unique_code"]) {
        //     return obj
        //   }
        // })

        try {

          if (parseInt(temp1[i]["length_in_km"]) == 0 ) {

            if (temp1[i]["proposed_work_name"].toLowerCase().includes("bridge") ) {
              temp1[i]["work_type"] = "BRIDGE"
  
            } else if ( temp1[i]["proposed_work_name"].toLowerCase().includes("structure")) {
              let name = temp1[i]["name_of_work_english"].toLowerCase()
              temp1[i]["work_type"] = name.includes("bridge") || name.includes("flyover") || name.includes("causeway")?"BRIDGE":"ROAD"
  
            } else {
              temp1[i]["work_type"] = "ROAD"
  
            }

          } else {
            temp1[i]["work_type"] = "ROAD"

          }  
        } catch(e) {
          console.log("Error 1",e)
          temp1[i]["work_type"] = "ROAD"
        }

        try {
          if (temp1[i]["work_type"] == "BRIDGE") {
            let workname = temp1[i]["name_of_work_english"].toLowerCase()
            if(workname.includes("river")) {
            temp1[i]["bridge_category"] = "River Bridge"
          } else if(workname.includes("rob")||workname.includes("r.o.b")||workname.includes("road over bridge")) {
            temp1[i]["bridge_category"] = "ROB"
          }else if(workname.includes("rub")||workname.includes("road under bridge")||workname.includes("r.u.b")) {
            temp1[i]["bridge_category"] = "RUB"
          }else if(workname.includes("underpass") || workname.includes("under pass")) {
            temp1[i]["bridge_category"] = "UnderPass"
          } else {
            temp1[i]["bridge_category"] = "Flyover"

          }}
          

        } catch(e) {
          console.log("Error 2",e)
        }


        // if (c==undefined) {
        //   console.log("not found")
        // }
    //  delete temp1.data[i]["widening_cw"]
    //  delete temp1.data[i]["dlp_period_for_work_in_month"]
    //  delete temp1.data[i]["date_completion_dlp_period"]

      try {
          temp1[i]["total_expenditure"] = temp1[i]["expanditure_section"]["total_expenditure_as_on_today_on_this_project"]
          temp1[i]["spillover"] = temp1[i]["expanditure_section"]["current_spillover_of_project"]
          temp1[i]["last_fy_expense"] = temp1[i]["expanditure_section"]["expen_incured_last_fy_year"]

        try {
          if (temp1[i]["package_id"] >0 && i>0) {
            temp1[i]["isPackage"] = "yes"
            if (packages.includes(temp1[i]["package_id"]) ) {
              temp1[i]["total_expenditure"] = 0
              temp1[i]["dtp_amount"] = 0
              temp1[i]["tender_app_amount"] = 0
              temp1[i]["spillover"] = 0
                temp1[i]["last_fy_expense"] = 0
              // temp1[i]["package_id"] = temp1[i]["package_id"] + ":" + package_name[temp1[i]["name_of_work_english"]]
            } else {
              packages.push(temp1[i]["package_id"])
              //package_name[temp1[i]["package_id"]] = temp1[i]["name_of_work_english"]
              //temp1[i]["package_id"] = temp1[i]["package_id"] + ":" + package_name[temp1[i]["name_of_work_english"]]
            }
            
            
            
          }else {
            if(i==0){
              packages.push(temp1[i]["package_id"])
              temp1[i]["isPackage"] = "yes"
            } else {
              temp1[i]["isPackage"] = "no"

            }
            //temp1[i]["package_id"] = temp1[i]["package_id"] + ":" + package_name[temp1[i]["name_of_work_english"]]

          }
        } catch (e) {
          console.log("Error ---- ",e.Message)
        }

        
      } catch (e) {
        console.log(temp1)
        console.log(e)
      }
      temp1[i]["date_completion_dlp_period"] = null
      temp1[i]["electrict_line_pole_shifting_data"] = null
      temp1[i]["water_supply_storm_water_line_data"] = null
      if(temp1[i]["budget_ce_role"]=="STATE") {

        temp1[i]["Wing"] = "State Highway"
    
      } else if(temp1[i]["budget_ce_role"]=="NH") {
        temp1[i]["Wing"] = "National Highway"
      } else if(temp1[i]["budget_ce_role"]=="ALL") {
        temp1[i]["Wing"] = "National Highway"
        // let test = temp1[i]
        // test["Wing"] = "State Highway"
        // temp2.push(test)
      }

    
  //  temp1[i]["work_status_name"] = c["workstatus_name"]
    
    // workStatusMap[temp1[i]["workstatus_id"]]
      
      
      //temp1[i]["WorkID"] = "Work" + temp1[i]["id"]
      temp2.push(temp1[i])
    }

    allStrobesData.current  = allStrobesData.current.concat(temp2)



    }
    

    console.log(allStrobesData.current)
    // debugger;
    await pushStrobesData()
      
  }

  const pushStrobesData = async () => {
    await RemoveData("state","")

    let temp1 = allStrobesData.current

    for (let i=0;i<temp1.length;i++){
      let wid = "SH"
      if (temp1[i]["Wing"] == "National Highway") {
        wid = "NH"
      }
      temp1[i]["WorkID"] = wid + temp1[i]["id"]
      let d = JSON.stringify([temp1[i]])
      let a = await SaveData("state", d)
    }
   await TransferData("state","")  
  await TransferData("nh","")
    //   let a = await SaveData("state", d)

    // }  
    setDataRefetched(false)
    location.reload();
  }

  const onModalClose = () => {
    setOpenModal(false);
    setChecked(false);
    setGridData({})
    setFilterValues([])
    setSelectedModalFilter([])
  }
  function fetchData(filters) {
    setModalChartLoading(true)
    window.filters = filters
    let chartData =  getChartsWithFilter(chartID, filters)
    try {
      chartData.then( result => {
        let temp = JSON.parse(result.d)
        setChartData(temp[0])
        setChartID(temp[0].idnumber)
        setChartGridData(temp[0])
        setModalChartLoading(false)

     }, function(error) {
      setModalChartLoading(false)
      console.log(error)
     });
    } catch(err) {
        console.log("chart failed")
    }
   
  }
  
  useEffect(() => {
    try {
      if (!isLoading ) {
        let temp = JSON.parse(chartsData.d)
        temp = temp.slice(0,2)
        searchCharts.current = temp
        setData(temp)
        setOriginalData(temp)
        setLoading(false)
      } else {
        setLoading(true)
      }
    } catch (error) {
      setData([])
      setOriginalData([])
      setLoading(false)
    }
  }, [isLoading])

  const viewDataFn = () => {
    setViewDataRefetched(true)
    let a = ViewAllData("","","106","")
    a.then((res) => {
      setModalData(res)
      setViewDataVisible(true)
      setViewDataRefetched(false)
    })
  }

  const makeData = (data) => {
    const list = []
    const nonGrouplist = []
    const groups = {}
    data.forEach((d) => {
      if (d.group) {
        const grp = groups[d.group]
        if (grp) grp.charts.push(d)
        else {
          groups[d.group] = { title: d.group, type: 'group', charts: [d] }
        }
      } else nonGrouplist.push(d)
    })
    Object.keys(groups).map((key) => list.push(groups[key]))
    setData([...list, ...nonGrouplist])
  }

  if (loading) return ( 
  <CContainer fluid className="p-0" xxl>
    <div className="p-2 bg-secondary border rounded mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: '50%' }} className="rounded-lg overflow-hidden">
            <Input value={searchString} placeholder="Search here ..."  />
          </div>
        </div>
      </div>
  <CRow lg={{ gutterY: 3 }}>
       
            <CCol lg={4} >
              <Card title='Loading...'  simple>
                <CRow lg={{ gutterY: 3 }}>
                <Skeleton  height={70} count={3} >
                  </Skeleton>
                </CRow>
              </Card>
            </CCol>
            <CCol lg={4} >
              <Card title='Loading...'  simple>
                <CRow lg={{ gutterY: 3 }}>
                <Skeleton  height={70} count={3} >
                  </Skeleton>
                </CRow>
              </Card>
            </CCol>
         
      </CRow>
    
  </CContainer>) 

  return (
    <>
    <CContainer  fluid className="p-0 align-items-center justify-content-between">
      <div className="p-2 bg-secondary border rounded mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: '30%' }} className="rounded-lg overflow-hidden">
            <Input value={searchString} placeholder="Search here ..." onChange={handleOnSearchChange} />
          </div>
          <div style={{fontSize:"18px"}}>
           <b>Strobes - (NH,SH,CP)</b> 
          </div>
          <div className="d-flex "  >
          <CButton color='primary' className='m-2' variant='outline' onClick={viewDataFn}  >
          {isViewDataRefeteched ? <><CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                 Loading...</> : <>View Data</>}
            </CButton>
          <CButton color='danger' className='m-2'  variant='outline' onClick={()=>{location.href="https://strobes.in/login"}} >
            Modify Data
            </CButton>
            <CButton color='success' className='m-2' variant='outline' onClick={fetchStateData}>
            {isDataRefeteched ? <><CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                 Loading...</> : <>Refetch</>}
            </CButton>
          </div>
        </div>
         
        
        
      </div>
      <CRow lg={{ gutterY: 3 }}>
        {data.map((chart, index) =>
          chart.type === 'group' ? (
            <CCol lg={12} key={index}>
              <Card title={chart.title} simple>
                <CRow lg={{ gutterY: 3 }}>
                  {chart.charts.map((d, i) => (
                    <CCol lg={12} xl={d.type === 'pie' ? 6 : 6} key={`gc-${i}`}>
                      <Card title={d.title} onClick={() => {handleClickOnCardExpand(i)}}>
                        <ChartWrapper options={d} />
                      </Card>
                    </CCol>
                  ))}
                </CRow>
              </Card>
            </CCol>
          ) : (
            <CCol lg={12} xl={chart.type === 'pie' ? 4 : 6} key={index}>
              <Card title={chart.title} onClick={() => {handleClickOnCardExpand(index)}}>
                <ChartWrapper options={chart} />
              </Card>
            </CCol>
          ),
        )}
      </CRow>
      {openModal && <Modal open={openModal} type={chartData.type} title={chartData.title} onClose={onModalClose}>
        <div className="container" >
          <div className="row">
            <div className={chartData.type === 'pie'?'col-8':'col-10'}>{
            !checked ? ( modalChartLoading ?
             <div style={{height:'522px'}}> 
             {/* <Skeleton height={'60px'}></Skeleton> */}
             <Loading loading loaderColor="#3498db" />
             </div> :
              <ChartWrapper options={chartData} /> )
            : (<GridView cols={gridData.cols} rows={gridData.rows} />)
            }</div>
            <div className={chartData.type === 'pie'?'col-4':'col-2'}>
              <div>
                <h5 className="font-weight-bolder m-0">View Details ♒</h5>
                
                {/* <p>Description</p> */}
                <p></p>
                {/* <Link to="/details">view table ↗️</Link> */}
                <p></p>
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="font-weight-bolder m-0">Filters 📂</h5>
                    <Button onClick={() => setDrawerOpened(true)} className="ml-2 d-flex justify-content-center align-items-center" shape="circle" type="primary" icon={<FilterFilled size="small" />} />
                  </div>
                  <div className="d-flex mt-2 align-items-center flex-wrap">
                    {selectedModalFilters.map((m, i) => (
                      <Chip key={i} name={m.name} onClose={() => onRemoveModalFilter(m)} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <ToggleSwitch checked={checked} onChange={setChecked} />
                <div className="ml-2">Gird View</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>}
      {openModal && isDrawerOpened && <FilterDrawer  onClose={() => setDrawerOpened(false)} visible={isDrawerOpened} fetchDataFn={fetchData}  
       filterData={filterData}
       setFiltersFn = {setSelectedModalFilter }
        setFilterValuesFn={setFilterValues} 
       filterValuesState={filterValues} />}
       {
  viewDataVisible && <ModalTable open={viewDataVisible} setVisibleFn={setViewDataVisible} type="table"  title="View All State Data" value="View All State Data" data={modalData}></ModalTable>
      }
    </CContainer>
    
    </>
    
  )
}

export default RPMS
