import React, { useState,useEffect } from 'react'
import { CContainer, CRow, CCol, CFormLabel, CFormInput, CFormTextarea, CCard, CCardBody, CButton, CForm } from '@coreui/react'
import helper from '../components/shared/helper'
import DatePicker from '../components/shared/DatePicker'
import Customdropdown from '../components/shared/Customdropdown'

import SaveUpdateDataFn from '../services/general/saveUpdateData.api'
import { useSelector } from 'react-redux'
import moment from 'moment'

const Items = ({ label, value, required, handleChange }) => {
  if (label.includes('Name Of Work') || label.includes('NameOfWork')) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormTextarea required={required ? required:false} value={value} name={label} onChange={handleChange} />
      </CCol>
    )
  }
  if (label.includes('Date')) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <DatePicker required={required ? required:false} value={value} label={label} onChange={handleChange} />
      </CCol>
    )
  }
  if (helper.checkDropdowField(label)) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <Customdropdown required={required ? required:false} options={helper.checkDropDownData(label)} name={label} value={value} onChange={handleChange} />
      </CCol>
    )
  }
  if (helper.checkNumeric(label)) {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormInput required={required ? required:false} type="number" value={value} name={label} onChange={handleChange} />
      </CCol>
    )
  } else {
    return (
      <CCol xs={6} className="mt-2">
        <CFormLabel>{label}</CFormLabel>
        <CFormInput required={required ? required:false} disabled={label == 'WorkID' ? true : false} value={value} onChange={handleChange} name={label} />
      </CCol>
    )
  }
}


function EditDetails({ data,wing }) {
  const { selectDate } = useSelector((state) => state.common)
  const [item, setItem] = useState({})
  const [validated, setValidated] = useState(false)


  const handleSubmit = async (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      await onSave()
    }
    setValidated(true)
  }

  useEffect(()=>{
    setItem(data)
  },[data])
  const handleChange = (val, label) => {
    if (label.includes('Date')) {
      let c = { ...item }
      let selectDate = moment(val).format('DD/MM/YYYY')
      c[label] = selectDate
      setItem(c)
    } else {
      let c = { ...item }
      c[label] = val.target.value
      setItem(c)
    }
  }

  var colSize = 6
  try {
    colSize = data['Status'] ? 6 : 12
  } catch (e) {
    console.log('Error')
  }

  const onSave = async () => {
    let body = {
      module: wing,
      data: {...data,...item},
    }

    //await SaveUpdateDataFn("UPDATE","1",wing,JSON.stringify(item))
    alert(JSON.stringify(body))
  }
  return (
    <CContainer className=" bg-white shadow">
      <CRow>
        {data['Name Of Work'] && (
          <CCol>
            <CCard className="mb-3">
              <CCardBody>
                <b>{data['Name Of Work']}</b>
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </CRow>
      <CForm noValidate validated={validated}>
      <CRow>
        <CCol xs={2} className="g-0 position-relative">
          <div className="row-container">
            <button
              onClick={() => {
                setActive('info')
              }}
              className={'butn btn-blue'}
            >
              Row Info
            </button>
            <div className="btns d-flex flex-column align-items-center">
              <CButton onClick={handleSubmit}  style={{width:'100%'}} className="my-1 px-5 text-white" color="success">
                Save
              </CButton>
              <CButton className="my-1 px-5 text-white"  style={{width:'100%'}} color="warning">
                Clear
              </CButton>
              <CButton className="my-1 px-5 text-white"  style={{width:'100%'}} color="danger">
                Delete
              </CButton>
            </div>
          </div>
        </CCol>
        <CCol xs={10} className="border" style={{ height: '680px', overflow: 'overlay' }}>
       
            <CRow className="p-3">
              {Object.keys(item).map((obj, i) => {
                let label = obj
    
                return (
                  <Items
                    key={i}
                    label={label}
                    value={item[obj]}
                    required={obj?.required}
                    selectDate={selectDate}
                    handleChange={(e) => {
                      handleChange(e, label)
                    }}
                  />
                )
              })}
              <div className="d-flex"></div>
            </CRow>
          
        </CCol>
      </CRow>
      </CForm>
    </CContainer>
  )
}

export default EditDetails
