import getCharts from '../getCharts'
import { ALLWORKS } from '../../constants/api.config'

export default async function getAllWorks(loginID = 1, workType = "Road") {
  try {
    const payload = { loginID, workType }
    const res = await getCharts(ALLWORKS, payload)
    return res.data
  } catch (error) {
    return error
  }
}
