import React from 'react'
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'
import DetailsLayout from '../../layout/DetailsLayout';

export const Details_Table = () => {
    const [data, setData] = React.useState('');
    const [type, setType] = React.useState('');
    const [loading, setloading] = React.useState(true);
    const {setTable} = useSelector(state=>state.common)
    const fetchData = () => {
      setloading(true)
      let res = localStorage.getItem("details_tab")
      let type = localStorage.getItem("details_edit")
      setType(type)
      let dat = JSON.parse(res)
      setData(dat)
      setloading(false)

    }
    React.useEffect(()=>{
      fetchData();
    },[])
  return (
    <DetailsLayout  data={data} type={type} />
  )
}
