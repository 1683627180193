export const BASE_URL = 'https://margsahayak.gujarat.gov.in'
export const LOGIN = 'api/v1/client/login'
export const SERVICES = 'Services'
export const CHANGEPASSWORD ='ChangePassword'
export const SERVICES1 = 'Services1'
export const SERVICES2 = 'Services2'
export const WINGS = 'Wings'
export const DIVISION = 'Division'
export const OFFICE = 'Office'
export const CHARTS = 'GetCharts'
export const SCADA = 'Scada'
export const SAVEDATA = 'SaveData'
export const REFETCHDATA = 'RefetchData'
export const ALLWORKS = 'GetAllWorks'
export const  HOTMIX = 'GetHotMixData'
export const  MMGSYAPIDATA = 'MMGSYAPIDATA'
export const  MMGSYROADDATA = 'MMGSYROADDATA'
export const AgGridFilter="AgGridFilter"
export const AgGridFilterChart="AgGridFilterChart"
export const APILOG = "APILOG"

export const  HOTMIXDATA = 'GetHotMixPlantData'
export const ALLWORKSDATA = 'GetAllWorkData'
export const HOMECHARTS = 'GetHomeCharts'
export const HOMECHARTS_Temp = 'GetAllCharts_Temp'
export const CHARTLOGIN = 'Login'
export const COMMONCHARTS = 'GetAllCharts_Common'

export const HOMECHARTSDATA = 'GetHomeChartsData'
export const REMOVEDATA = 'RemoveData'
export const RefetchDataExternal = 'RefetchDataExternal'

export const TRANSFERDATA = 'TransferData'

export const MMGSYBRIDGEDATA = 'MMGSYBRIDGEDATA'

export const HOMECHARTSDATA_Temp = 'GetHomeChartsData_Temp'

export const CHARTSWITHFILTER = 'GetChartsWithFilter'
export const ChartTableDetails = "ChartTableDetails"
export const CHILDTABLEDETAILS = "ChildTableDetails"
export const Filters = 'GetFilters'
export const Modules = 'GetModules'
export const GETDATA = 'GetData'
export const WatchList = 'WatchList'
export const GetApiLogData = 'GetApiLogData'

export const SaveUpdateData = "SaveUpdateData"
// export const FILTER_URL = 'http://localhost:50252/webservice/Filters.asmx'

// export const CHARTS_URL = 'http://localhost:50252/webservice/Charts.asmx'

// export const DATAWAREHOUSE_URL = 'http://localhost:50252/webservice/Datawarehouse.asmx'

// export const FILTER_URL = 'http://localhost/dashboardWS/webservice/Filters.asmx'
// export const CHARTS_URL = 'http://localhost/dashboardWS/webservice/Charts.asmx'


// export const FILTER_URL = 'http://103.27.120.198/dashboardWSTest/webservice/Filters.asmx'
// export const CHARTS_URL = 'http://103.27.120.198/dashboardWSTest/webservice/Charts.asmx'
// export const DATAWAREHOUSE_URL = 'http://103.27.120.198/dashboardWSTest/webservice/Datawarehouse.asmx'

export const FILTER_URL = 'https://api.rnbdashboard.com/webservice/Filters.asmx'
export const CHARTS_URL = 'https://api.rnbdashboard.com/webservice/Charts.asmx'
export const DATAWAREHOUSE_URL = 'https://api.rnbdashboard.com/webservice/Datawarehouse.asmx'


// export const FILTER_URL = 'http://localhost/dashboardWS/webservice/Filters.asmx'

// export const CHARTS_URL = 'http://localhost/dashboardWS/webservice/Charts.asmx'

// export const DATAWAREHOUSE_URL = 'http://localhost/dashboardWS/webservice/Datawarehouse.asmx'