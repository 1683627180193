import getCharts from '../getCharts'
import { GetApiLogData } from '../../constants/api.config'

export default async function GetApiLogs(mode="",login="") {
  try {
    const payload = {mode,login}
    const res = await getCharts(GetApiLogData, payload)
    return res.data
  } catch (error) {
    return error
  }
}
