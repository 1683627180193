import React, { useEffect } from 'react'
import { CAvatar, CBadge, CDropdown, CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { cilLockLocked, cilSettings, cilTask, cilUser, cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import avatar8 from './../../assets/images/avatars/R&BD.jpg'
import { useHistory } from 'react-router-dom'
import { removeCookie } from './../../utils/handleCookie'


const AppHeaderDropdown = ({ url }) => {


  const history = useHistory()
  const logout = () => {
    removeCookie()
    history.push('/login')
  }

  const adminPanelClicked = ()=> {
    let newURL = "https://103.27.120.198/RNBnamm/login.aspx?encryptedURl=" +  url
   
    window.open(newURL, '_blank', 'noopener,noreferrer');
  }

  const changepassword = ()=>{
    history.push('/changepassword')
  }
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>

        {/* <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#">
          <CIcon icon={cilLockLocked} className="me-2" />
          Lock Account
        </CDropdownItem> */}
         <CDropdownItem onClick={adminPanelClicked}>
            
            <CIcon icon={cilSettings} className="me-2" />
              Admin Panel
            </CDropdownItem>
           <CDropdownItem onClick={changepassword}>
            
        <CIcon icon={cilLockLocked} className="me-2" />
          Change Password
        </CDropdownItem>
        <CDropdownItem onClick={logout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
