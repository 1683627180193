import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import { CButton, CFormInput, CRow, CCol, CModal, CModalHeader,CDropdown, CModalBody,CDropdownMenu,CDropdownItem,CDropdownToggle, CModalFooter, CFormLabel } from '@coreui/react'
import { LicenseManager } from 'ag-grid-enterprise'
import './AgGridStyle.css'
import { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import { DynamicForm } from './DynamicForm'
import {useHistory} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { tableInfo } from '../../views/dashboard/dummyJson/tabledatajson'
LicenseManager.setLicenseKey('For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-21_December_2022_[v2]_MTY3MTU4MDgwMDAwMA==72993978b037cf7071dd20d6c116bd5d')


export const ShowAllData = () => {
  const gridRef = useRef()
  const [datas,setDatas] = useState([])
  const [loading,setloading] = useState(true)
  const [options,setoptions] = useState([])
    React.useEffect(()=>{
        fetchData()
    },[])

    const fetchData = () => {
      setloading(true)
        let row = localStorage.getItem("show_all_data")

        let da = JSON.parse(row)
        setoptions(da)
    
        setloading(false)
    }


  const history = useHistory()
  const [modal, setModal] = useState(false)
  const {zoom} = useSelector(state => state.common)

  

  const [schemaData, setSchemaData] = useState({})
  const [gridApi, setGridApi] = useState(null)
  const toolPanel = useRef({ columns: false, filters: false })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const btnList =[]

  const [quickFilterText, setQuickFilterText] = useState('')
  const onFirstDataRendered = useCallback((params) => {
    try {
      if (params.title?.includes('Watch List')) {
        gridRef.current.api.forEachNode((node, key) => {
          if (node.data.watchlist == 1) {
            node.setSelected(true)
          }
        })
      }
    } catch (e) {}
  }, [])

  const saveOnClick = () => {
    const selectedData = gridRef.current.api.getSelectedRows()
    const selectedRowIds = selectedData.map((obj) => {
      return obj.WorkID
    })
    params.saveWatchList(selectedRowIds)
  }
  
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
      hiddenByDefault: false,
    }
  }, [])
  
  const handleButtonClick = (e) => {
    if (gridApi) {
      if (e == 'columns') {
        if (!toolPanel.current.columns) {
          gridApi.openToolPanel('columns')
          toolPanel.current.columns = true
          toolPanel.current.filters = false
        } else {
          gridApi.closeToolPanel('columns')
          toolPanel.current.columns = false
        }
      } else {
        if (!toolPanel.current.filters) {
          gridApi.openToolPanel('filters')
          toolPanel.current.columns = false
          toolPanel.current.filters = true
        } else {
          gridApi.closeToolPanel('filters')
          toolPanel.current.filters = false
        }
      }
    }
  }
  const suppressRowHoverHighlight = false
  const columnHoverHighlight = false

  const statusBar = useMemo(() => {
    return {
      statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }, { statusPanel: 'agTotalRowCountComponent', align: 'center' }, { statusPanel: 'agFilteredRowCountComponent' }, { statusPanel: 'agSelectedRowCountComponent' }, { statusPanel: 'agAggregationComponent' }],
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,

      enablePivot: true,
      flex: 1,
      minWidth: 300,
    }
  }, [])

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel()
  }, [])


  const excelStyles = useMemo(() => {
    return [
      {
        id: 'multiline',
        alignment: {
          wrapText: true,
        },
      },
    ]
  }, [])
  function onCellClicked(event) {

    if (event.data == undefined) {
      return
    }else if (event.event.target.className.includes('map-class')) {
    try {
          let workID = event.data.WorkID
          let mapLocation = `https://ris.ncog.gov.in/roadstatus/?id=${workID}`
          window.open(mapLocation, '_blank', 'noreferrer')
        } catch (e) {
          alert('Unable to redirect to Maps, Please check with Administrator')
        }
      }else  {

      localStorage.removeItem('details_tab')
      // history.push("/tabledetails",event.data)
      localStorage.setItem("details_tab",JSON.stringify(event.data))
      window.open("/tabledetails","_blank");

      localStorage.setItem("details_edit",false)

      }
  }
  let chartModel
 let currentChartRef
 const filterType = useRef("")
 const actiontype = useRef("")
 const filterName = useRef("")

 const saveFilterModalData = (e) => {
  if (actiontype.current == "save" && filterType.current == "filter") {
    window.colState = gridRef.current.columnApi.getColumnState();
    let tempName = filterType.current + ':' + filterName.current
    localStorage.setItem(tempName, JSON.stringify(window.colState));

    alert('Column State Saved');
  } else if (actiontype.current == "restore" && filterType.current == "filter") {
    let tempName = filterType.current + ':' + filterName.current
    let c = localStorage.getItem(tempName);
    let colState = JSON.parse(c)
    if (!colState) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    gridRef.current.columnApi.applyColumnState({
      state: colState,
      applyOrder: true,
    });
    console.log('column state restored');

  } else if (actiontype.current == "save" && filterType.current == "chart") {
    
    let tempName = filterType.current + ':' + filterName.current
    const chartModels = gridRef.current.api.getChartModels() || [];
    if (chartModels.length > 0) {
      localStorage.setItem(tempName, JSON.stringify(chartModels[0]));

    }
    alert('Chart Saved');

  } else if (actiontype.current == "restore" && filterType.current == "chart") {
    let tempName = filterType.current + ':' + filterName.current

    let c = localStorage.getItem(tempName);
    let chartModel = JSON.parse(c)
    if (!chartModel) return;
    currentChartRef =  gridRef.current.api.restoreChart(chartModel)

  } 
 
    setStateModal(false)
}
 const saveTableFilter = (e,type) => {
    if (e=="chart") {
      filterType.current = "chart"
        setStateModal(true)
    } else  {
      filterType.current = "filter"
        setStateModal(true)
    }

    if(type=="restore") {
      actiontype.current = "restore"
    } else {
      actiontype.current = "save"

    }
 }
  function parseDate(str) {
    const monthNames = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
    };
  
    const [month, day, year] = str.split(' ');
  
    return new Date(year, monthNames[month], day);
  }

  const [stateModal, setStateModal] = useState(false)


  const saveState = useCallback(() => {
    setStateModal(true)
    // window.colState = gridRef.current.columnApi.getColumnState();
    // localStorage.setItem('filterState', JSON.stringify(window.colState));

    // alert('Column State Saved');
  }, []);

  const restoreState = useCallback(() => {
    
  }, []);

  const resetState = useCallback(() => {
    gridRef.current.columnApi.resetColumnState();
    console.log('column state reset');
  }, []);

  const saveChart = useCallback(() => {
    

  }, []);

  const clearChart = useCallback(() => {
    if (currentChartRef) {
      currentChartRef.destroyChart();
      //currentChartRef = undefined;
    }
  }, [currentChartRef]);

  const restoreChart = useCallback(() => {
    
  }, [chartModel]);

  useEffect(() => {
   
    if (options != '') {
    
      btnList.push({ Name: 'Map' })
      let temp1 = JSON.parse(options)
      let rowGroup = ""
      
      try {
       rowGroup =  temp1?.RowGroup
       
      } catch (e) {
        
      }
     
      let temp = temp1.Data
      try {
        let c = []
        for (let j = 0; j < Object.keys(temp[0]).length; j++) {
          if (j == 0) {
            if (params.title?.includes('Watch List')) {
              c.push({
                field: Object.keys(temp[0])[j],
                headerCheckboxSelection: true,
                checkboxSelection: true,
                headerName: Object.keys(temp[0])[j],
              })
              
            }
            if (Object.keys(temp[0])[j] == "id") {
              continue;
            }
          }
            c.push({
              field: Object.keys(temp[0])[j],
              headerName: Object.keys(temp[0])[j],
              rowGroup : Object.keys(temp[0])[j] == rowGroup ? true:false,
              sort: Object.keys(temp[0])[j] == rowGroup ? 'desc':'',
              filter: 'agMultiColumnFilter',
              filterParams: {
                filters: [
                  Object.keys(temp[0])[j].toLowerCase().includes("date") && {
                    filter: 'agDateColumnFilter',
                    filterParams: {
                      buttons: ['reset'],
                      // provide comparator function
                      comparator: (filterLocalDateAtMidnight, cellValue) => {
                          const dateAsString = cellValue;
          
                          if (dateAsString == null && dateAsString == "") {
                              return 0;
                          }
                          let today  = new Date(cellValue)
                          if (isNaN(today.getTime())) {
                            today = parseDate(dateAsString)
                            if (isNaN(today.getTime())) {
                           
                              return null;
                            }
                          }
                          if (today < filterLocalDateAtMidnight) {
                              return -1;
                          } else if (today > filterLocalDateAtMidnight) {
                              return 1;
                          }
                          return 0;
                      }
                    }
                  },
                  {
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                      buttons: ['reset'],
                      //suppressAndOrCondition: true
                  },
                  },
                  {
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      buttons: ['reset'],
                      //suppressAndOrCondition: true
                  },
                  },
                  
                ],
              },
              
            })
          }
        

        btnList.forEach((element) => {
          c.push({
            field: element.Name,
            pinned: 'right',
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            suppressSizeToFit: true,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
              clicked: (field) => {
                alert(`${field} was clicked`)
              },
              label: element.Name,
            },
          })
        })

        setColumns(c)

        setData(temp)   
      

      } catch (e) {}

    }

  }, [options])


  const toggle = () => {
    setModal(!modal)
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
    },
  }


  return (<p>fdadaada</p>)
}
