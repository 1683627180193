import getCharts from '../../../services/getCharts'
import { CHANGEPASSWORD } from '../../../constants/api.config'

export default async function ChangePassword(loginID = '1',username, oldPassword,newPassword) {
  try {
    const payload = {loginID, userCode: username, oldPassword,newPassword }
    const res = await getCharts(CHANGEPASSWORD, payload)
    return res.data
  } catch (error) {
    return error
  }
}