import { CModal, CModalHeader, CModalTitle, CModalBody, CButton } from '@coreui/react'
import { useEffect } from 'react'
import CIcon from '@coreui/icons-react'
import { cilZoomIn, cilZoomOut, cilViewModule as cilViewModule } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { onZoomChange } from '../../redux/commonAction'

function Modal({ open, onClose, type, title, children }) {
  const { zoom } = useSelector((state) => state.common)
  const dispatch = useDispatch()

  const showFullData =()=>{
    window.open('/showalldata','_blank')
  }


  useEffect(() => {})
  return (
    <CModal scrollable size={type == 'pie' ? 'lg' : 'xl'} backdrop="static" keyboard={false} alignment="center" visible={open} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
        <div style={{ marginLeft: 'auto' }}>
          {/* <CButton
            onClick={()=>{showFullData()}}
            variant="outline"
            color="info"
            style={{ margin: '0 2px', fontWeight: 'bold', height: '35px' }}
          >
            <CIcon icon={cilViewModule}></CIcon>
          </CButton> */}
          <CButton
            onClick={() => {
              dispatch(onZoomChange(zoom + 0.1))
            }}
            variant="outline"
            color="info"
            style={{ margin: '0 2px', fontWeight: 'bold', height: '35px' }}
          >
            <CIcon icon={cilZoomIn} size="xl" />
          </CButton>
          <CButton
            onClick={() => {
              if (zoom > 1) {
                dispatch(onZoomChange(zoom - 0.1))
              }
            }}
            variant="outline"
            color="info"
            style={{ margin: '0 5px', fontWeight: 'bold', height: '35px' }}
          >
            <CIcon icon={cilZoomOut} size="xl" />
          </CButton>
        </div>
      </CModalHeader>
      <CModalBody>{children}</CModalBody>
    </CModal>
  )
}

export default Modal
