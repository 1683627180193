import Cookies from 'universal-cookie'

export const saveJWTinCookie = cookie => {
  const cookies = new Cookies()
  // if(localStorage.getItem("jwt_token")){

  // }else {
    localStorage.setItem('jwt_token', 'test');

  // }
  //cookies.set('jwt_token', cookie.token, { path: '/', expires: new Date(cookie.expiration) })
}

export const getJWTfromCookie = () => {
  const cookies = new Cookies()
  let a = localStorage.getItem("jwt_token")
  if (a!=null) {
    return true
  }
 // return cookies.get('jwt_token')
 return false
}

export const removeCookie = () => {
  localStorage.clear()
  }
