import getCharts from '../getCharts'
import { REFETCHDATA } from '../../constants/api.config'

export default async function RefetchNHData(loginID = '1',wing='NH', jsonData="") {
  try {
    const payload = {loginID, wing, jsonData }
    const res = await getCharts(REFETCHDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}
