import getCharts from '../getCharts'
import { APILOG } from '../../constants/api.config'

export default async function saveAPILogData(mode="",endPoint="",method="",requestBody="",responseBody="",responseCode="",applicationName="") {
  try {
    
    const payload = { mode, endPoint, method, requestBody,responseBody,responseCode,applicationName }
    const res = await getCharts(APILOG, payload)
    return res.data
  } catch (error) {
    return error
  }
}