import React from 'react'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import { CButton, CFormInput, CRow, CCol,CBadge, CModal, CModalHeader, CDropdown, CModalBody, CDropdownMenu, CDropdownItem, CDropdownToggle, CModalFooter, CFormLabel } from '@coreui/react'
import { LicenseManager } from 'ag-grid-enterprise'

import { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import { DynamicForm } from './DynamicForm'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { tableInfo } from '../../views/dashboard/dummyJson/tabledatajson'
import AgGridChartFn from '../../services/general/getAgGridFilterChart.api'
import AgGridFilterFn from '../../services/general/getAgGridFilter.api.js'
import Customdropdown from './Customdropdown'
import CustomButton from './CustomButton'
import CustomDropdown from './Customdropdown';
import { openDatabase,getAllData,addData } from './indexedDB'
import MyInnerRenderer from './MyInnerRenderer.jsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './AgGridStyle.css'

LicenseManager.setLicenseKey('For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-21_December_2022_[v2]_MTY3MTU4MDgwMDAwMA==72993978b037cf7071dd20d6c116bd5d')
  const getParams = () => ({
    processCellCallback(params) {
      const value = params.value;
      return value === undefined ? '' : `_${value}_`;
    },
    processRowGroupCallback(params) {
      const { node } = params;
      if (!node.footer) {
        return `row group: ${node.key}`;
      }
      const isRootLevel = node.level === -1;
      if (isRootLevel) {
        return 'Grand Total';
      }
      return `Sub Total (${node.key})`;
    },
  });

const CustomAgGrid = ({ options, btnList = [], rowClickedFn = () => {}, module = '', editable = false, isMapRequired = true, ...params }) => {
  const gridRef = useRef()
  const history = useHistory()
  var today = new Date();
var yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
var dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
var formattedDate = today.toLocaleDateString('en-GB', dateOptions);

  const [modal, setModal] = useState(false)
  const { zoom } = useSelector((state) => state.common)
  const [saveBtnVisible, setSaveBtnVisible] = useState(params.title?.includes('Watch List') ? false : true)
  const [isLoading, setIsLoading] = useState(false)
  const [schemaData, setSchemaData] = useState({})
  const [gridApi, setGridApi] = useState(null)
  const [columnApi, setColumnApi] = useState(null);

  const toolPanel = useRef({ columns: false, filters: false })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [type, setType] = useState()
  const [selectedType, setselectedType] = useState('')

  const [quickFilterText, setQuickFilterText] = useState('')
  const onFirstDataRendered = useCallback((params) => {
    try {
      if (params.title?.includes('Watch List')) {
        gridRef.current.api.forEachNode((node, key) => {
          if (node.data.watchlist == 1) {
            node.setSelected(true)
          }
        })
      }
    } catch (e) {}
  }, [])

  const saveOnClick = () => {
    const selectedData = gridRef.current.api.getSelectedRows()
    const selectedRowIds = selectedData.map((obj) => {
      return obj.WorkID
    })
    params.saveWatchList(selectedRowIds)
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
      hiddenByDefault: false,
    }
  }, [])

  const handleButtonClick = (e) => {
    if (gridApi) {
      if (e == 'columns') {
        if (!toolPanel.current.columns) {
          gridApi.openToolPanel('columns')
          toolPanel.current.columns = true
          toolPanel.current.filters = false
        } else {
          gridApi.closeToolPanel('columns')
          toolPanel.current.columns = false
        }
      } else {
        if (!toolPanel.current.filters) {
          gridApi.openToolPanel('filters')
          toolPanel.current.columns = false
          toolPanel.current.filters = true
        } else {
          gridApi.closeToolPanel('filters')
          toolPanel.current.filters = false
        }
      }
    }
  }
  const suppressRowHoverHighlight = false
  const columnHoverHighlight = false

  const statusBar = useMemo(() => {
    return {
      statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }, { statusPanel: 'agTotalRowCountComponent', align: 'center' }, { statusPanel: 'agFilteredRowCountComponent' }, { statusPanel: 'agSelectedRowCountComponent' }, { statusPanel: 'agAggregationComponent' }],
    }
  }, [])
  function customSum({values}) {
    let sum = 0;
    values.forEach(value => {
      if (value !== null && value !== undefined) {
        // Convert the value to an integer before summing
        const intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
          sum += intValue;
        }
      }
    });
    return sum;
  }

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 300,
      cellRendererParams: {
        innerRenderer: MyInnerRenderer,
      },
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      enablePivot: true,
      flex: 1,
      minWidth: 200,
    }
  }, [])

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel()
  }, [])

  const excelStyles = useMemo(() => {
    return [
      {
        id: 'multiline',
        alignment: {
          wrapText: true,
        },
      },
    ]
  }, [])

  const getMapData = async (workid) => {
    let allHeaders = {
      "Content-Type": "application/json"

    }
    
    let url = "https://gatishakti.ncog.gov.in/FindRoad/auth/redirectapi"
    const rawBody = JSON.stringify({
      "workid": workid    });
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: allHeaders,
        body: rawBody,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("-------------------")
      console.log(data)
      let mapLocation = data?.redirectionUrl
      window.open(mapLocation, '_blank', 'noreferrer')

    } catch (error) {
      console.log("Error")
      console.log(error)
    }
    

  }

  const onCellClicked = async (event)=> {
    if (event.data == undefined) {
      return
    } else if (event.event.target.className.includes('map-class')) {
      try {
        let workID = event.data.WorkID
        console.log("workID", workID)
        let responseData = await getMapData(workID)

        // let mapLocation = `https://ris.ncog.gov.in/roadstatus/?id=${workID}`
        // window.open(mapLocation, '_blank', 'noreferrer')
      } catch (e) {
        alert('Unable to redirect to Maps, Please check with Administrator')
      }
    } else {
      localStorage.removeItem('details_tab')
      // history.push("/tabledetails",event.data)
      localStorage.setItem('details_tab', JSON.stringify(event.data))
      window.open('/tabledetails', '_blank')

      localStorage.setItem('details_edit', false)
    }
  }
  let chartModel
  let currentChartRef

  const filterType = useRef('')
  const actiontype = useRef('')
  const filterName = useRef('')

  const filterDropDownData = useRef([])
  const chartDropDownData = useRef([])

  const agGridFilterSave = async (name, value, loginID = '1') => {
    await AgGridFilterFn('INSERT', name, value, loginID)
  }

  const agGridChartSave = async (name, value, loginID = '1') => {
    await AgGridChartFn('INSERT', name, value, loginID)
  }

  const agGridFilterGET = async (loginID = '1') => {
    let resp = await AgGridFilterFn('SELECT', '', '', loginID)
    return JSON.parse(resp.d)
  }
  const agGridChartGET = async (loginID = '1') => {
    let resp = await AgGridChartFn('SELECT', '', '', loginID)
    return JSON.parse(resp.d)
  }

  const defaultExcelExportParams = useMemo(() => {
    return getParams();
  }, []);

  const saveFilterModalData = async (e) => {
    setIsLoading(true)
    if (actiontype.current == 'save' && filterType.current == 'filter') {
      let colState = gridRef.current.columnApi.getColumnState()
      let filterModel = gridRef.current.api.getFilterModel();
      let data = {
        "colState":colState,
        "filterModel":filterModel
      }


      let tempName = filterName.current

      await agGridFilterSave(tempName, JSON.stringify(data), '1')
      setIsLoading(false)
      alert('Column State Saved')
    } else if (actiontype.current == 'restore_filter' && filterType.current == 'filter') {
      let newData = filterDropDownData.current
      let selectedName = selectedType
      if (selectedName == "") {
        selectedName = dropdown[0].value
      }
      let da = newData.filter((i) => {

        if (i.name === selectedName) {
          return i
        }
      })
      let newcolState = da
      let data = JSON.parse(newcolState[0].value)
      let colState = data["colState"]
      let filterModel = data["filterModel"]
      if (!colState) {
        console.log('no columns state to restore by, you must save state first')
        return
      }

      if (!filterModel) {
        console.log('no columns state to restore by, you must save state first')
        return
      }
      gridRef.current.api.setFilterModel(filterModel)
      gridRef.current.columnApi.applyColumnState({
        state: colState,
        applyOrder: true,
      })
      setIsLoading(false)
      console.log('column state restored')
    } else if (actiontype.current == 'save' && filterType.current == 'chart') {
      const chartModels = gridRef.current.api.getChartModels() || [];
      let tempName = filterName.current

      await agGridChartSave(tempName, JSON.stringify(chartModels[0]), '1')
      setIsLoading(false)
      alert('Chart Saved')
    } else if (actiontype.current == 'restore_chart' && filterType.current == 'chart') {
      let newData = chartDropDownData.current
      let selectedName = selectedType
      if (selectedName == "") {
        selectedName = dropdown[0].value
      }

      let da = newData.filter((i) => {

        if (i.name === selectedName) {
          return i
        }
      })

      let chartModel = da

      chartModel = JSON.parse(chartModel[0].value)
      console.log(chartModel)

      if (!chartModel) return

      currentChartRef = gridRef.current.api.restoreChart(chartModel)
    
    }
    setIsLoading(false)
    setStateModal(false)
  }
  const clearChart = useCallback(() => {
    if (currentChartRef) {
      currentChartRef.destroyChart();
      //currentChartRef = undefined;
    }
  }, [currentChartRef]);

  const saveTableFilter = async (e, type) => {
   
    setType(type)
    if (type == 'restore_filter') {
      actiontype.current = 'restore_filter'
      let resp = await agGridFilterGET()
      filterDropDownData.current = resp.Data
      let drop = []
      resp.Data.map((i) => {
        drop.push({
          lable: i.name,
          value: i.name,
        })
      })
      setDropdown(drop)
    } else if (type == 'restore_chart') {
      actiontype.current = 'restore_chart'
      let resp = await agGridChartGET()
      chartDropDownData.current = resp.Data
      let drop = []
      resp.Data.map((i) => {
        drop.push({
          lable: i.name,
          value: i.name,
        })
      })
      setDropdown(drop)
    } else {
      actiontype.current = 'save'
    }
    if (e == 'chart') {
      filterType.current = 'chart'
      setStateModal(true)
    } else {
      filterType.current = 'filter'
      setStateModal(true)
    }
  }

  function parseDate(str) {
    const monthNames = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    }

    const [month, day, year] = str.split(' ')

    return new Date(year, monthNames[month], day)
  }

  const [stateModal, setStateModal] = useState(false)

  const saveState = useCallback(() => {
    setStateModal(true)
    // window.colState = gridRef.current.columnApi.getColumnState();
    // localStorage.setItem('filterState', JSON.stringify(window.colState));

    // alert('Column State Saved');
  }, [])

  const restoreState = useCallback(() => {}, [])

  const resetState = useCallback(() => {
    gridRef.current.columnApi.resetColumnState()
    console.log('column state reset')
  }, [])



  const saveChart = useCallback(() => {}, [])

  const restoreChart = useCallback(() => {}, [chartModel])

  useEffect(() => {
    if (options != '') {
      if (isMapRequired) {
        btnList.push({ Name: 'Map' })
      }

      let temp1 = JSON.parse(options)
      let rowGroup = ''

      try {
        rowGroup = temp1?.RowGroup
      } catch (e) {}

      let temp = temp1.Data
      try {
        let c = []
        for (let j = 0; j < Object.keys(temp[0]).length; j++) {
          if (j == 0) {
            if (params.title?.includes('Watch List')) {
              c.push({
                field: Object.keys(temp[0])[j],
                headerCheckboxSelection: true,
                checkboxSelection: true,
                headerName: Object.keys(temp[0])[j],
              })
            }
            if (Object.keys(temp[0])[j] == 'id') {
              continue
            }
          }
          c.push({
            field: Object.keys(temp[0])[j],
            headerName: Object.keys(temp[0])[j],
            rowGroup: Object.keys(temp[0])[j] == rowGroup ? true : false,
            enablePivot:true,
            enableRowGroup:true,
            sort: Object.keys(temp[0])[j] == rowGroup ? 'desc' : '',
            filter: 'agMultiColumnFilter',
            filterParams: {
              filters: [
                {
                  filter: 'agNumberColumnFilter',
                  filterParams: {
                    buttons: ['reset'],
                    //suppressAndOrCondition: true
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    buttons: ['reset'],
                    //suppressAndOrCondition: true
                  },
                },
                {
                  filter: 'agDateColumnFilter',
                  filterParams: {
                    buttons: ['reset'],
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                      const dateAsString = cellValue

                      if (dateAsString == null && dateAsString == '') {
                        return 0
                      }
                      let today = new Date(cellValue)
                      
                      if (isNaN(today.getTime())) {
                        today = parseDate(dateAsString)
                        if (isNaN(today.getTime())) {
                          return null
                        }
                      }
                      if (today < filterLocalDateAtMidnight) {
                        return -1
                      } else if (today > filterLocalDateAtMidnight) {
                        return 1
                      }
                      return 0
                    },
                  },
                },
              ],
            },
          })
        }

        btnList.forEach((element) => {
          c.push({
            field: element.Name,
            pinned: 'right',
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            suppressSizeToFit: true,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
              clicked: (field) => {
                alert(`${field} was clicked`)
              },
              label: element.Name,
            },
          })
        })

        setColumns(c)
        setData(temp)

      } catch (e) {}
    }
  }, [options])

  const toggle = () => {
    setModal(!modal)
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
    },
    getRowStyle: (params) => {
      if (params.node.footer) {
        return { fontWeight: 'bold' };
      }
    },
    aggFuncs:{
      "sum":customSum,
      "count":customCountAggFunc
    }
  }

  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#gridID');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#gridID');
    eGridDiv.style.width = '700px';
    eGridDiv.style.height = '200px';
    api.setDomLayout();
  };

  const onBtPrint = async (e) => {

    let title = params?.title != "" ? params?.title : "Work Details"

    let todaysDate = new Date()

    setColumnApi(gridRef.current.columnApi)
    setGridApi(gridRef.current.api)

    let rowData = [];
  gridRef.current.api.forEachNodeAfterFilterAndSort(node => {
    if(node.data) {
      rowData.push(node.data)
    }

  } );


  let colState = gridRef.current.columnApi.getColumnState()
  let filterModel = gridRef.current.api.getFilterModel();
  

  let allData = {
    rowData:JSON.stringify(rowData),
    colData:JSON.stringify(columns),
    title:title,
    todaysDate,
    colState,
    filterModel

  }


  addData(allData)
    .then((id) => {
      console.log(id)
      localStorage.setItem("allPrintData",id)
      window.open('/print')

    })
    .catch((error) => console.error(error))

 // 
  //return rowData;
    
  }

  function customCountAggFunc(params) {
    let count = params.values.length;
    let childCount = 0
    if(params?.rowNode != undefined) {
      if (params?.rowNode?.allChildrenCount != 0) {
        childCount= params?.rowNode?.allChildrenCount - count; // subtract parent count
      }
    }
    
    let newCount = count + childCount;
    
    return newCount
  }

  // function customCountAggFunc(params) {
  //   let count = params.values.length;
  //   let childCount = 0;
  //   try {
  //     if (params?.rowNode?.rowIndex == null) {
  //       count = 0
  //       console.log(parseInt(obj))
  //       params.values.map(obj=>{
  //         count = count + parseInt(obj)
  //       })
  //     }

  //   } catch (e) {
  //       console.log("failed")
  //   }
    
  //   // params.childrenAfterFilter.forEach(childNode => {
  //   //   childCount += childNode.group ? childNode.allChildrenCount : 1;
  //   // });
  //   return count; // return the total count of all rows
  // }
  
  return (
    <>
      <CRow>
        <CCol xs={3}>
          <CFormInput type="text" placeholder="Search" onChange={(e) => setQuickFilterText(e.target.value)} />
        </CCol>
        <CCol>
          <CButton
            onClick={(e) => {
              handleButtonClick('columns')
            }}
            variant="outline"
            color="primary"
            style={{ margin: '5px 0px', fontWeight: 'bold' }}
          >
            Show/hide Columns
          </CButton>
          <CButton
            onClick={(e) => {
              handleButtonClick('filters')
            }}
            variant="outline"
            color="warning"
            style={{ margin: '5px 5px', fontWeight: 'bold' }}
          >
            Filter
          </CButton>

          <CButton onClick={onBtExport} variant="outline" color="success" style={{ margin: '5px', fontWeight: 'bold' }}>
            Export to Excel
          </CButton>
          <CButton onClick={onBtPrint} variant="outline" color="success" style={{ margin: '5px', fontWeight: 'bold' }}>
            Print
          </CButton>
          <CButton variant="outline" color="secondary" style={{ margin: '5px', display: params.title?.includes('Watch List') ? 'inline' : 'none', fontWeight: 'bold' }} onClick={saveOnClick}>
            Save WatchList
          </CButton>
           <CDropdown>
            <CDropdownToggle style={{ margin: '5px 5px', fontWeight: 'bold' }} color="info" variant="outline">
              Save/Restore
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem
                onClick={(e) => {
                  saveTableFilter('filter', 'save')
                }}
              >
                Save Filter
              </CDropdownItem>
              <CDropdownItem
                onClick={(e) => {
                  saveTableFilter('filter', 'restore_filter')
                }}
              >
                Restore Filter
              </CDropdownItem>
              <CDropdownItem
                onClick={(e) => {
                  saveTableFilter('chart', 'save')
                }}
              >
                Save Chart
              </CDropdownItem>
              <CDropdownItem
                onClick={(e) => {
                  saveTableFilter('chart', 'restore_chart')
                }}
              >
                Restore Chart
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown> 
          <b><CBadge style={{height:'35px',fontSize:'15px',marginBottom:'-10px',padding:'10px'}} color="primary">{"Last Sync Date:" + formattedDate.toString()}</CBadge></b>

        </CCol>
        
      </CRow>

      <div
      id="gridID"
        className="ag-theme-material"
        style={{
          height: '38rem',
          zoom: '0.9',
        }}
      >
        <AgGridReact
          rowData={data}
          columnDefs={columns}
          suppressRowHoverHighlight={suppressRowHoverHighlight}
          defaultColDef={defaultColDef}
          onCellClicked={onCellClicked}
          target="_blank"
          pagination={true}
          paginationAutoPageSize={true}
          enableCharts={true}
          autoGroupColumnDef={autoGroupColumnDef}
          groupIncludeFooter={true}
          groupIncludeTotalFooter={true}
          statusBar={statusBar}
          quickFilterText={quickFilterText}
          columnHoverHighlight={columnHoverHighlight}
          groupSelectsChildren={true}
          onFirstDataRendered={onFirstDataRendered}
          rowSelection={'multiple'}
          rowGroupPanelShow={'always'}
          columnGroupPanelShow={'always'}
          enableRangeSelection={'true'}
          suppressAutoSize={false}
          ref={gridRef}
          excelStyles={excelStyles}
          gridOptions={gridOptions}
          pivotPanelShow={'always'}
          animateRows={true}
          onGridReady={(params) => {
            setGridApi(params.api);
            setColumnApi(params.columnApi);
          }}
        />
      </div>

      {stateModal && (
        <CModal
          className="restore_filters"
          size="sm"
          visible={stateModal}
          onClose={() => {
            setStateModal(false)
            setselectedType('')
          }}
        >
  
          <CModalHeader onClose={() => stateModal(false)}>Save</CModalHeader>
          <CModalBody>
            <CFormLabel>Filter/Chart Name</CFormLabel>

            {type === 'restore_filter' || type === 'restore_chart' ? (
             <CustomDropdown options={dropdown} value={selectedType}   onChange={(text) => {console.log("text",text.target)
              setselectedType(text.target.value)
            }} />):<CFormInput
                onChange={(e) => {
                  filterName.current = e?.target?.value
                }}
              ></CFormInput>}
          </CModalBody>
          <CModalFooter>
            <CustomButton title="Close" onClick={() => setModal(false)} color="secondary" />
            <CustomButton title="Done" onClick={saveFilterModalData} isLoading={isLoading} color="primary" />
                
          </CModalFooter>
        </CModal>
      )}

      {modal && (
        <CModal size="xl" visible={modal} onClose={toggle}>
          <CModalHeader closeButton>Details</CModalHeader>
          <CModalBody>
            <DynamicForm data={schemaData}></DynamicForm>
          </CModalBody>
          <CModalFooter></CModalFooter>
        </CModal>
      )}
    </>
  )
}

const BtnCellRenderer = (data) => {
  useEffect(() => {}, [])
  return (
    <>
      <CButton variant="outline" className="map-class" color={'success'}>
        {data.label}
      </CButton>
    </>
  )
}

export default CustomAgGrid