import getCharts from '../getCharts'
import { REMOVEDATA } from '../../constants/api.config'

export default async function RemoveData(wing="state", jsonData) {
  try {
    const payload = {wing, jsonData }
    const res = await getCharts(REMOVEDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}
