import React , { useState }  from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useContext } from 'react'
import { UsernameContext } from './UsernameContext'
import ChangePasswrd from './PasswordChange.api.js'

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false); // State for loader
  let username = localStorage.getItem('loggedInUsername')
  let userData = null

  try{
    userData = JSON.parse(localStorage.getItem('loggedInUserData'))

  } catch (e) {
}
  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const validatePassword = async (e) => {
    // Implement your password validation logic here

    if (oldPassword == "") {
      alert("Old password field is required");
      return;
    }

    if (newPassword == "") {
      alert("New password field is required");
      return;
    }

    if (repeatPassword == "") {
      alert("Repeat password field is required");
      return;
    }

    if (newPassword !== repeatPassword) {
      alert("New password and repeat password don't match.");
      return;
    }
    setLoading(true);
    if (userData) {
      let callChangePassword = await ChangePasswrd('',userData.UserID,oldPassword,newPassword)
    
      try {
          let changePassword_obj = callChangePassword.d
          console.log(changePassword_obj)
          if(changePassword_obj.Code) {
            setTimeout(() => {
              setOldPassword('');
            setNewPassword('');
            setRepeatPassword('');
            setLoading(false); // Stop loading state after action completes
            alert(changePassword_obj.Name)

            }, 2000);
            
          } else {
            setTimeout(() => {
            setLoading(false); // Stop loading state after action completes
            alert(changePassword_obj.Name)
          }, 2000);
          }
      } catch (e) {
        console.log(e)
        setTimeout(() => {
        setLoading(false); // Stop loading state after action completes
        alert(e.Message)
      }, 2000);
      }
     

    }

    
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <h1>Change Password</h1>
                  <p className="text-medium-emphasis">Change your existing password</p>

                  <div>
                    <h5>
                      <CIcon icon={cilUser} /> User: {username}
                    </h5>
                  </div>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Old Password"
                      autoComplete="new-password"
                      value={oldPassword}
                      onChange={handleOldPasswordChange}
                    />
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="New Password"
                      autoComplete="new-password"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                    />
                  </CInputGroup>

                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Repeat New password"
                      autoComplete="new-password"
                      value={repeatPassword}
                      onChange={handleRepeatPasswordChange}
                    />
                  </CInputGroup>

                  <div className="d-grid">
                  <CButton color="success"  onClick={validatePassword}>
                      {loading ? (
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      ) : (
                        'Confirm'
                      )}
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ChangePassword