import getCharts from '../getCharts'
import { ALLWORKSDATA } from '../../constants/api.config'

export default async function getAllWorkData(loginID=1,mode, workType="Road") {
  try {
    const payload = { loginID, mode, workType}
    const res = await getCharts(ALLWORKSDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}
