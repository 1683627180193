

import React, { useState } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';




function DatePicker(props) {
 
    const [ value, setValue ] = useState( props.value);

    // const handleChange = ( newValue ) => {
    //     console.log("newValue: ", newValue );
    //     setValue(newValue);
    //     // props.setDate( newValue );
    //     dispatch(onSelectDateChange(newValue))
    //     props.onChange();
        
    // };

   
    const handleDateChange = date => {
        if (props.onChange) {
            setValue(date)
          props.onChange( date,props.label);
          
        }
    };

  return (
    <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    value={ value }
                    onChange={ handleDateChange }
                    renderInput={(params) => <TextField {...params} required/>}
                />
            </Stack>
        </LocalizationProvider>
    </>
  );
}

export default DatePicker;