import getCharts from '../getCharts'
import { MMGSYBRIDGEDATA } from '../../constants/api.config'


export default async function MMGSYFetchBridgeData(login="1") {
  try {
    var res = ""
  
      const payload = {login }

      res = await getCharts(MMGSYBRIDGEDATA, payload)

   
    return res.data
  } catch (error) {
    return error
  }
}
