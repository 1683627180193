import React, { useReducer } from 'react'
import { CCol, CRow, CCard, CCardBody, CFormInput , CSpinner,CDropdown,CDropdownToggle ,CDropdownMenu,CDropdownItem ,CButtonGroup,
  CCardTitle, CCardText, CCardImage, CButton,CAccordion,CAccordionBody,CAccordionHeader,CAccordionItem , CFormSelect, CCardHeader, CContainer } from '@coreui/react'
import { CWidgetStatsA, CWidgetStatsD, CFormSwitch  } from '@coreui/react'
import ChartWrapper from '../../components/shared/HomeChartWrapper'
import Modal from '../../components/shared/ModalTable'
import Loading from 'react-fullscreen-loading';
import NewModal from '../../components/shared/Modal'
import {
  CChartBar,
  CChartLine,
} from '@coreui/react-chartjs'

import { getAllHomeBuildingsCharts, getAllFilters, getAllWorksBuilding } from '../../services/apicalls.js'
import getAllHomeCharts  from '../../services/general/getAllHomeCharts.api.js'

import GetHomeChartsData  from '../../services/general/getHomeChartsData.api.js'


import { useEffect, useRef } from 'react'
import {  useParams } from 'react-router-dom';
import Card from '../../components/Card'

import { useState } from 'react'
import { useCallback } from 'react'

import Loader from '../../components/shared/Loader'

const Departments = ['ALL','Education',
  'Labour and employment',
  "Sports Y. & Culture",
  "Legal",
  "Tribal Development",
  "Agriculture",
  "Industry & Mining",
  "Finance",
  "Animal husbandry",
  "R & B",
  "Ports & Transport",
  "Revenue"]

  const Wings = ['ALL','State Highway', 'Policy and Planning','STC']

  const WingMap = {
    "wms":"State Highway",
    "pp":"Policy and Planning",
    "stc":"STC"
  }
export const Buildings = () => {
  const loginID = "2"
  const moduleID = "-3"
  const tranTypeID = "-3"
  const [checked, setChecked] = useState(false)
  const routeParams = useParams();

  const [modalChartLoading, setModalChartLoading] = useState(false)

  const [isloading,setIsLoading] = useState(false)

 // const [allStatus, setAllStatus] = useState(["Loading...","Loading...","Loading...","Loading...","Loading...","Loading..."])

  const [modalVisible, setModalVisible] = useState(false)

  const [modalData, setModalData] = useState("")

  const selectedDepartment = useRef("ALL")
  
  const pageTitle = useRef("")
  const selectedWing = useRef("ALL")
  const [openModal, setOpenModal] = useState(false)
  const [chartData, setChartData] = useState({})
 const { isLoading, data: chartsData } = getAllHomeBuildingsCharts(loginID, moduleID, tranTypeID,"ALL",WingMap[routeParams["wing"]] );

 // const { isLoading:isLoadingWorkData, data: workData } = getAllWorksBuilding(loginID);

  const [lengthChartData, setLengthChartData] = useState([])
  const [isDataRefeteched, setDataRefetched] = useState(false)

  const [chartOptions, setChartOptions] = useState([])
  const [isViewDataRefeteched, setViewDataRefetched] = useState(false)
  const viewDataFn = () => {
    // setViewDataRefetched(true)
    // let a = ViewAllData("","","106","")
    // a.then((res) => {
    //   setModalData(res)
    //   setViewDataVisible(true)
    //   setViewDataRefetched(false)
    // })
  }
  const chartClickEvent = useCallback(async (databaseName, label, chartID)=> {
    setIsLoading(true)
    let modalData = await GetHomeChartsData(databaseName,label, chartID, selectedDepartment.current,selectedWing.current)

    setModalData(modalData)
    setModalVisible(true)
    setIsLoading(false)

  }, [chartsData])




  const onModalClose = () => {
    setOpenModal(false);
  }


  const handleClickOnCardExpand = (i) => {
    try{

      } catch(e) {
          console.log(e)
      }
  }

  const handleOnDepartmentChange =async (e) => {
    selectedDepartment.current = Departments[e.target.value]
    let chartsData = await getAllHomeCharts(loginID, moduleID, tranTypeID, Departments[e.target.value], selectedWing.current)
    parseChartData(chartsData)
  }

  const handleOnWingChange =async (e) => {
    selectedWing.current = Wings[e.target.value]
    let chartsData = await getAllHomeCharts(loginID, moduleID, tranTypeID, selectedDepartment.current,Wings[e.target.value])
    parseChartData(chartsData)
  }

  const parseChartData = (chartData) => {
    try{
      setAllStatus(workData.d.split(","))
    } catch {

    }

    let temp = JSON.parse(chartData.d)

    setChartOptions(temp)
  }

  useEffect(()=> {
    if(!isLoading ) {
      pageTitle.current = WingMap[routeParams["wing"]]
      parseChartData(chartsData)
    }
  },[isLoading])
    return (
        <>
        <div className="p-2 bg-secondary border rounded mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: '30%' }} className="rounded-lg overflow-hidden">
            <CFormInput  placeholder="Search here ..."  />
          </div>
          <div style={{fontSize:"18px"}}>
           <b>{pageTitle.current}</b> 
          </div>
          <div className="d-flex "  >
          <CButton color='primary' className='m-2' variant='outline' onClick={viewDataFn}  >
          {isViewDataRefeteched ? <><CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                 Loading...</> : <>View Data</>}
            </CButton>
          <CButton color='danger' className='m-2'  variant='outline'  >
            Modify Data
            </CButton>
            <CButton color='success' className='m-2' variant='outline' >
            {isDataRefeteched ? <><CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                 Loading...</> : <>Refetch</>}
            </CButton>
          </div>
        </div>
         
        
        
      </div>
        

{/*
<CRow >

  <CCol sm={2}  >
    <WidgetWrapper title={allStatus[0]} accessCode="2" workType="Building" value="Not Started" color="danger"></WidgetWrapper>
  </CCol>
  <CCol sm={2}>
  <WidgetWrapper title={allStatus[1]} accessCode="2" workType="Building" value="In Progress" color="warning"></WidgetWrapper>
  </CCol>
  <CCol sm={2}>
  <WidgetWrapper title={allStatus[2]} accessCode="2" workType="Building" value="Completed" color="success"></WidgetWrapper>

  </CCol>
  <CCol sm={2}>
    <WidgetWrapper title={allStatus[3]} accessCode="2" workType="Building" value="Stopped" color="primary"></WidgetWrapper>
  </CCol>
  <CCol sm={2}>
    <WidgetWrapper title={allStatus[4]} accessCode="2" workType="Building" value="Dropped" color="danger"></WidgetWrapper>
  </CCol>
  <CCol sm={2}>
    <WidgetWrapper title={allStatus[5]} accessCode="2" workType="Building" value="Total" color="info"></WidgetWrapper>
  </CCol>


</CRow>

<CRow  className='mb-2' >

  <CCol  xs={12} >

   {chartOptions[0] ?
   (
    <Card title="Number of Works Department Wise" onClick={() => {handleClickOnCardExpand(0)}} >
      <ChartWrapper height="350px" chartID="1" options={chartOptions[0]} chartClickEvent={chartClickEvent} />
    </Card>
   )
    : (<Card title="Number of Works Department Wise" >
    <p>Loading</p>
  </Card>)}
  </CCol>




</CRow>
<CRow  className='mb-2' >
  <CCol  xs={4} >

   {chartOptions[5] ?
   (
    <Card title="Number of Works Wing Wise" onClick={() => {handleClickOnCardExpand(5)}} >
      <ChartWrapper height="350px" chartID="6" options={chartOptions[5]} chartClickEvent={chartClickEvent} />
    </Card>
   )
    : (<Card title="Number of Works Wing Wise" >
    <p>Loading</p>
  </Card>)}
  </CCol>




</CRow>   */}
<CCard>
  {/* <CCardHeader>
    <CRow >
      <CCol xs={8}>
      Buildings Data

      </CCol>
      <CCol xs={2}>
     <CRow>
      <CCol xs={6}>
Department:
      </CCol>
      <CCol xs={6}>
      <CFormSelect
    onChange={handleOnDepartmentChange}
  aria-label="Default select example"
  options={
    Departments.map((e,i) => {
      return {'label':e, 'value':i}
    })
  }
/>
      </CCol>
      </CRow>
      </CCol>
      <CCol xs={2}>
      <CRow>
      <CCol xs={4}>
Wing:
      </CCol>
      <CCol xs={8}>
      <CFormSelect
      onChange={handleOnWingChange}
  aria-label="Default select example"
  options={
    Wings.map((e,i) => {
      return {'label':e, 'value':i}
    })
  }
/>
      </CCol>
      </CRow>
      </CCol>
    </CRow>
  </CCardHeader> */}
  <CCardBody style={{backgroundColor:"#ebedef"}}>

<CRow className='mb-2'>
<CCol xl={4}>

{chartOptions[1] ?
(
 <Card title="Status of Works at Each Stage"  onClick={() => {handleClickOnCardExpand(1)}}>
   <ChartWrapper chartID='2' options={chartOptions[1]} chartClickEvent={chartClickEvent}  />
 </Card>
)
 : (<></>)}
</CCol>

  <CCol xl={8}>

   {chartOptions[2] ?
   (
    <Card title="Land Availability wise Number of Works" onClick={() => {handleClickOnCardExpand(2)}} >
      <ChartWrapper chartID='3' options={chartOptions[2]} chartClickEvent={chartClickEvent} chartColors="new" />
    </Card>
   )
    : (<></>)}
  </CCol>

</CRow>

<CRow className='mb-2'>
<CCol xl={8}>

{chartOptions[3] ?
(
 <Card title="Number of Not Started Works At Each Stage"  onClick={() => {handleClickOnCardExpand(3)}}>
   <ChartWrapper options={chartOptions[3]} chartClickEvent={chartClickEvent} chartID="4" chartColors="new" />
 </Card>
)
 : (<></>)}
</CCol>
<CCol xl={4}>

{chartOptions[4] ?
(
 <Card title="Status of Works In Progress" onClick={() => {handleClickOnCardExpand(4)}}>
   <ChartWrapper options={chartOptions[4]} chartClickEvent={chartClickEvent} chartID="5" chartColors="new" />
 </Card>
)
 : (<></>)}
</CCol>

  {/* <CCol xl={6}>

   {chartOptions[3] ?
   (
    <Card title="" >
      <ChartWrapper options={chartOptions[2]} chartClickEvent={typeOfRoadChart} />
    </Card>
   )
    : (<></>)}
  </CCol> */}

</CRow>
  </CCardBody>
</CCard>
{isloading && <Loader/>}
{openModal && <NewModal open={openModal} type={chartData.type} title={chartData.title} onClose={onModalClose}>
        <div className="container" >
          <div className="row">
            <div className={chartData.type === 'pie'?'col-8':'col-10'}>{
            !checked ? ( modalChartLoading ?
             <div style={{height:'522px'}}>
             {/* <Skeleton height={'60px'}></Skeleton> */}
             <Loading loading loaderColor="#3498db" />
             </div> :
              <ChartWrapper options={chartData} chartClickEvent={chartClickEvent} /> )
            : (<></>)
            }</div>
            <div className={chartData.type === 'pie'?'col-4':'col-2'}>
              <div>
                <h5 className="font-weight-bolder m-0">View Details ♒</h5>

              </div>
              <div className="d-flex align-items-center py-2">
                {/* <ToggleSwitch checked={checked} onChange={setChecked} /> */}
                <div className="ml-2">Gird View</div>
              </div>
            </div>
          </div>
        </div>
      </NewModal>}

{
  modalVisible && <Modal open={modalVisible} setVisibleFn={setModalVisible} type="table"  title="Buildings Data" value="Buildings Data" data={modalData}></Modal>
}
        </>
    )
}