import getCharts from '../getCharts'
import { SAVEDATA } from '../../constants/api.config'

export default async function SaveData(wing="state", jsonData) {
  try {
    const payload = {wing, jsonData }
    const res = await getCharts(SAVEDATA, payload)
    return res.data
  } catch (error) {
    return error
  }
}
