import { Suspense } from 'react'
import { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
//import Login from './views/pages/login/newLogin.jsx'
//import Register from './views/pages/Register/Register.jsx'
import { getJWTfromCookie } from './utils/handleCookie.js'
import DefaultLayout from './layout/DefaultLayout'
import { QueryClient, QueryClientProvider } from 'react-query'
import ChangePassword from './views/pages/login/ChangePassword';

// import { ReactQueryDevtools, ReactQueryDevtoolsPanel } from 'react-query/devtools'
import './index.css'
import 'antd/dist/antd.less'
import { Toaster } from 'react-hot-toast'

import { lazy } from 'react'

import {UserContext} from './Contexts/UserContext'
import { Details_Table } from './views/dashboard/Details_Table';
import { store } from './redux/store'
import { Provider } from 'react-redux'

const Login = lazy(()=>import('./views/pages/login/newLogin.jsx'))
const Register = lazy(()=>import('./views/pages/Register/Register.jsx'))
//const DefaultLayout = lazy(()=>import('./layout/DefaultLayout'))
import EditCustomAgGrid from './components/shared/EditCustomAgGrid';
import { AddRecord } from './components/shared/AddRecord';
import { ShowAllData } from './components/shared/ShowAllData';
import PrintView  from './views/dashboard/PrintView';

// Pages
const ProtectedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (getJWTfromCookie() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)} />
const PublicRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (getJWTfromCookie() ? <Redirect to={{ pathname: '/home' }} /> : <Component {...props} />)} />
const queryClient = new QueryClient()

window.NavigationDetails = {}

const App = () => {
  const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
  const [activeSelection, setActiveSelection] = useState(true);
  const [encryptedURL, setEncryptedURL] = useState(true);

  if(isMobile) {
    return(<>
    
    <div style={{textAlign:"center", marginTop:"100%"}}>
      <p><b>Mobile View Under Construction</b></p> 
      <p><b>Please use application on Desktop</b></p>
      </div>
    </>)
  }
  return (
    <Provider store={store}>
      <UserContext.Provider value = {{activeSelection, setActiveSelection, encryptedURL, setEncryptedURL}}>

      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtoolsPanel isOpen={true}></ReactQueryDevtoolsPanel> */}
        <Router>
          <Toaster />
          <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <PublicRoute exact path="/" name="Login Page" component={() => <Login />} />
            <ProtectedRoute path="/home" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/homeBuilding" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/watchlist" component={() => <DefaultLayout />} />
            <PublicRoute exact path="/login" name="Login Page" component={() => <Login />} />
            <ProtectedRoute exact path="/print" name="Print View" component={() => <PrintView />} />
            <PublicRoute exact path="/register" name="Register page" component={() => <Register />} />
            <ProtectedRoute path="/dashboard" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/buildings" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/tabledetails" component={() => <Details_Table />} />
            <ProtectedRoute path="/edittable" component={() => <EditCustomAgGrid />} />
            <ProtectedRoute path="/datawarehouse" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/details" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/childTable" component={() => <DefaultLayout />} />
            <ProtectedRoute path="/addrecord" component={() => <AddRecord />} />
            <ProtectedRoute exact path="/changepassword" name="Change password page" component={()=><ChangePassword />} />

            <ProtectedRoute path="/showalldata" component={() => <ShowAllData />} />
          </Switch>
          </Suspense>
        </Router>
      </QueryClientProvider>
      </UserContext.Provider>
    </Provider>
  )
}


export default App
